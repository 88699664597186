import React, { useEffect, useState, useContext } from "react";
import "./styles.css";
import {
  AccountBalanceWallet,
  AccountBalanceWalletOutlined,
  Assignment,
  AssignmentOutlined,
  Close,
  HourglassTop,
  PendingActions,
  SwapHoriz,
} from "@mui/icons-material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import NavContext from "../../Context/NavContext";
import { useParams, useNavigate } from "react-router-dom";
import { Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { toast } from "react-toastify";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker";
import TimePicker from "react-time-picker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [111, 133, 352, 233, 298, 59, 505],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
};

// Select operational hours
function StudioOperationalHrs({
  operationalHrs,
  setOperationalHrs,
  UpdateOperationalHrs,
}) {
  return (
    <div className="studio-operational-hrs">
      {Object.entries(operationalHrs)
        .slice(0, 7)
        .map((item, index) => (
          <div className="studio-operational-hrs-item">
            <p id="operational-day">{item[0]}</p>
            <div className="studio-operational-hrs-item-time">
              {/* <input
                type="time"
                id="operational-time-input"
                onChange={(e) => {
                  // update time
                  let temp = operationalHrs;
                  temp[item[0]].from = e.target.value;
                  setOperationalHrs(temp);

                  // setOperationalHrs({
                  //   ...operationalHrs,
                  //   [item]: {
                  //     from: e.target.value,
                  //   },
                  // });
                }}
              /> */}
              <TimePicker
                onChange={(e) => {
                  console.log("time range changed", e);
                  let temp = operationalHrs;
                  temp[item[0]].from = e;
                  setOperationalHrs(temp);
                }}
                disableClock
              />
              <p>to</p>
              <TimePicker
                onChange={(e) => {
                  console.log("time range changed", e);
                  let temp = operationalHrs;
                  temp[item[0]].to = e;
                  setOperationalHrs(temp);
                }}
                disableClock
              />
              {/* <input
                type="time"
                id="operational-time-input"
                onChange={(e) => {
                  let temp = operationalHrs;
                  temp[item[0]].to = e.target.value;
                  setOperationalHrs(temp);
                  // setOperationalHrs({
                  //   ...operationalHrs,
                  //   [item]: {
                  //     to: e.target.value,
                  //   },
                  // });
                }}
              /> */}
            </div>
          </div>
        ))}
      <div className="studio-operational-hrs-item">
        <p id="operational-day">Weekend off?</p>
        <input
          type="checkbox"
          id="weekend-off-checkbox"
          onChange={(e) => {
            operationalHrs["isWeekendOff"] = e.target.checked;
          }}
        />
      </div>
      <button
        onClick={() => {
          UpdateOperationalHrs();
          console.log("operational hours:", operationalHrs);
        }}
        id="studio-operational-hrs-save-btn"
      >
        Save
      </button>
    </div>
  );
}

function EquipmentsList({ equipments, setEquipments }) {
  return (
    <div className="studio-equipments-list">
      {equipments.map((item, index) => (
        <div className="studio-equipments-list-item">
          {Object.entries(item).map((i, index) => (
            <p>{i[index]}</p>
          ))}
        </div>
      ))}
    </div>
  );
}

const StudioDashboard = () => {
  const [allOrders, setAllOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [acceptedOrders, setAcceptedOrders] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [ordersWithDate, setOrdersWithDate] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [avgRatings, setAvgRatings] = useState();
  const [earnings, setEarnings] = useState(0);
  const { setActiveNavItem } = useContext(NavContext);
  const [slots, setSlots] = useState([]);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [bookedSlotsArray, setBookedSlotsArray] = useState([]);
  const [slotsToBeBlocked, setSlotsToBeBlocked] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [ownerIds, setOwnerIds] = useState({
    studio: "",
    engineer: "",
  });
  const [btnContent, setBtnContent] = useState("Switch");
  const [open, setOpen] = useState({
    opHrs: false,
    equipments: false,
  });
  const handleOpen = (name) => {
    setOpen({ ...open, [name]: true });
  };
  const handleClose = () => setOpen(false);
  const [operationalHrs, setOperationalHrs] = useState({
    monday: {
      to: "",
      from: "",
    },
    tuesday: {
      to: "",
      from: "",
    },
    wednesday: {
      to: "",
      from: "",
    },
    thursday: {
      to: "",
      from: "",
    },
    friday: {
      to: "",
      from: "",
    },
    saturday: {
      to: "",
      from: "",
    },
    sunday: {
      to: "",
      from: "",
    },
    isWeekendOff: false,
  });
  const [equipments, setEquipments] = useState([
    {
      microphone: "",
      headphones: "",
      audioInterface: "",
      MIDI: "",
      monitor: "",
      drums: "",
      racks: "",
      mixer: "",
      guitar: "",
      cymbals: "",
      speaker: "",
      keyboard: "",
      accousticallyTreated: false,
      sid: window.localStorage.getItem("studioId"),
    },
    true,
  ]);

  const modalStyle = {
    position: "absolute",
    bottom: "50px",
    left: "50%",
    transform: "translate(-50%, 0)",
    width: "100%",
    maxWidth: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    boxSizing: "border-box",
    borderRadius: 1,
    justifyContent: "center",
  };

  const handleBtnTextChange = () => {
    if (btnContent === "Switch") {
      setBtnContent("Cancel");
    } else {
      setBtnContent("Switch");
    }
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);
  }, []);

  useEffect(() => {
    document.title = "Jamrio | Dashboard";
    GetAllOrders();
    GetOrdersWithDate();
    GetStudioData();
    GetCompletedOrdersAmount();
    setActiveNavItem("D");
    fetchBookedSlots();
    GetSlotsBlockedByStudio();
    GetOwnerDetails();
  }, []);

  useEffect(() => {
    GetOrdersWithDate();
    fetchBookedSlots();
    GetSlotsBlockedByStudio();
  }, [selectedDate]);

  // useEffect(() => {

  // }, [slots]);

  useEffect(() => {
    GenerateArray(9, 21, 1);
    console.log("i fire once");
  }, []);

  // create slots list
  const GenerateArray = (start, stop, step) => {
    setSlots([]);
    return Array.from({ length: (stop - start) / step + 1 }, (value, index) =>
      setSlots((slots) => [
        ...slots,
        {
          id: index,
          start: start + index * step,
          end: start + index * step + 1,
        },
      ])
    );
  };

  const GenerateArrayOnlyTime = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    );

  useEffect(() => {
    setPendingOrders([]);
    if (allOrders) {
      Object.entries(allOrders).forEach(([key, value]) => {
        return value.state === "approval_pending"
          ? setPendingOrders((pendingOrders) => [...pendingOrders, value])
          : null;
      });
    }
  }, [allOrders]);

  useEffect(() => {
    setAcceptedOrders([]);
    if (allOrders) {
      Object.entries(allOrders).forEach(([key, value]) => {
        return value.state === "otp_pending" &&
          new Date(value.bookingdate) >= new Date()
          ? setAcceptedOrders((acceptedOrders) => [...acceptedOrders, value])
          : null;
      });
    }
  }, [allOrders]);

  const GetAllOrders = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/dashboard/studio/${window.localStorage.getItem("studioId")}/orders`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Studio orders ----->", data);
          }
          setAllOrders(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchBookedSlots = async () => {
    //Production
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/studio/${window.localStorage.getItem("studioId")}/slots?date=${moment(
        selectedDate
      ).format("YYYY-MM-DD")}`,
      {
        //Testing
        // await fetch(`http://localhost:3000/studio/details/?type=D&id=${studioId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (process.env.NODE_ENV === "development") {
            console.log("Booked slots: ", data);
          }
          setSelectedSlots([]);
          setBookedSlots(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Something went wrong", data);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const BlockSlots = () => {
    //Production
    fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/studio/blockslots/multiple`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
        body: JSON.stringify({
          bookingDate: moment(selectedDate).format("YYYY-MM-DD"),
          slots: slotsToBeBlocked,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (process.env.NODE_ENV === "development") {
            console.log("Slots Blocked: ", data);
          }
          setSelectedSlots([]);
          fetchBookedSlots();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Something went wrong", data);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UnBlockSlots = (slot) => {
    //Production
    fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/studio/unblockslot`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
        body: JSON.stringify({
          bookingDate: moment(selectedDate).format("YYYY-MM-DD"),
          fromHour: "0" + slot + ":00",
          toHour: "0" + (slot + 1) + ":00",
          isBlocked: true,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (process.env.NODE_ENV === "development") {
            console.log("Slots Unblocked: ", data);
          }
          setSelectedSlots([]);
          fetchBookedSlots();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Please unblock one slot at a time");
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdateOperationalHrs = () => {
    //Production
    fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/studio/operationhours`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
        body: JSON.stringify(operationalHrs),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (process.env.NODE_ENV === "development") {
            console.log("Slots Unblocked: ", data);
          }
          toast.success("Updated Successfully");
          handleClose();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Please unblock one slot at a time");
          }
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetOrdersWithDate = async (date) => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/dashboard/studio/${window.localStorage.getItem(
        "studioId"
      )}/orders/bookings/${moment(selectedDate).format("YYYY-MM-DD")}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Studio orders by Date ----->", date, data);
          }
          setOrdersWithDate(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // ----------Get Studio Data--------------
  const GetStudioData = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/studio/${window.localStorage.getItem("studioId")}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Studio Data ------------------- ----->", data);
          }
          setReviews(data.reviews);
          setAvgRatings(data.rating);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetCompletedOrdersAmount = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/dashboard/studio/${window.localStorage.getItem(
        "studioId"
      )}/earnings/completed?date=${moment(new Date()).format("YYYY-MM-DD")}`,
      {
        method: "Get",
        headers: {
          "Content-Type9": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Studio Data Completed ----->", data);
          }
          // setCompletedAmount(data);
          setEarnings(data.totalAmount);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetSlotsBlockedByStudio = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/studio/${window.localStorage.getItem("studioId")}/blockedslot`,
      {
        method: "GET",
        headers: {
          "Content-Type9": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Get Blocked Slots ----->", data);
          }
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetOwnerDetails = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/user/${window.localStorage.getItem("userId")}`,
      // "http://localhost:3000/user/auth",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("User Details ----->", data);
          }
          setOwnerIds({
            studio: data.studioid,
            engineer: data.studioengineerId,
          });
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    createBookedSlotsArray();
  }, [bookedSlots]);

  useEffect(() => {
    console.log("booked slots array", bookedSlotsArray);
    console.log("booked slots array unique", [...new Set(bookedSlotsArray)]);
  }, [bookedSlotsArray]);

  // create an array of booked slots
  const createBookedSlotsArray = () => {
    setBookedSlotsArray([]);
    bookedSlots?.forEach((slot) => {
      if (
        moment(selectedDate).format("YYYY-MM-DD") ===
        slot.bookingDate?.substring(0, 10)
      ) {
        const slotStart = parseInt(slot.fromHour.substring(0, 2));
        const slotEnd = parseInt(slot.toHour.substring(0, 2));
        if (slotStart !== 0) {
          GenerateArrayOnlyTime(slotStart, slotEnd - 1, 1).forEach((s) => {
            setBookedSlotsArray((slots) => [...slots, s]);
          });
        }
      }
    });
  };

  // Handle slots click
  const handleSlotClick = (slot) => {
    if (selectedSlots.includes(slot)) {
      setSelectedSlots(selectedSlots.filter((item) => item !== slot));
    } else {
      setSelectedSlots([...selectedSlots, slot]);
    }
  };

  const handleSlotClickToBlock = (slot) => {
    if (selectedSlots.includes(slot.start)) {
      setSlotsToBeBlocked(
        slotsToBeBlocked.filter((item) => item.start !== slot.start)
      );
    } else {
      setSlotsToBeBlocked([
        ...slotsToBeBlocked,
        {
          fromHour: slot.start + ":00",
          toHour: slot.end + ":00",
          isBlocked: true,
        },
      ]);
    }
  };

  return (
    <div className="studioDashboard">
      <Modal
        open={open.opHrs || open.equipments}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // hideBackdrop
      >
        <Box id="operational-hrs-modal">
          <Typography
            id="operational-hrs-modal-title"
            variant="h6"
            component="h2"
          >
            {open.opHrs
              ? "Set your operational hours"
              : open.equipments
              ? "Add your equipments list"
              : null}
            <Close onClick={handleClose} id="operational-hrs-close-btn" />
          </Typography>
          <div className="operational-hrs-modal-content">
            {open.opHrs ? (
              <StudioOperationalHrs
                operationalHrs={operationalHrs}
                setOperationalHrs={setOperationalHrs}
                UpdateOperationalHrs={UpdateOperationalHrs}
              />
            ) : open.equipments ? (
              <EquipmentsList
                equipments={equipments}
                setEquipments={setEquipments}
              />
            ) : null}
          </div>
        </Box>
      </Modal>
      {screenWidth < 800 && ownerIds?.engineer && (
        <button
          id="switch-dashboard-floating-btn"
          onClick={() => navigate("/engineer-home/dashboard")}
        >
          <SwapHoriz /> <p>Switch to Engineer Dashboard</p>
        </button>
      )}
      <h1 id="studio-dashboard-title">Dashboard</h1>
      <div className="studio-dashboard-items-container">
        <div className="studio-dashboard-items-left">
          <div className="studio-dashboard-items-left-top">
            <div
              onClick={() => navigate("/studio-home/bookings-pending")}
              className="studio-dashboard-orders-container"
            >
              <p id="studio-dashboard-order-container-title">Pending Orders</p>
              <div className="studio-dashboard-orders-inner-container">
                <HourglassTop
                  fontSize={screenWidth > 800 ? "large" : "small"}
                  className="studio-dashboard-orders-container-icon"
                />
                <p id="studio-dashboard-orders-container-number">
                  {pendingOrders?.length}
                </p>
              </div>
            </div>
            <div
              onClick={() => navigate("/studio-home/bookings-upcoming")}
              className="studio-dashboard-orders-container"
            >
              <p id="studio-dashboard-order-container-title">Upcoming Orders</p>
              <div className="studio-dashboard-orders-inner-container">
                <PendingActions
                  fontSize={screenWidth > 800 ? "large" : "small"}
                  className="studio-dashboard-orders-container-icon"
                />
                <p id="studio-dashboard-orders-container-number">
                  {acceptedOrders?.length}
                </p>
              </div>
            </div>
            <div
              onClick={() => navigate("/studio-home/bookings-total")}
              className="studio-dashboard-orders-container"
            >
              <p id="studio-dashboard-order-container-title">Total Orders</p>
              <div className="studio-dashboard-orders-inner-container">
                <AssignmentOutlined
                  fontSize={screenWidth > 800 ? "large" : "small"}
                  className="studio-dashboard-orders-container-icon"
                />
                <p id="studio-dashboard-orders-container-number">
                  {allOrders?.length}
                </p>
              </div>
            </div>
          </div>
          <div className="studio-dashboard-items-left-middle">
            <div className="studio-dashboard-earnings-container">
              <div className="studio-dashboard-earnings-left-container">
                <p id="studio-dashboard-order-container-title">Earnings</p>
                <div className="studio-dashboard-earnings-amount-container">
                  <AccountBalanceWalletOutlined fontSize="large" />
                  <p id="studio-dashboard-earnings-amount">₹{earnings}</p>
                </div>
                <button id="studio-dashboard-withdraw-btn">Withdraw</button>
              </div>
              <div className="studio-dashboard-earnings-graph-container">
                <Line options={options} data={data} />
              </div>
            </div>
            {/* <button
              className="eequipments-modal-btn"
              onClick={() => handleOpen("equipments")}
            >
              Add Equipments
            </button> */}
          </div>
          {screenWidth < 800 && (
            <div className="studio-dashboard-items-right">
              <div className="studio-dashboard-calendar-main-container">
                <Calendar
                  className="studio-react-dashboard-calendar"
                  onChange={setSelectedDate}
                  value={selectedDate}
                />
                <button
                  onClick={() => handleOpen("opHrs")}
                  id="operational-hrs-btn"
                >
                  Add Studio Working Hours
                </button>
                <div className="studio-dashboard-orders-by-date">
                  <p id="studio-selected-date">
                    {moment(selectedDate).format("DD MMMM")}
                  </p>
                  <div className="slots-main-container">
                    {slots &&
                      slots?.map((slot) => (
                        <button
                          onClick={() => {
                            handleSlotClick(slot.start);
                            handleSlotClickToBlock(slot);
                          }}
                          className={
                            bookedSlotsArray?.includes(slot.start)
                              ? "slot-item-disabled"
                              : selectedSlots?.includes(slot.start)
                              ? "slot-item-clicked"
                              : "slot-item"
                          }
                          // disabled={bookedSlots?.includes(slot.start)}
                        >
                          <p>
                            {slot.start
                              ? parseInt(slot.start) > 12
                                ? parseInt(slot.start) - 12 + "pm"
                                : parseInt(slot.start) === 12
                                ? parseInt(slot.start) + "pm"
                                : parseInt(slot.start) + "am"
                              : "00:00"}
                          </p>
                        </button>
                      ))}
                  </div>
                  <div className="block-slots-btns-container">
                    <button
                      onClick={() => {
                        BlockSlots();
                        console.log(
                          "Block slots",
                          selectedSlots,
                          slotsToBeBlocked
                        );
                      }}
                      id="block-slots-btn"
                      disabled={selectedSlots?.length < 1}
                    >
                      Block Slots
                    </button>
                  </div>
                  {bookedSlotsArray?.length > 0 && (
                    <>
                      <div className="slots-main-container">
                        {bookedSlotsArray &&
                          [...new Set(bookedSlotsArray)]?.map((slot) => (
                            <button
                              onClick={() => handleSlotClick(slot)}
                              className={
                                selectedSlots?.includes(slot)
                                  ? "slot-item-clicked"
                                  : bookedSlotsArray?.includes(slot)
                                  ? "slot-item-disabled"
                                  : "slot-item-invisible"
                              }
                            >
                              <p>
                                {slot
                                  ? parseInt(slot) > 12
                                    ? parseInt(slot) - 12 + "pm"
                                    : parseInt(slot) === 12
                                    ? parseInt(slot) + "pm"
                                    : parseInt(slot) + "am"
                                  : "00:00"}
                              </p>
                            </button>
                          ))}
                      </div>
                      <p>Only 1 slot will can be unblocked at a time</p>
                      <div className="block-slots-btns-container">
                        <button
                          onClick={() => {
                            console.log("Block slots", selectedSlots);
                            UnBlockSlots(selectedSlots[0]);
                          }}
                          id="block-slots-btn"
                          disabled={selectedSlots?.length < 1}
                        >
                          Unblock Slots
                        </button>
                      </div>
                    </>
                  )}
                  <div className="studio-dashboard-orders-by-date-inner-container">
                    {ordersWithDate?.length > 0 ? (
                      ordersWithDate?.map((order) => (
                        <div
                          onClick={() =>
                            navigate(`/studio-home/order/${order.id}`)
                          }
                          className="studio-order-by-date"
                        >
                          <p>Studio Booking</p>
                          <p>
                            {parseInt(order?.starttime) > 12
                              ? parseInt(order?.starttime) - 12 + "pm"
                              : parseInt(order?.starttime) === 12
                              ? parseInt(order?.starttime) + "pm"
                              : parseInt(order?.starttime) + "am"}{" "}
                            -{" "}
                            {parseInt(order?.endtime) > 12
                              ? order?.endtime?.substring(0, 2) - 12 + "pm"
                              : parseInt(order?.endtime) === 12
                              ? parseInt(order?.endtime) + "pm"
                              : parseInt(order?.endtime) + "am"}
                          </p>
                        </div>
                      ))
                    ) : (
                      <p>No Orders for this Date</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="studio-dashboard-items-left-bottom">
            <div className="studio-dashboard-reviews-title-container">
              <p id="studio-dashboard-order-container-title">Reviews</p>
              <p id="studio-dashboard-order-container-subtitle">
                Average Ratings ⭐ {avgRatings?.toFixed(1)}
              </p>
            </div>
            <div className="studio-dashboard-reviews-main-container">
              {reviews.length > 0 ? (
                <div className="studio-dashboard-review-container">
                  <img
                    id="studio-dashboard-reviewer-img"
                    src="https://images.unsplash.com/photo-1501196354995-cbb51c65aaea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80"
                    alt="reviewer"
                  />
                  <div className="studio-dashboard-review-text-container">
                    <div className="studio-dashboard-review-title-container">
                      <p>
                        <span id="studio-dashboard-reviewer-name">
                          Jack Mathew
                        </span>{" "}
                        ⭐4
                      </p>
                      <p id="studio-dashboard-review-read-more">Read More</p>
                    </div>
                    <p>
                      {"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Utorci dui, luctus vitae gravida quis, sollicitudin vitaenibh. Vivamus semper nec dui bibendum dignissim. Morbi mattis dolor enim, ut tempor tortor maximus nec. Aliquam acurna erat".substring(
                        0,
                        100
                      ) + "..."}
                    </p>
                  </div>
                </div>
              ) : (
                <p>No Reviews Yet</p>
              )}
            </div>
          </div>
        </div>
        {screenWidth > 800 && (
          <div className="studio-dashboard-items-right">
            <div className="studio-dashboard-calendar-main-container">
              <Calendar
                className="studio-react-dashboard-calendar"
                onChange={setSelectedDate}
                value={selectedDate}
              />
              <button
                onClick={() => handleOpen("opHrs")}
                id="operational-hrs-btn"
              >
                Add Studio Working Hours
              </button>
              <div className="studio-dashboard-orders-by-date">
                <p id="studio-selected-date">
                  {moment(selectedDate).format("DD MMMM")}
                </p>
                <div className="slots-main-container">
                  {slots &&
                    slots?.map((slot) => (
                      <button
                        onClick={() => {
                          handleSlotClick(slot.start);
                          handleSlotClickToBlock(slot);
                        }}
                        className={
                          bookedSlotsArray?.includes(slot.start)
                            ? "slot-item-disabled"
                            : selectedSlots?.includes(slot.start)
                            ? "slot-item-clicked"
                            : "slot-item"
                        }
                        // disabled={bookedSlots?.includes(slot.start)}
                      >
                        <p>
                          {slot.start
                            ? parseInt(slot.start) > 12
                              ? parseInt(slot.start) - 12 + "pm"
                              : parseInt(slot.start) === 12
                              ? parseInt(slot.start) + "pm"
                              : parseInt(slot.start) + "am"
                            : "00:00"}
                        </p>
                      </button>
                    ))}
                </div>
                <div className="block-slots-btns-container">
                  <button
                    onClick={() => {
                      BlockSlots();
                      console.log(
                        "Block slots",
                        selectedSlots,
                        slotsToBeBlocked
                      );
                    }}
                    id="block-slots-btn"
                    disabled={selectedSlots?.length < 1}
                  >
                    Block Slots
                  </button>
                </div>
                {bookedSlotsArray?.length > 0 && (
                  <>
                    <div className="slots-main-container">
                      {bookedSlotsArray &&
                        [...new Set(bookedSlotsArray)]?.map((slot) => (
                          <button
                            onClick={() => handleSlotClick(slot)}
                            className={
                              selectedSlots?.includes(slot)
                                ? "slot-item-clicked"
                                : bookedSlotsArray?.includes(slot)
                                ? "slot-item-disabled"
                                : "slot-item-invisible"
                            }
                          >
                            <p>
                              {slot
                                ? parseInt(slot) > 12
                                  ? parseInt(slot) - 12 + "pm"
                                  : parseInt(slot) === 12
                                  ? parseInt(slot) + "pm"
                                  : parseInt(slot) + "am"
                                : "00:00"}
                            </p>
                          </button>
                        ))}
                    </div>
                    <div className="block-slots-btns-container">
                      <button
                        onClick={() =>
                          console.log(
                            "unBlock slots",
                            selectedSlots,
                            UnBlockSlots(selectedSlots[0])
                          )
                        }
                        id="block-slots-btn"
                        disabled={selectedSlots?.length < 1}
                      >
                        Unblock Slots
                      </button>
                    </div>
                  </>
                )}
                <div className="studio-dashboard-orders-by-date-inner-container">
                  {ordersWithDate?.length > 0 ? (
                    ordersWithDate?.map((order) => (
                      <div
                        onClick={() =>
                          navigate(`/studio-home/order/${order.id}`)
                        }
                        className="studio-order-by-date"
                      >
                        <p>Studio Booking</p>
                        <p>
                          {parseInt(order?.starttime) > 12
                            ? parseInt(order?.starttime) - 12 + "pm"
                            : parseInt(order?.starttime) === 12
                            ? parseInt(order?.starttime) + "pm"
                            : parseInt(order?.starttime) + "am"}{" "}
                          -{" "}
                          {parseInt(order?.endtime) > 12
                            ? order?.endtime?.substring(0, 2) - 12 + "pm"
                            : parseInt(order?.endtime) === 12
                            ? parseInt(order?.endtime) + "pm"
                            : parseInt(order?.endtime) + "am"}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p>No Orders for this Date</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudioDashboard;
