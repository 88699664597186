import { createContext, useState } from "react";

const PackageContext = createContext();

export function SelectedPackagesProvider({ children }) {
  const [selectedPackages, setSelectedPackages] = useState({id: "", action: ""});
  const [selectedCoupon, setSelectedCoupon] = useState();
  return (
    <PackageContext.Provider
      value={{
        selectedPackages,
        setSelectedPackages,
        setSelectedCoupon,
        selectedCoupon
      }}
    >
      {children}
    </PackageContext.Provider>
  );
}

export default PackageContext;
