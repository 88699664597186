import "./styles.css";
import {
  EventNote,
  Today,
  AccessTime,
  AccountBalanceWalletOutlined,
  PaidOutlined,
} from "@mui/icons-material";
import { useEffect, useContext, useState } from "react";
import NavContext from "../../Context/NavContext";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

const EngineerBookings = () => {
  //Navigation and URL Params
  const navigate = useNavigate();
  const { component } = useParams();
  const { setActiveNavItem } = useContext(NavContext);
  const [activeFilter, setActiveFilter] = useState(component);
  const [orders, setOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [acceptedOrders, setAcceptedOrders] = useState([]);
  const [itemClicked, setItemClicked] = useState({
    orderId: "",
    order: false,
    accept: false,
    reject: false,
  });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);
  }, []);

  useEffect(() => {
    document.title = "Jamrio | Bookings";
    setActiveNavItem("B");
    GetAllOrders();
  }, []);

  useEffect(() => {
    setAllOrders([]);
    if (orders) {
      Object.entries(orders).forEach(([key, value]) => {
        return value.state !== "created"
          ? setAllOrders((allOrders) => [...allOrders, value])
          : null;
      });
    }
  }, [orders]);

  const GetAllOrders = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/mixing-mastering/order/engineer/${window.localStorage.getItem(
        "engineerId"
      )}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Studio orders ----->", data);
          }
          setOrders(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    setPendingOrders([]);
    if (allOrders) {
      Object.entries(allOrders).forEach(([key, value]) => {
        return value.state === "approval_pending"
          ? setPendingOrders((pendingOrders) => [...pendingOrders, value])
          : null;
      });
    }
  }, [allOrders]);
  useEffect(() => {
    setAcceptedOrders([]);
    let date = new Date();
    let today = date.toISOString();
    if (allOrders) {
      Object.entries(allOrders).forEach(([key, value]) => {
        return value.state === "success" &&
          new Date(value.bookingdate)
            .toISOString()
            .substring(0, 10)
            .split("-")
            .join("") >= today.substring(0, 10).split("-").join("")
          ? setAcceptedOrders((acceptedOrders) => [...acceptedOrders, value])
          : null;
      });
    }
    console.log("today", today.substring(0, 10).split("-").join(""));
  }, [allOrders]);

  useEffect(() => {
    console.log("accepted orders", acceptedOrders);
  }, [acceptedOrders]);

  //Order accepting and rejecting api

  const AcceptOrder = async (orderId) => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/order/${orderId}/approve/accept`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.status;
      })
      .then((data) => {
        console.log("order accepted:", data);
        if (data === 200) {
          GetAllOrders();
        } else {
          console.log("Failed");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const RejectOrder = async (orderId) => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/order/${orderId}/approve/reject`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.status;
      })
      .then((data) => {
        console.log("order accepted:", data);
        if (data === 200) {
          GetAllOrders();
        } else {
          console.log("Failed");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const PendingOrders = ({ order }) => {
    return (
      <div
        onClick={() => {
          navigate(`/engineer-home/order/${order?.id}`);
        }}
        className="booking-container-phone"
      >
        <p id="bookings-order-cost-phone">₹{order?.netamount}</p>
        <p id="bookings-order-text-id">
          Order Id: <span style={{ fontWeight: "600" }}>{order?.id}</span>
        </p>
        <p id="bookings-order-text-phone">
          {moment.utc(order.bookingdate).format("MMM Do YYYY")}
        </p>
        {order?.state === "approval_pending" ? (
          <div className="bookings-items-btn-container">
            <button
              onClick={() => {
                navigate(`/engineer-home/order/${order.id}/accept`);
              }}
              id="bookings-accept-btn"
            >
              Accept
            </button>
            <button
              onClick={() => {
                console.log("clicked reject");
                navigate(`/engineer-home/order/${order.id}/reject`);
              }}
              id="bookings-reject-btn"
            >
              Reject
            </button>
          </div>
        ) : (
          <p id="bookings-order-text-phone">Order Status: {order?.state}</p>
        )}
      </div>
    );
  };

  return (
    <div className="engineerBookings">
      <h1 id="bookings-title">Engineer Bookings</h1>
      <div className="bookings-items-container">
        <div className="bookings-items-top">
          <button
            onClick={() => {
              setActiveFilter("pending");
              navigate("/engineer-home/bookings-pending");
            }}
            className={`bookings-filter-container${
              component === "bookings-pending" || activeFilter === "pending"
                ? "-selected"
                : ""
            }`}
          >
            <p id="bookings-filter-title">Pending <br /> Requests</p>
            <p
              id={`bookings-filter-number${
                component === "bookings-pending" || activeFilter === "pending"
                  ? "-selected"
                  : ""
              }`}
            >
              {pendingOrders?.length}
            </p>
          </button>
          <button
            onClick={() => {
              setActiveFilter("upcoming");
              navigate("/engineer-home/bookings-upcoming");
            }}
            className={`bookings-filter-container${
              component === "bookings-upcoming" || activeFilter === "upcoming"
                ? "-selected"
                : ""
            }`}
          >
            <p id="bookings-filter-title">Upcoming <br /> Requests</p>
            <p
              id={`bookings-filter-number${
                component === "bookings-upcoming" || activeFilter === "upcoming"
                  ? "-selected"
                  : ""
              }`}
            >
              {acceptedOrders?.length}
            </p>
          </button>
          <button
            onClick={() => {
              setActiveFilter("total");
              navigate("/engineer-home/bookings-total");
            }}
            className={`bookings-filter-container${
              component === "bookings-total" || activeFilter === "total"
                ? "-selected"
                : ""
            }`}
          >
            <p id="bookings-filter-title">Total <br /> Requests</p>
            <p
              id={`bookings-filter-number${
                component === "bookings-total" || activeFilter === "total"
                  ? "-selected"
                  : ""
              }`}
            >
              {allOrders?.length}
            </p>
          </button>
        </div>
        <div className="bookings-items-bottom">
          {component === "bookings-pending" || activeFilter === "pending" ? (
            pendingOrders &&
            pendingOrders?.map((order) => <PendingOrders order={order} />)
          ) : component === "bookings-upcoming" ||
            activeFilter === "upcoming" ? (
            acceptedOrders &&
            acceptedOrders?.map((order) => <PendingOrders order={order} />)
          ) : component === "bookings-total" || activeFilter === "total" ? (
            allOrders &&
            allOrders?.map((order) => <PendingOrders order={order} />)
          ) : (
            <p>No Orders Yet</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default EngineerBookings;
