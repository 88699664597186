import React, { useEffect, useState, useContext } from "react";
import "./styles.css";
import {
  AccountBalanceWallet,
  AccountBalanceWalletOutlined,
  Assignment,
  AssignmentOutlined,
  HourglassTop,
  PendingActions,
  SwapHoriz,
} from "@mui/icons-material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import NavContext from "../../Context/NavContext";
import { useParams, useNavigate } from "react-router-dom";
import { Switch } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [111, 133, 352, 233, 298, 59, 505],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
};

const EngineerDashboard = () => {
  const [allOrders, setAllOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [acceptedOrders, setAcceptedOrders] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [ordersWithDate, setOrdersWithDate] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [avgRatings, setAvgRatings] = useState();
  const [earnings, setEarnings] = useState(0);
  const { setActiveNavItem } = useContext(NavContext);
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [ownerIds, setOwnerIds] = useState({
    studio: "",
    engineer: "",
  });
  const [engineerDetails, setEngineerDetails] = useState();
  const [engStatus, setEngStatus] = useState(false);

  const handleStatusChange = (event) => {
    console.log("Switch", event.target.checked);
    setEngStatus(event.target.checked);
    ChangeEngineerStatus(event.target.checked ? 1 : 0);
  };

  useEffect(() => {
    setEngStatus(engineerDetails?.isEnabled);
  }, [engineerDetails]);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);
  }, []);

  useEffect(() => {
    document.title = "Jamrio | Dashboard";
    GetAllOrders();
    GetOrdersWithDate();
    GetEngineerData();
    GetEngineerEarnings();
    setActiveNavItem("D");
    GetEngineerReviews();
    GetOwnerDetails();
  }, []);

  useEffect(() => {
    GetOrdersWithDate();
  }, [selectedDate]);

  useEffect(() => {
    setPendingOrders([]);
    if (allOrders) {
      Object.entries(allOrders).forEach(([key, value]) => {
        return value.state === "approval_pending"
          ? setPendingOrders((pendingOrders) => [...pendingOrders, value])
          : null;
      });
    }
  }, [allOrders]);

  useEffect(() => {
    setAcceptedOrders([]);
    if (allOrders) {
      Object.entries(allOrders).forEach(([key, value]) => {
        return value.state === "otp_pending" &&
          new Date(value.bookingdate) >= new Date()
          ? setAcceptedOrders((acceptedOrders) => [...acceptedOrders, value])
          : null;
      });
    }
  }, [allOrders]);

  const GetAllOrders = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/mixing-mastering/order/engineer/${window.localStorage.getItem(
        "engineerId"
      )}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("engineer orders ----->", data);
          }
          setAllOrders(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetOrdersWithDate = async (date) => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/mixing-mastering/${window.localStorage.getItem(
        "engineerId"
      )}/orders/${moment(selectedDate).format("YYYY-MM-DD")}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("engineer orders by Date ----->", date, data);
          }
          setOrdersWithDate(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
          setOrdersWithDate();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // ----------Get engineer Data--------------
  const GetEngineerData = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/mixing-mastering/${window.localStorage.getItem(
        "engineerId"
      )}/studioengineer`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("engineer Data ------------------- ----->", data);
          }
          setAvgRatings(data.rating);
          setEngineerDetails(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetEngineerReviews = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/mixing-mastering/${window.localStorage.getItem(
        "engineerId"
      )}/studioengineer`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("engineer reviews ------------------- ----->", data);
          }
          setReviews(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetEngineerEarnings = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/mixing-mastering/${window.localStorage.getItem("engineerId")}/earnings`,
      {
        method: "Get",
        headers: {
          "Content-Type9": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("engineer earnings ----->", data);
          }
          // setCompletedAmount(data);
          setEarnings(data.totalAmount);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetOwnerDetails = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/user/${window.localStorage.getItem("userId")}`,
      // "http://localhost:3000/user/auth",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("User Details ----->", data);
          }
          setOwnerIds({
            studio: data.studioid,
            engineer: data.studioengineerId,
          });
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const ChangeEngineerStatus = async (status) => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/dashboard/engineer/${engineerDetails.id}/enabled/${status}`,
      // "http://localhost:3000/user/auth",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          GetEngineerData();
        }
        if (process.env.NODE_ENV === "development") {
          console.log("Status changed----->", response.status);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          GetEngineerData();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="engineerDashboard">
      {screenWidth < 800 && ownerIds?.studio && (
        <button
          id="switch-dashboard-floating-btn"
          onClick={() => navigate("/studio-home/dashboard")}
        >
          <SwapHoriz /> <p>Switch to Studio Dashboard</p>
        </button>
      )}
      {/* {screenWidth < 800 && (
        <div className="enable-engineer-status-container">
          <p
            id={`engineer-profile-visibility-${
              engineerDetails?.isEnabled ? "true" : "false"
            }`}
          >
            {engineerDetails?.isEnabled
              ? "Profile is Visible"
              : "Profile is not Visible"}
          </p>
          <Switch
            checked={engStatus}
            onChange={handleStatusChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      )} */}
      <h1 id="engineer-dashboard-title">Dashboard</h1>
      <div className="engineer-dashboard-items-container">
        <div className="engineer-dashboard-items-left">
          <div className="engineer-dashboard-items-left-top">
            <div
              onClick={() => navigate("/engineer-home/bookings-pending")}
              className="engineer-dashboard-orders-container"
            >
              <p id="engineer-dashboard-order-container-title">
                Pending Orders
              </p>
              <div className="engineer-dashboard-orders-inner-container">
                <HourglassTop
                  fontSize="large"
                  className="engineer-dashboard-orders-container-icon"
                />
                <p id="engineer-dashboard-orders-container-number">
                  {pendingOrders?.length}
                </p>
              </div>
            </div>
            <div
              onClick={() => navigate("/engineer-home/bookings-upcoming")}
              className="engineer-dashboard-orders-container"
            >
              <p id="engineer-dashboard-order-container-title">
                Upcoming Orders
              </p>
              <div className="engineer-dashboard-orders-inner-container">
                <PendingActions
                  fontSize="large"
                  className="engineer-dashboard-orders-container-icon"
                />
                <p id="engineer-dashboard-orders-container-number">
                  {acceptedOrders?.length}
                </p>
              </div>
            </div>
            <div
              onClick={() => navigate("/engineer-home/bookings-total")}
              className="engineer-dashboard-orders-container"
            >
              <p id="engineer-dashboard-order-container-title">Total Orders</p>
              <div className="engineer-dashboard-orders-inner-container">
                <AssignmentOutlined
                  fontSize="large"
                  className="engineer-dashboard-orders-container-icon"
                />
                <p id="engineer-dashboard-orders-container-number">
                  {allOrders?.length}
                </p>
              </div>
            </div>
          </div>
          <div className="engineer-dashboard-items-left-middle">
            <div className="engineer-dashboard-earnings-container">
              <div className="engineer-dashboard-earnings-left-container">
                <p id="engineer-dashboard-order-container-title">Earnings</p>
                <div className="engineer-dashboard-earnings-amount-container">
                  <AccountBalanceWalletOutlined fontSize="large" />
                  <p id="engineer-dashboard-earnings-amount">₹{earnings}</p>
                </div>
                <button id="engineer-dashboard-withdraw-btn">Withdraw</button>
              </div>
              <div className="engineer-dashboard-earnings-graph-container">
                <Line options={options} data={data} />
              </div>
            </div>
          </div>
          {screenWidth < 800 && (
            <div className="engineer-dashboard-items-right">
              <div className="engineer-dashboard-calendar-main-container">
                <Calendar
                  className="engineer-react-dashboard-calendar"
                  onChange={setSelectedDate}
                  value={selectedDate}
                />
                <div className="engineer-dashboard-orders-by-date">
                  <p id="engineer-selected-date">
                    {moment(selectedDate).format("DD MMMM")}
                  </p>
                  <div className="engineer-dashboard-orders-by-date-inner-container">
                    {ordersWithDate?.length > 0 ? (
                      ordersWithDate?.map((order) => (
                        <div
                          onClick={() =>
                            navigate(`/engineer-home/order/${order.id}`)
                          }
                          className="engineer-order-by-date"
                        >
                          <p>engineer Booking</p>
                          {/* <p>
                            {parseInt(order?.starttime) > 12
                              ? parseInt(order?.starttime) - 12 + "pm"
                              : parseInt(order?.starttime) === 12
                              ? parseInt(order?.starttime) + "pm"
                              : parseInt(order?.starttime) + "am"}{" "}
                            -{" "}
                            {parseInt(order?.endtime) > 12
                              ? order?.endtime?.substring(0, 2) - 12 + "pm"
                              : parseInt(order?.endtime) === 12
                              ? parseInt(order?.endtime) + "pm"
                              : parseInt(order?.endtime) + "am"}
                          </p> */}
                        </div>
                      ))
                    ) : (
                      <p>No Orders for this Date</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="engineer-dashboard-items-left-bottom">
            <div className="engineer-dashboard-reviews-title-container">
              <p id="engineer-dashboard-order-container-title">Reviews</p>
              <p id="engineer-dashboard-order-container-subtitle">
                Average Ratings ⭐ {avgRatings}
              </p>
            </div>
            <div className="engineer-dashboard-reviews-main-container">
              {reviews.length > 0 ? (
                <div className="engineer-dashboard-review-container">
                  <img
                    id="engineer-dashboard-reviewer-img"
                    src="https://images.unsplash.com/photo-1501196354995-cbb51c65aaea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80"
                    alt="reviewer"
                  />
                  <div className="engineer-dashboard-review-text-container">
                    <div className="engineer-dashboard-review-title-container">
                      <p>
                        <span id="engineer-dashboard-reviewer-name">
                          Jack Mathew
                        </span>{" "}
                        ⭐4
                      </p>
                      <p id="engineer-dashboard-review-read-more">Read More</p>
                    </div>
                    <p>
                      {"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Utorci dui, luctus vitae gravida quis, sollicitudin vitaenibh. Vivamus semper nec dui bibendum dignissim. Morbi mattis dolor enim, ut tempor tortor maximus nec. Aliquam acurna erat".substring(
                        0,
                        100
                      ) + "..."}
                    </p>
                  </div>
                </div>
              ) : (
                <p>No Reviews Yet</p>
              )}
            </div>
          </div>
        </div>
        {screenWidth > 800 && (
          <div className="engineer-dashboard-items-right">
            <div className="engineer-dashboard-calendar-main-container">
              <Calendar
                className="engineer-react-dashboard-calendar"
                onChange={setSelectedDate}
                value={selectedDate}
              />
              <div className="engineer-dashboard-orders-by-date">
                <p id="engineer-selected-date">
                  {moment(selectedDate).format("DD MMMM")}
                </p>
                <div className="engineer-dashboard-orders-by-date-inner-container">
                  {ordersWithDate?.length > 0 ? (
                    ordersWithDate?.map((order) => (
                      <div
                        onClick={() =>
                          navigate(`/engineer-home/order/${order.id}`)
                        }
                        className="engineer-order-by-date"
                      >
                        <p>engineer Booking</p>
                        {/* <p>
                            {parseInt(order?.starttime) > 12
                              ? parseInt(order?.starttime) - 12 + "pm"
                              : parseInt(order?.starttime) === 12
                              ? parseInt(order?.starttime) + "pm"
                              : parseInt(order?.starttime) + "am"}{" "}
                            -{" "}
                            {parseInt(order?.endtime) > 12
                              ? order?.endtime?.substring(0, 2) - 12 + "pm"
                              : parseInt(order?.endtime) === 12
                              ? parseInt(order?.endtime) + "pm"
                              : parseInt(order?.endtime) + "am"}
                          </p> */}
                      </div>
                    ))
                  ) : (
                    <p>No Orders for this Date</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EngineerDashboard;
