import React from "react";
import "./styles.css";
import { useEffect, useContext, useState } from "react";
import NavContext from "../../Context/NavContext";
import {
  AccountCircleRounded,
  AddCircleOutline,
  ArrowForwardIosRounded,
  Call,
  Close,
  ContactEmergency,
  Edit,
  Help,
  Logout,
  ModeEdit,
  Visibility,
} from "@mui/icons-material";
import { Modal, Typography, Box, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const EngineerSettings = () => {
  const { setActiveNavItem } = useContext(NavContext);
  const [engineerData, setEngineerData] = useState();
  const [userData, setUserData] = useState();
  const [avgRatings, setAvgRatings] = useState();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);
  }, []);

  useEffect(() => {
    document.title = "Jamrio | Account";
    setActiveNavItem("A");
    GetEngineerData();
    GetOwnerDetails();
  }, []);

  useEffect(() => {
    console.log("Engineer Data", engineerData);
  }, [engineerData]);

  const GetEngineerData = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/mixing-mastering/${window.localStorage.getItem(
        "engineerId"
      )}/studioengineer`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Engineer Data ------------------- ----->", data);
          }
          setEngineerData(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetOwnerDetails = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/user/${window.localStorage.getItem("userId")}`,
      // "http://localhost:3000/user/auth",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("User Details ----->", data);
          }
          setUserData(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onClickLogout = () => {
    window.localStorage.removeItem("userId");
    window.localStorage.removeItem("studioId");
    window.localStorage.removeItem("engineerId");
    OnLogout();
  };

  const OnLogout = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/auth/logout`,
      // "http://localhost:3000/user/auth",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Logged Out ----->", data);
          }
          navigate("/");
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="account-page">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="account-items-container">
        <div className="account-item account-details">
          <div id="account-img-container">
            {engineerData?.image ? (
              <img src={engineerData?.image} alt="profile" id="account-img" />
            ) : (
              <AccountCircleRounded />
            )}
          </div>
          <div className="account-details-container">
            <div className="acount-user-name-container">
              <p>{engineerData?.username}</p>
              <div className="account-ratings-container">
                ⭐<p>{engineerData?.rating}</p>
              </div>
            </div>
            <h1>{engineerData?.firstname + " " + engineerData?.lastname}</h1>
          </div>
        </div>
        {screenWidth < 800 && (
          <div
            className="account-item"
            onClick={() => navigate("/engineer-home/profile")}
          >
            <div id="account-icon-container">
              <ModeEdit id="account-icon" />
            </div>
            <p id="account-item-text">Edit Profile</p>
            <ArrowForwardIosRounded id="account-icon" />
          </div>
        )}
        <div
          className="account-item"
          onClick={() => window.open(`https://jamrio.com/faqs`, "_blank")}
        >
          <div id="account-icon-container">
            <Help id="account-icon" />
          </div>
          <p id="account-item-text">FAQs</p>
          <ArrowForwardIosRounded id="account-icon" />
        </div>
        <div
          className="account-item"
          onClick={() => window.open(`https://jamrio.com/contact-us`, "_blank")}
        >
          <div id="account-icon-container">
            <Call id="account-icon" />
          </div>
          <p id="account-item-text">Contact Us</p>
          <ArrowForwardIosRounded id="account-icon" />
        </div>
        <div className="account-item" onClick={onClickLogout}>
          <div id="account-icon-container">
            <Logout id="account-icon" />
          </div>
          <p id="account-item-text">Logout</p>
          <ArrowForwardIosRounded id="account-icon" />
        </div>
      </div>
    </div>
  );
};

export default EngineerSettings;
