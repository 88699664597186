import "./styles.css";
import { useEffect, useContext, useState } from "react";
import NavContext from "../../Context/NavContext";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
const notifyErr = (field) => toast.error(field);

const EngineerCoupons = () => {
  const { setActiveNavItem } = useContext(NavContext);
  const [allPackagesData, setAllPackagesData] = useState([]);
  const [allCouponsData, setAllCouponsData] = useState([]);
  const [studioPackagesData, setStudioPackagesData] = useState([]);
  const [engineerCouponsData, setEngineerCouponsData] = useState();
  const [couponId, setCouponId] = useState(null);
  const [showPackages, setShowPackages] = useState([]);
  const [openPackagesList, setOpenPackagesList] = useState(false);
  const [openCouponsList, setOpenCouponsList] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    document.title = "Jamrio | Packages & Coupons";
    setActiveNavItem("PC");
    FetchAllCouponsData();
    FetchEngineerCouponsData();
  }, []);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);
  }, []);

  const updatePackagesList = () => {
    studioPackagesData.map((pkg) => setShowPackages((p) => [...p, pkg.id]));
    // setPackagesList(new Set(showPackages));
  };
  useEffect(() => {
    updatePackagesList();
  }, [studioPackagesData, allPackagesData]);

  useEffect(() => {
    console.log("New packages data---->", showPackages);
  }, [showPackages]);

  const FetchAllCouponsData = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/coupons`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${window.localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("All Coupons data ----->", data);
          }
          setAllCouponsData(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const FetchEngineerCouponsData = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/mixing-mastering/${window.localStorage.getItem("engineerId")}/coupons`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${window.localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Engineer Coupons data ----->", data);
          }
          setEngineerCouponsData(data);
          setCouponId(data[0].couponId);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const EnableCoupon = async (code) => {
    //PRODUCTION
    DisableCoupon(couponId);
    console.log("Coupon: ", code);
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/enable`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
        body: JSON.stringify({
          studioengineerId: window.localStorage.getItem("engineerId"),
          couponCode: code,
        }),
      }
    )
      .then((response) => {
        console.log("response", response);
        if (response.status === 409)
          notifyErr(response.status + " Coupon already Added");
        return response.json();
      })
      .then((data) => {
        if (data?.couponId) {
          console.log("coupon data", data);
          setCouponId(data.couponId);
        } else if (data[1] === true) {
          if (process.env.NODE_ENV === "development") {
            console.log("Coupon Added ----->", data);
          }
          setCouponId(data[0].couponId);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data);
          }
          notifyErr("Please remove the selected coupon first");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const DisableCoupon = async (id) => {
    //PRODUCTION
    console.log("Coupon: ", id);
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/disable`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
        body: JSON.stringify({
          studioengineerId: window.localStorage.getItem("engineerId"),
          couponId: id,
        }),
      }
    )
      .then((response) => {
        if (response.status === 409)
          notifyErr(response.status + " Coupon already Added");
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (process.env.NODE_ENV === "development") {
            console.log("Coupon Removed ----->", data);
          }
          setCouponId(null);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data);
          }
          notifyErr(data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="engineer-pc-main-container">
      <ToastContainer
        position={screenWidth < 800 ? "top-center" : "bottom-right"}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <h1 id="pc-main-title2">Offers</h1>
      <div className="engineer-pc-items-container">
        <p>*You can only select one coupon at a time</p>
        <div className="engineer-pc-packages-container">
          <div className="pc-packages-title-container">
            {/* <p id="pc-title">Coupons</p> */}
            <p
              onClick={() => setOpenCouponsList((list) => !list)}
              id="pc-see-more"
            >
              {/* {allCouponsData?.length > 3 && "See More"} */}
            </p>
          </div>
          <div className="eng-pc-packages">
            {allCouponsData?.length > 0 ? (
              allCouponsData?.map((cpn) => (
                <div className="pc-package-item">
                  <p id="package-name">{cpn.description}</p>
                  <p id="package-cost">
                    {cpn.isFinalAmount
                      ? "₹" + cpn.amount
                      : parseInt(cpn.amount) + "%"}
                  </p>
                  <p id="package-hrs">
                    Offer valid upto {moment(cpn.validityEnd).format("DD MMMM")}
                  </p>
                  <button
                    id={`package-${cpn.id === couponId ? "remove" : "add"}-btn`}
                    onClick={() =>
                      cpn.id === couponId
                        ? DisableCoupon(cpn.id)
                        : EnableCoupon(cpn.code)
                    }
                  >
                    {cpn.id === couponId ? "Remove Coupon" : "Add Coupon"}
                  </button>
                </div>
              ))
            ) : (
              <p>No Coupons found</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EngineerCoupons;
