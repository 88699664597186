import { AccountBalanceWalletOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import "./styles.css";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [111, 133, 352, 233, 298, 59, 505],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
};

const EngineerEarnings = () => {
  const [earnings, setEarnings] = useState(0);

  useEffect(() => {
    document.title = "Jamrio | Earning";
    GetEngineerEarnings();
  }, []);

  const GetEngineerEarnings = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/mixing-mastering/${window.localStorage.getItem("engineerId")}/earnings`,
      {
        method: "Get",
        headers: {
          "Content-Type9": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("engineer earnings ----->", data);
          }
          // setCompletedAmount(data);
          setEarnings(data.totalAmount);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <div className="engineer-dashboard-items-left-middle">
        <div className="engineer-dashboard-earnings2-container">
          <div className="engineer-dashboard-earnings-left-container">
            <p id="engineer-dashboard-order-container-title">Earnings</p>
            <div className="engineer-dashboard-earnings-amount-container">
              <AccountBalanceWalletOutlined fontSize="large" />
              <p id="engineer-dashboard-earnings-amount">₹{earnings}</p>
            </div>
            <button id="engineer-dashboard-withdraw-btn">Withdraw</button>
          </div>
          <div className="engineer-dashboard-earnings-graph-container">
            <Line options={options} data={data} />
          </div>
        </div>
      </div>

      <div className="engineer-earning-section">
        <div id="engineer-earning-paid-card">
          <div className="engineer-earning-order">
            <p className="engineer-earning-order-title">Order ID: #21231</p>
            <p className="engineer-earning-order-history">Paid</p>
          </div>
          <div className="engineer-earning-order">
            <h3 className="engineer-earning-order-title-studio">
              Music Studio
            </h3>
            <h4 className="engineer-earning-order-history">₹5999</h4>
          </div>
          <div className="engineer-earning-profile">
            <img
              className="engineer-earning-image"
              height={30}
              width={30}
              src="https://images.jamrio.com/studioengineers/123/1676724738337Free%20webinar%20on%20content%20Marketing.jpg"
              alt=""
            />
            <p>Name</p>
          </div>
          <div className="engineer-earning-details">
            <div>
              <div className="engineer-earning-detail">
                <p className="engineer-earning-detail-title">Date</p>
                <p className="engineer-earning-detail-body">Dec 12 2022</p>
              </div>
              <div className="engineer-earning-detail">
                <p className="engineer-earning-detail-title">TIme</p>
                <p className="engineer-earning-detail-body">03:00-06:00PM</p>
              </div>
            </div>
            <div>
              <div className="engineer-earning-detail">
                <p className="engineer-earning-detail-title">Duration</p>
                <p className="engineer-earning-detail-body">3hr</p>
              </div>
              <div className="engineer-earning-detail">
                <p className="engineer-earning-detail-title">Payment Type</p>
                <p className="engineer-earning-detail-body">UPI</p>
              </div>
            </div>
          </div>
        </div>

        <div id="engineer-earning-paid-card">
          <div className="engineer-earning-order">
            <p className="engineer-earning-order-title">Order ID: #21231</p>
            <p className="engineer-earning-order-history">Paid</p>
          </div>
          <div className="engineer-earning-order">
            <h3 className="engineer-earning-order-title-studio">
              Music Studio
            </h3>
            <h4 className="engineer-earning-order-history">5999</h4>
          </div>
          <div className="engineer-earning-profile">
            <img
              className="engineer-earning-image"
              height={30}
              width={30}
              src="https://images.jamrio.com/studioengineers/123/1676724738337Free%20webinar%20on%20content%20Marketing.jpg"
              alt=""
            />
            <p>Name</p>
          </div>
          <div className="engineer-earning-details">
            <div>
              <div className="engineer-earning-detail">
                <p className="engineer-earning-detail-title">Date</p>
                <p className="engineer-earning-detail-body">Dec 12 2022</p>
              </div>
              <div className="engineer-earning-detail">
                <p className="engineer-earning-detail-title">TIme</p>
                <p className="engineer-earning-detail-body">03:00-06:00PM</p>
              </div>
            </div>
            <div>
              <div className="engineer-earning-detail">
                <p className="engineer-earning-detail-title">Duration</p>
                <p className="engineer-earning-detail-body">3hr</p>
              </div>
              <div className="engineer-earning-detail">
                <p className="engineer-earning-detail-title">Payment Type</p>
                <p className="engineer-earning-detail-body">UPI</p>
              </div>
            </div>
          </div>
        </div>
        <div id="engineer-earning-paid-card">
          <div className="engineer-earning-order">
            <p className="engineer-earning-order-title">Order ID: #21231</p>
            <p className="engineer-earning-order-history">Paid</p>
          </div>
          <div className="engineer-earning-order">
            <h3 className="engineer-earning-order-title-studio">
              Music Studio
            </h3>
            <h4 className="engineer-earning-order-history">5999</h4>
          </div>
          <div className="engineer-earning-profile">
            <img
              className="engineer-earning-image"
              height={30}
              width={30}
              src="https://images.jamrio.com/studioengineers/123/1676724738337Free%20webinar%20on%20content%20Marketing.jpg"
              alt=""
            />
            <p>Name</p>
          </div>
          <div className="engineer-earning-details">
            <div>
              <div className="engineer-earning-detail">
                <p className="engineer-earning-detail-title">Date</p>
                <p className="engineer-earning-detail-body">Dec 12 2022</p>
              </div>
              <div className="engineer-earning-detail">
                <p className="engineer-earning-detail-title">TIme</p>
                <p className="engineer-earning-detail-body">03:00-06:00PM</p>
              </div>
            </div>
            <div>
              <div className="engineer-earning-detail">
                <p className="engineer-earning-detail-title">Duration</p>
                <p className="engineer-earning-detail-body">3hr</p>
              </div>
              <div className="engineer-earning-detail">
                <p className="engineer-earning-detail-title">Payment Type</p>
                <p className="engineer-earning-detail-body">UPI</p>
              </div>
            </div>
          </div>
        </div>
        <div id="engineer-earning-paid-card">
          <div className="engineer-earning-order">
            <p className="engineer-earning-order-title">Order ID: #21231</p>
            <p className="engineer-earning-order-history">Paid</p>
          </div>
          <div className="engineer-earning-order">
            <h3 className="engineer-earning-order-title-studio">
              Music Studio
            </h3>
            <h4 className="engineer-earning-order-history">5999</h4>
          </div>
          <div className="engineer-earning-profile">
            <img
              className="engineer-earning-image"
              height={30}
              width={30}
              src="https://images.jamrio.com/studioengineers/123/1676724738337Free%20webinar%20on%20content%20Marketing.jpg"
              alt=""
            />
            <p>Name</p>
          </div>
          <div className="engineer-earning-details">
            <div>
              <div className="engineer-earning-detail">
                <p className="engineer-earning-detail-title">Date</p>
                <p className="engineer-earning-detail-body">Dec 12 2022</p>
              </div>
              <div className="engineer-earning-detail">
                <p className="engineer-earning-detail-title">TIme</p>
                <p className="engineer-earning-detail-body">03:00-06:00PM</p>
              </div>
            </div>
            <div>
              <div className="engineer-earning-detail">
                <p className="engineer-earning-detail-title">Duration</p>
                <p className="engineer-earning-detail-body">3hr</p>
              </div>
              <div className="engineer-earning-detail">
                <p className="engineer-earning-detail-title">Payment Type</p>
                <p className="engineer-earning-detail-body">UPI</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EngineerEarnings;
