import "./styles.css";
import { useEffect, useContext, useState } from "react";
import NavContext from "../../Context/NavContext";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
const notifyErr = (field) => toast.error(field);

const StudioPackagesCoupons = () => {
  const { setActiveNavItem } = useContext(NavContext);
  const [allPackagesData, setAllPackagesData] = useState([]);
  const [allCouponsData, setAllCouponsData] = useState([]);
  const [studioPackagesData, setStudioPackagesData] = useState([]);
  const [studioCouponsData, setStudioCouponsData] = useState();
  const [couponId, setCouponId] = useState(null);
  const [showPackages, setShowPackages] = useState([]);
  const [openPackagesList, setOpenPackagesList] = useState(false);
  const [openCouponsList, setOpenCouponsList] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);
  }, []);

  useEffect(() => {
    document.title = "Jamrio | Packages & Coupons";
    GetStudioData();
    setActiveNavItem("PC");
    FetchAllPackagesData();
    FetchAllCouponsData();
    FetchStudioPackagesData();
    FetchStudioCouponsData();
  }, []);

  const updatePackagesList = () => {
    studioPackagesData.map((pkg) => setShowPackages((p) => [...p, pkg.id]));
    // setPackagesList(new Set(showPackages));
  };
  useEffect(() => {
    updatePackagesList();
  }, [studioPackagesData, allPackagesData]);

  useEffect(() => {
    console.log("New packages data---->", showPackages);
  }, [showPackages]);

  const GetStudioData = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN
      }/studio/${window.localStorage.getItem("studioId")}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Studio Data ------------------- ----->", data);
          }
          setStudioCouponsData(data.coupons);
          setCouponId(data.coupons?.id);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // TODO - Integrate APIs for Adding and Removing packages and coupons
  const FetchAllPackagesData = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/studio/packages`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${window.localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("All Packages data ----->", data);
          }
          setAllPackagesData(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const FetchAllCouponsData = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/coupons`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${window.localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("All Coupons data ----->", data);
          }
          setAllCouponsData(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const FetchStudioPackagesData = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN
      }/studio/packages/studioPackage/${window.localStorage.getItem(
        "studioId"
      )}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${window.localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Studio Packages data ----->", data);
          }
          setStudioPackagesData(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const FetchStudioCouponsData = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN
      }/studio/${window.localStorage.getItem("studioId")}/coupons`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${window.localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Studio Coupons data ----->", data);
          }
          setStudioCouponsData(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const AddPackage = async (id) => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/studio/packages/addToStudio`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
        body: JSON.stringify({
          studio_id: window.localStorage.getItem("studioId"),
          package_id: id,
        }),
      }
    )
      .then((response) => {
        if (response.status === 409)
          notifyErr(response.status + " Package already Added");
        return response.json();
      })
      .then((data) => {
        if (data.id) {
          if (process.env.NODE_ENV === "development") {
            console.log("Package Added ----->", data);
          }
          FetchStudioPackagesData();
          FetchAllPackagesData();
          updatePackagesList();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
          notifyErr(data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const RemovePackage = async (id) => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/studio/packages/delete`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
        body: JSON.stringify({
          studio_id: window.localStorage.getItem("studioId"),
          package_id: id,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.id) {
          if (process.env.NODE_ENV === "development") {
            console.log("Package Removed ----->", data);
          }
          FetchStudioPackagesData();
          const pkgId = data.package_id;
          setShowPackages(
            showPackages.filter((item) => {
              return item !== pkgId;
            })
          );
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
          notifyErr(data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const EnableCoupon = async (code) => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN
      }/studio/${window.localStorage.getItem("studioId")}/coupons/enable`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
        body: JSON.stringify({
          studioId: window.localStorage.getItem("studioId"),
          couponCode: code,
        }),
      }
    )
      .then((response) => {
        if (response.status === 409)
          notifyErr(response.status + " Coupon already Added");
        return response.json();
      })
      .then((data) => {
        if (data[0]?.id) {
          if (process.env.NODE_ENV === "development") {
            console.log("Coupon Added ----->", data);
          }
          setCouponId(data[0].couponId);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
          notifyErr(data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [isCoupons, setIsCoupons] = useState(false);

  

  return (
    <div className="pc-main-container">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <h1 id="pc-main-title">Packages & Coupons</h1>
      <div id="pc-toggle-buttons">
        <button className={!isCoupons ? `selected pc-buttons` : `pc-buttons`} id="package-button" onClick={()=>setIsCoupons(false)}>Packages</button>
        <button className={isCoupons ? `selected pc-buttons` : `pc-buttons`} id="coupon-button" onClick={()=>setIsCoupons(true)}>Coupons</button>
      </div>
      <div className="pc-items-container">
        {
          !isCoupons &&
          <div id="packagesContainer" className="pc-packages-container">
            <div className="pc-packages-title-container">
              {/* <p id="pc-title">Packages</p> */}
              <p
                onClick={() => {
                  setOpenPackagesList((list) => !list);
                }}
                id="pc-see-more"
              >
                {allPackagesData?.length > 3 && "See More"}
              </p>
            </div>
            <div className="pc-packages">
              {allPackagesData?.length > 0 ? (
                allPackagesData
                  ?.slice(
                    0,
                    !openPackagesList && screenWidth < 800
                      ? 2
                      : !openPackagesList
                        ? 3
                        : allPackagesData.length
                  )
                  .map((pkg) => (
                    <div className="pc-package-item">
                      <p id="package-name">{pkg.name}</p>
                      <p id="package-desc">{pkg.description}</p>
                      <p id="package-cost">₹{pkg.cost}</p>
                      <p id="package-hrs"> for {pkg.hours_allowed}hrs</p>
                      <button
                        id={`package-${new Set(showPackages).has(pkg.id) ? "remove" : "add"
                          }-btn`}
                        onClick={() => {
                          new Set(showPackages).has(pkg.id)
                            ? RemovePackage(pkg.id)
                            : AddPackage(pkg.id);
                        }}
                      >
                        {new Set(showPackages).has(pkg.id) ? "Remove" : "Add"}{" "}
                        Package
                      </button>
                    </div>
                  ))
              ) : (
                <p>No Coupons found</p>
              )}
            </div>
          </div>
        }
        {
          isCoupons &&
          <div className="pc-packages-container">
            <div className="pc-packages-title-container">
              {/* <p id="pc-title">Coupons</p> */}
              <p
                onClick={() => setOpenCouponsList((list) => !list)}
                id="pc-see-more"
              >
                {allCouponsData?.length > 3 && "See More"}
              </p>
            </div>
            <div className="pc-packages">
              {allCouponsData?.length > 0 ? (
                allCouponsData
                  ?.slice(0, !openCouponsList ? 3 : allCouponsData.length)
                  .map((cpn) => (
                    <div className="pc-package-item">
                      <p id="package-name">{cpn.description}</p>
                      <p id="package-cost">{cpn.code}</p>
                      <p id="package-hrs">
                        Upto {moment(cpn.validityEnd).format("DD MMMM")}
                      </p>
                      <button
                        id={`package-${cpn.id === couponId ? "remove" : "add"
                          }-btn`}
                        onClick={() => EnableCoupon(cpn.code)}
                      >
                        {cpn.id === couponId ? "Coupon Added" : "Add Coupon"}
                      </button>
                    </div>
                  ))
              ) : (
                <p>No Coupons found</p>
              )}
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default StudioPackagesCoupons;
