import React, { useContext, useEffect, useState } from "react";
import "./styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import BookingDetailsContext from "../../Context/BookingDetailsContext.js";
import ScaleLoader from "react-spinners/ScaleLoader";
import Alert from "@mui/material/Alert";
import { FaFileAudio } from "react-icons/fa";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../Images/jamrioLogo.webp";
import io from "socket.io-client";
import OrderStatus from "../../Components/OrderStatus/OrderStatus";

const socket = io(`https://${process.env.REACT_APP_DOMAIN}/inbox`, {
  extraHeaders: {
    Authorization: window.localStorage.getItem("AuthToken"),
  },
});

function EngineerOrderHistory({ token }) {
  const [uploading, setUploading] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [messageText, setMessageText] = useState();
  const [orderDetails, setOrderDetails] = useState([]);
  const [inboxId, setInboxId] = useState([]);
  const [inboxes, setInboxes] = useState([]);
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Jamr | Order History";
    GetUploadedFiles();
    FetchOrderDetails();
    GetInboxes();
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [orderDetails]);

  //File Upload
  const fileTypes = [
    "PCM",
    "WAV",
    "MP3",
    "OGG",
    "FLAC",
    "WMA",
    "M4A",
    "AAC",
    "PNG",
    "JPG",
  ];
  const [file, setFile] = useState(null);
  const handleFileChange = (file) => {
    setFile(file);
    console.log("Selected file", file);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    justifyContent: "center",
  };
  useEffect(() => {
    console.log("order Data -----> ", orderDetails);
  }, [orderDetails]);

  useEffect(() => {
    setTimeout(() => setShowAlert(false), 4000);
  }, [showAlert]);

  useEffect(() => {
    setInboxId([]);
    setInboxId(inboxes.filter((inbox) => inbox.userId === orderDetails.userid));
  }, [inboxes, orderDetails]);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.log("Inbox id", inboxId);
    }
  }, [inboxId]);

  //Get order details
  const FetchOrderDetails = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/order/${id}/get`,
      {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("GET order details ----->", data);
          }
          setOrderDetails(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //Fetch user details
  const fetchUserData = async () => {
    console.log("USER ID--------->", orderDetails?.userid);
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/user/${orderDetails?.userid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Response Body -> ", JSON.parse(JSON.stringify(data)));

        if (!data.message) {
          setUserData(data);
          console.log("USER DETAILS ----->", data);
        } else {
          console.log("Failed", data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //Order accepting and rejecting api

  const AcceptOrder = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/order/${id}/approve/accept`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.status;
      })
      .then((data) => {
        console.log("order accepted:", data);
        if (data === 200) {
          FetchOrderDetails();
        } else {
          console.log("Failed");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const RejectOrder = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/order/${id}/approve/reject`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.status;
      })
      .then((data) => {
        console.log("order accepted:", data);
        if (data === 200) {
          FetchOrderDetails();
        } else {
          console.log("Failed");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAccept = (orderId) => {
    AcceptOrder(orderId);
  };

  const handleReject = (orderId) => {
    RejectOrder(orderId);
  };

  // File upload api

  const UploadFile = async (data) => {
    const formData = new FormData();

    formData.append("file", data);

    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/${id}/files/upload/${orderDetails?.engineer_id}/engineer`,
      {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
        body: formData,
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.isError) {
          console.log("File uploading ----->", data);
          setUploading(false);
          setShowAlert(true);
          setFile(null);
          toast.success("File uploaded successfully!");
          GetUploadedFiles();
        } else {
          console.log("Failed", data.isError);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Get uploaded files
  const GetUploadedFiles = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/${id}/files`,
      {
        method: "get",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          console.log("GET uploaded files ----->", data);
          setAllFiles(data);
        } else {
          console.log("Failed", data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const DownloadFile = async (key) => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/${orderDetails?.engineer_id}/files/get?file=${key}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (process.env.NODE_ENV === "development") {
          console.log("Response Body -> ", JSON.parse(JSON.stringify(data)));
        }

        if (!data.code) {
          if (process.env.NODE_ENV === "development") {
            console.log("File DETAILS ----->", data);
          }
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data?.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetInboxes = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/inbox/engineer/${window.localStorage.getItem("engineerId")}`,
      // "http://localhost:3000/user/auth",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.inbox) {
          if (process.env.NODE_ENV === "development") {
            console.log("Inboxes ----->", data);
          }
          setInboxes(data.inbox);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Messaging
  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected");
    });

    socket.on("error", (err) => {
      // setIsConnected(true);
      console.log("error", err);
    });

    socket.on("send-message", (data) => {
      // setIsConnected(true);
      console.log("send message", data);
      setMessageText("");
      handleClose();
      toast.success("Message sent successfully!");
    });

    socket.on("deliver-message", () => {
      console.log("deliver");
    });

    socket.on("read-message", () => {
      console.log("read");
    });

    socket.on("receive", (data) => {
      console.log("receive", data);
    });

    socket.on("disconnect", () => {});

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);

  const sendMessage = () => {
    console.log(
      "messageText",
      messageText,
      "orderid",
      orderDetails?.id,
      "userid",
      orderDetails?.userid,
      "engineerid",
      orderDetails?.engineer_id
    );
    if (messageText !== "") {
      try {
        let testsocket = socket.emit("send-message", {
          inbox: {
            inboxType: "engineerOrder",
            orderId: orderDetails?.id,
            engineerId: orderDetails?.engineer_id,
            userId: orderDetails?.userid,
          },
          message: {
            messageType: "text",
            text: messageText,
            sender: "engineer",
            receiver: "user",
          },
        });
        console.log("Emitted", testsocket);
      } catch (error) {
        console.log("error", error);
      }
    } else {
      toast.error("Message cannot be empty");
    }
  };

  return (
    <div className="EngineerOrderHistory">
      <img
        onClick={() => navigate("/engineer-home/dashboard")}
        src={Logo}
        alt="Jamrio logo"
        id="jamrio-logo"
      />
      <div className="engineer-order-history-main-container">
        <div className="engineer-left-main-container">
          <div className="order-profile-container">
            <div className="profile-image">
              <img
                src="https://images.unsplash.com/photo-1501196354995-cbb51c65aaea?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80"
                alt="profile"
                className="profile-pic"
              />
            </div>
            <div className="profile-details">
              <h1 className="name">{userData?.name}</h1>
              <div className="contact-container">
                <img
                  src="https://img.icons8.com/ios-filled/50/ffffff/new-post.png"
                  alt="email"
                  className="contact-icons"
                />
                <h2 className="email">{userData?.email}</h2>
              </div>
              <div className="contact-container">
                <img
                  src="https://img.icons8.com/material-outlined/50/ffffff/iphone--v1.png"
                  alt="phone"
                  className="contact-icons"
                />
                <h2 className="phone">{userData?.mobile}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="engineer-right-main-container">
          <div className="right-top-container">
            <div className="engineer-request-status-container">
              <h2>Order Status</h2>
              {orderDetails && orderDetails.state === "approval_pending" ? (
                <div className="actionBtn-container">
                  <button id="acceptBtn" onClick={() => handleAccept(id)}>
                    Accept
                  </button>
                  <button id="rejectBtn" onClick={() => handleReject(id)}>
                    Reject
                  </button>
                </div>
              ) : (
                <h2 id="order-status">{orderDetails?.state}</h2>
              )}
              <h3 id="order-id">{id}</h3>
            </div>
          </div>
          <div className="order-details-container">
            <div className="order-details-text-content">
              <div id="text-container">
                <div className="order-details-content">
                  <p className="detail-title">Selected Service: </p>
                  <h1 className="detail">Stereo Mastering</h1>
                </div>
                <div className="order-details-content">
                  <p className="detail-title">Payment Status: </p>
                  <h1 className="detail">Paid</h1>
                </div>
                <div className="order-details-content">
                  <p className="detail-title">Amount Paid: </p>
                  <h1 className="detail">₹{orderDetails?.netamount}</h1>
                </div>
              </div>
            </div>
          </div>
          {orderDetails?.state === "approval_pending" ||
          orderDetails?.state === "success" ? (
            <div className="engineer-messaging-file-container">
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyle}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Message Studio
                  </Typography>
                  <input
                    id="order-history-chat-input"
                    type="text"
                    placeholder="Type your message ..."
                    value={messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                  />
                  <button className="send-msg-btn" onClick={sendMessage}>
                    Send Message
                  </button>
                </Box>
              </Modal>
              <div className="message-container">
                <h2 className="order-status-title">
                  Message the User for any queries
                </h2>
                <div className="message-btn-container">
                  {inboxId[0]?.id ? (
                    <button
                      className="send-msg-btn"
                      onClick={() => navigate("/engineer-home/inbox")}
                    >
                      Open Inbox
                    </button>
                  ) : (
                    <button className="send-msg-btn" onClick={handleOpen}>
                      Send Message
                    </button>
                  )}
                </div>
              </div>
              <div className="file-upload-container">
                <div className="file-upload-inner-container">
                  <div>
                    <FileUploader
                      handleChange={handleFileChange}
                      name="file"
                      types={fileTypes}
                      classes="file-upload-box"
                      maxSize={1024}
                      children={
                        <>
                          <h2>Drag and Drop a File here</h2>
                          <h2>OR</h2>
                          <h3 className="modalBtn modalBtn2">
                            Click here to select a file
                          </h3>
                          <p>Max file size 1GB</p>
                        </>
                      }
                    />
                  </div>
                </div>
                <ToastContainer
                  position="bottom-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="colored"
                />
                {file ? (
                  <>
                    <div className="selected-file-container">
                      <div className="selected-file">
                        <h1 className="detail">{file.name}</h1>
                      </div>
                    </div>
                    <button
                      className="modalBtn modalBtn3"
                      onClick={() => {
                        setUploading(true);
                        UploadFile(file);
                      }}
                    >
                      {uploading ? (
                        <div>
                          <ScaleLoader
                            color={"#fff"}
                            loading={uploading}
                            height={20}
                            width={5}
                            radius={50}
                          />
                        </div>
                      ) : (
                        <p>Upload File</p>
                      )}
                    </button>
                  </>
                ) : null}
                {showAlert ? (
                  <Alert severity="success">File Uploaded Successfully!</Alert>
                ) : null}
                {allFiles ? (
                  <div className="uploaded-file-container">
                    <h3>Uploaded Files</h3>
                    <div className="file-container">
                      {allFiles.map((file) => {
                        let fileName = file.filename;
                        return (
                          <div
                            className="uploaded-file"
                            key={file.url}
                            // onClick={() => {
                            //   DownloadFile(file.file_key);
                            // }}
                          >
                            <a
                              // Divide link after ? to get the file key
                              onClick={() =>
                                console.log(
                                  file.url
                                    .split("?")[0]
                                    ?.replace(
                                      "https://jamrioapp.s3.ap-south-1.amazonaws.com",
                                      "files.jamrio.com"
                                    )
                                )
                              }
                              download={file.filename}
                              className="file-link"
                              href={file.url
                                .split("?")[0]
                                ?.replace(
                                  "jamrioapp.s3.ap-south-1.amazonaws.com",
                                  "files.jamrio.com"
                                )}
                              target={"_blank"}
                              rel="noreferrer"
                            >
                              <FaFileAudio size={26} color={"#888"} />
                              <p className="file-name">
                                {fileName?.slice(0, -4).substring(0, 20) +
                                  "..."}
                              </p>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
          {/* {orderDetails?.state === "success" && <OrderStatus />} */}
        </div>
      </div>
    </div>
  );
}

export default EngineerOrderHistory;
