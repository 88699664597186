import { createContext, useState } from "react";

const BookingDetailsContext = createContext();

export function BookingDetailsProvider({ children }) {
  const [transactionId, setTransactionId] = useState();
  const [orderId, setOrderId] = useState();
  const [trnStudioId, setTrnStudioId] = useState();
  return (
    <BookingDetailsContext.Provider
      value={{
        transactionId,
        setTransactionId,
        orderId,
        setOrderId,
        trnStudioId,
        setTrnStudioId,
      }}
    >
      {children}
    </BookingDetailsContext.Provider>
  );
}

export default BookingDetailsContext;
