import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Setting up toast function
const notifySlotsErr = (message) =>
  toast.error(message, { position: "bottom-right" });

const notifyCheckboxErr = (message) =>
  toast.error(message, { position: "bottom-right" });

const notifyOrderProcessing = () =>
  toast.info("Processing order", { position: "bottom-right" });

const notifyPaymentErr = (message) =>
  toast.error(message, { position: "bottom-right" });

const notifyPaymentSuccess = () =>
  toast.success("Payment successful!", { position: "bottom-right" });

const notifyReferralSuccess = () =>
  toast.success("Referral code added successfully!", {
    position: "bottom-right",
  });

const notifyLoginUnameErr = () =>
  toast.error("Invalid Credentials!", { position: "bottom-right" });

const notifyRegistrationErr = (field) =>
  toast.error(field, { position: "bottom-right" });
const notifyRegistrationSuccess = (message) =>
  toast.success(message, { position: "bottom-right" });

const notifyErr = (field) => toast.error(field, { position: "bottom-right" });
const notifySuccess = (field) => toast.success(field, { position: "bottom-right" });
export {
  notifySlotsErr,
  notifyPaymentErr,
  notifyPaymentSuccess,
  notifyOrderProcessing,
  notifyLoginUnameErr,
  notifyRegistrationErr,
  notifyRegistrationSuccess,
  notifyReferralSuccess,
  notifyCheckboxErr,
  notifyErr,
  notifySuccess
};
