import "./styles.css";
import { FaBars, FaTimes } from "react-icons/fa";
import {
  Dashboard,
  EventNote,
  Inbox,
  BarChart,
  Link,
  Person,
  Logout,
  LocalOffer,
  SwapHoriz,
  AccountCircleOutlined,
} from "@mui/icons-material";
import Logo from "../../Images/jamrioLogo.webp";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import StudioDashboard from "../../Components/StudioDashboard/StudioDashboard";
import StudioBookings from "../../Components/StudioBookings/StudioBookings";
import { useContext } from "react";
import NavContext from "../../Context/NavContext";
import StudioInbox from "../../Components/StudioInbox/StudioInbox";
import StudioPackagesCoupons from "../../Components/StudioPackagesCoupons/StudioPackagesCoupons";
import StudioAccount from "../../Components/StudioAccount/StudioAccount";
import { ToastContainer } from "react-toastify";

const StudioHome = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { activeNavItem } = useContext(NavContext);
  const [studioDetails, setStudioDetails] = useState();
  const [pathName, setPathName] = useState();
  const { component } = useParams();
  const [clicked, setClicked] = useState(false);
  const [ownerIds, setOwnerIds] = useState({
    studio: "",
    engineer: "",
  });
  const navigate = useNavigate();
  //-------------NAV ITEMS--------------------//
  // Dashboard ------- D
  // Bookings ------- B
  // Inbox ------- I
  // Statistics ------- S
  // Packages & Coupons ------- PC
  // Link ------- L
  // Profile ------- P
  // Account ------- A

  useEffect(() => {
    GetStudioData();
    GetOwnerDetails();
    RefreshToken();
  }, []);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);
  }, []);

  const setActiveComponent = () => {
    switch (component) {
      case "dashboard":
        return <StudioDashboard />;
      // break;
      case "bookings-pending":
        return <StudioBookings />;
      case "bookings-upcoming":
        return <StudioBookings />;
      case "bookings-total":
        return <StudioBookings />;
      case "inbox":
        return <StudioInbox />;
      case "packages-coupons":
        return <StudioPackagesCoupons />;
      case "account":
        return <StudioAccount />;
      default:
        return <StudioDashboard />;
      // break;
    }
  };

  const GetStudioData = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/studio/${window.localStorage.getItem("studioId")}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Studio Data ------------------- ----->", data);
          }
          setStudioDetails(data.studio);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const RefreshToken = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/auth/refresh`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        if (response.status === 401) {
          OnLogout();
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data.token) {
          if (process.env.NODE_ENV === "development") {
            console.log("Refresh token ------------------- ----->", data);
          }
          window.localStorage.setItem("AuthToken", data.token.token);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetOwnerDetails = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/user/${window.localStorage.getItem("userId")}`,
      // "http://localhost:3000/user/auth",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("User Details ----->", data);
          }
          setOwnerIds({
            studio: data.studioid,
            engineer: data.studioengineerId,
          });
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onClickLogout = () => {
    window.localStorage.removeItem("userId");
    window.localStorage.removeItem("studioId");
    window.localStorage.removeItem("engineerId");
    OnLogout();
  };

  const OnLogout = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/auth/logout`,
      // "http://localhost:3000/user/auth",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Logged Out ----->", data);
          }
          navigate("/");
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="studioHome">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {screenWidth > 800 ? (
        <div className={"studio-home-navbar"}>
          <p id="studio-name">{studioDetails?.name}</p>
          <button
            onClick={() => {
              navigate("/studio-home/dashboard");
            }}
            className={
              activeNavItem === "D"
                ? "studioHome-navbar-items-selected"
                : "studioHome-navbar-items"
            }
          >
            <div className="studioHome-navbar-items-icons-container">
              <Dashboard className="studioHome-navbar-items-icon" />
            </div>
            <p className="studioHome-navbar-items-text">Dashboard</p>
          </button>
          <button
            onClick={() => {
              navigate("/studio-home/bookings-pending");
            }}
            className={
              activeNavItem === "B"
                ? "studioHome-navbar-items-selected"
                : "studioHome-navbar-items"
            }
          >
            <div className="studioHome-navbar-items-icons-container">
              <EventNote className="studioHome-navbar-items-icon" />
            </div>
            <p className="studioHome-navbar-items-text">Bookings</p>
          </button>
          <button
            onClick={() => {
              navigate("/studio-home/inbox");
            }}
            className={
              activeNavItem === "I"
                ? "studioHome-navbar-items-selected"
                : "studioHome-navbar-items"
            }
          >
            <div className="studioHome-navbar-items-icons-container">
              <Inbox className="studioHome-navbar-items-icon" />
            </div>
            <p className="studioHome-navbar-items-text">Inbox</p>
          </button>
          <button
            onClick={() => {
              navigate("/studio-home/packages-coupons");
            }}
            className={
              activeNavItem === "PC"
                ? "studioHome-navbar-items-selected"
                : "studioHome-navbar-items"
            }
          >
            <div className="studioHome-navbar-items-icons-container">
              <LocalOffer className="studioHome-navbar-items-icon" />
            </div>
            <p className="studioHome-navbar-items-text">Offers</p>
          </button>
          <button
            onClick={() => {
              navigate("/studio-home/account");
            }}
            className={
              activeNavItem === "A"
                ? "studioHome-navbar-items-selected"
                : "studioHome-navbar-items"
            }
          >
            <div className="studioHome-navbar-items-icons-container">
              <AccountCircleOutlined className="studioHome-navbar-items-icon" />
            </div>
            <p className="studioHome-navbar-items-text">Account</p>
          </button>
          <button
            className="studioHome-navbar-items studio-logout-btn"
            onClick={onClickLogout}
          >
            <div className="studioHome-navbar-items-icons-container">
              <Logout className="studioHome-navbar-items-icon" />
            </div>
            <p className="studioHome-navbar-items-text">Logout</p>
          </button>
          {ownerIds.engineer !== null && (
            <button
              className="studioHome-navbar-items"
              id="studio-switch-btn"
              onClick={() => navigate("/engineer-home/dashboard")}
            >
              <div className="studioHome-navbar-items-icons-container">
                <SwapHoriz className="studioHome-navbar-items-icon" />
              </div>
              <p className="studioHome-navbar-items-text">
                Switch To Engineer Dashboard
              </p>
            </button>
          )}
        </div>
      ) : (
        <div className="navbar-mobile">
          <button
            onClick={() => {
              navigate("/studio-home/bookings-pending");
            }}
            className={
              activeNavItem === "B"
                ? "studioHome-navbar-items-phone-selected"
                : "studioHome-navbar-items-phone"
            }
          >
            <div className="studioHome-navbar-items-phone-icons-container">
              <EventNote className="studioHome-navbar-items-phone-icon" />
            </div>
            <p className="studioHome-navbar-items-phone-text">Bookings</p>
          </button>
          <button
            onClick={() => {
              navigate("/studio-home/inbox");
            }}
            className={
              activeNavItem === "I"
                ? "studioHome-navbar-items-phone-selected"
                : "studioHome-navbar-items-phone"
            }
          >
            <div className="studioHome-navbar-items-phone-icons-container">
              <Inbox className="studioHome-navbar-items-phone-icon" />
            </div>
            <p className="studioHome-navbar-items-phone-text">Inbox</p>
          </button>
          <button
            onClick={() => {
              navigate("/studio-home/dashboard");
            }}
            className={
              activeNavItem === "D"
                ? "studioHome-navbar-items-phone-selected"
                : "studioHome-navbar-items-phone"
            }
          >
            <div className="studioHome-navbar-items-phone-icons-container">
              <Dashboard className="studioHome-navbar-items-phone-icon" />
            </div>
            <p className="studioHome-navbar-items-phone-text">Dashboard</p>
          </button>
          <button
            onClick={() => {
              navigate("/studio-home/packages-coupons");
            }}
            className={
              activeNavItem === "PC"
                ? "studioHome-navbar-items-phone-selected"
                : "studioHome-navbar-items-phone"
            }
          >
            <div className="studioHome-navbar-items-phone-icons-container">
              <LocalOffer className="studioHome-navbar-items-phone-icon" />
            </div>
            <p className="studioHome-navbar-items-phone-text">Offers</p>
          </button>
          <button
            onClick={() => {
              navigate("/studio-home/account");
            }}
            className={
              activeNavItem === "A"
                ? "studioHome-navbar-items-phone-selected"
                : "studioHome-navbar-items-phone"
            }
          >
            <div className="studioHome-navbar-items-phone-icons-container">
              <AccountCircleOutlined className="studioHome-navbar-items-phone-icon" />
            </div>
            <p className="studioHome-navbar-items-phone-text">Account</p>
          </button>
          {/* <button
            className="studioHome-navbar-items-phone studio-logout-btn"
            onClick={onClickLogout}
          >
            <div className="studioHome-navbar-items-phone-icons-container">
              <Logout className="studioHome-navbar-items-phone-icon" />
            </div>
            <p className="studioHome-navbar-items-phone-text">Logout</p>
          </button>
          {ownerIds.engineer !== null && (
            <button
              className="studioHome-navbar-items-phone"
              id="studio-switch-btn"
              onClick={() => navigate("/engineer-home/dashboard")}
            >
              <div className="studioHome-navbar-items-phone-icons-container">
                <SwapHoriz className="studioHome-navbar-items-phone-icon" />
              </div>
              <p className="studioHome-navbar-items-phone-text">
                Switch To Engineer Dashboard
              </p>
            </button>
          )} */}
        </div>
      )}
      <div
        className={
          clicked ? "studio-home-content-fullwidth" : "studio-home-content"
        }
      >
        <img
          onClick={() => navigate("/studio-home/dashboard")}
          src={Logo}
          alt="Jamrio logo"
          id="studio-jamrio-logo"
        />
        {/* <div id="mobile" onClick={clickHandler}>
          <i id="bar" className={clicked ? "fas fa-bars" : "fas fa-times"}></i>
        </div> */}
        <div id="studio-home-content-left"></div>
        {setActiveComponent()}
      </div>
    </div>
  );
};

export default StudioHome;
