import "./styles.css";
import React, { useState, useRef, useEffect, useContext } from "react";
import WaveSurfer from "wavesurfer.js";
import * as WaveSurferRegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions";
import {
  InputLabel,
  MenuItem,
  FormControl,
  OutlinedInput,
  Select,
} from "@mui/material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import headerImg from "./header.png";
import Rock from "./Rock.png";
import Jazz from "./Jazz.png";
import Pop from "./Pop.png";
import HipHop from "./Hip-Hop.png";
import Folk from "./Folk.png";
import EngineerContainers from "../../Components/EngineerContainers/EngineerContainers";
import placeholderImg from "../../Images/noImg.webp";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ToastContainer, toast } from "react-toastify";
import { Close, DeleteOutline, Edit } from "@mui/icons-material";
import {
  Modal,
  Box,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import NavContext from "../../Context/NavContext";
import SelectMUI from "@mui/material/Select";
import ScaleLoader from "react-spinners/ScaleLoader";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1200, min: 700 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 1,
  },
};

const notifyErr = (field) => toast.error(field);
const notifySuccess = (field) => toast.success(field);

const ProfileEdit = ({
  editableProfileDetails,
  handleInputChange,
  updateEngineerDetails,
}) => (
  <Box
    component="form"
    sx={{
      "& > :not(style)": { marginY: 1, width: "100%" },
    }}
    noValidate
    autoComplete="off"
  >
    <TextField
      className="outlined-basic"
      id="engineer-firstname"
      label="First Name"
      variant="outlined"
      name="firstname"
      value={editableProfileDetails.firstname}
      onChange={handleInputChange}
      fullWidth
    />
    <TextField
      className="outlined-basic"
      id="engineer-lastname"
      label="Last Name"
      variant="outlined"
      name="lastname"
      value={editableProfileDetails.lastname}
      onChange={handleInputChange}
      fullWidth
    />
    <TextField
      className="outlined-basic"
      id="engineer-username"
      label="Username"
      variant="outlined"
      name="username"
      value={editableProfileDetails.username}
      onChange={handleInputChange}
      fullWidth
    />
    <TextField
      className="outlined-basic"
      id="engineer-email"
      label="Email"
      variant="outlined"
      name="email"
      value={editableProfileDetails.email}
      onChange={handleInputChange}
      fullWidth
    />
    <TextField
      className="outlined-basic"
      id="engineer-city"
      label="City"
      variant="outlined"
      name="city"
      value={editableProfileDetails.city}
      onChange={handleInputChange}
      fullWidth
    />
    <TextField
      className="outlined-basic"
      id="engineer-pincode"
      label="Pincode"
      variant="outlined"
      name="pincode"
      value={editableProfileDetails.pincode}
      onChange={handleInputChange}
      fullWidth
    />
    <TextField
      className="outlined-basic"
      id="engineer-country"
      label="Country"
      variant="outlined"
      name="country"
      value={editableProfileDetails.country}
      onChange={handleInputChange}
      fullWidth
    />
    <TextField
      className="outlined-basic"
      id="engineer-country"
      label="About"
      variant="outlined"
      name="bio"
      value={editableProfileDetails.bio}
      onChange={handleInputChange}
      fullWidth
    />
    <TextField
      className="outlined-basic"
      id="engineer-country"
      label="Spotify"
      variant="outlined"
      name="spotify"
      value={editableProfileDetails.spotify}
      onChange={handleInputChange}
      fullWidth
    />
    <TextField
      className="outlined-basic"
      id="engineer-country"
      label="Instagram"
      variant="outlined"
      name="instagram"
      value={editableProfileDetails.instagram}
      onChange={handleInputChange}
      fullWidth
    />
    {/* <TextField
      className="outlined-basic"
      id="engineer-country"
      label="Youtube"
      variant="outlined"
      name="youtube"
      value={editableProfileDetails.youtube}
      onChange={handleInputChange}
      fullWidth
    /> */}
    <TextField
      className="outlined-basic"
      id="engineer-password"
      label="Password"
      variant="outlined"
      name="password"
      value={editableProfileDetails.password}
      onChange={handleInputChange}
      fullWidth
      required
    />
    <button
      onClick={(e) => {
        e.preventDefault();
        updateEngineerDetails();
        if (process.env.NODE_ENV === "development") {
          console.log("Edited details: ", editableProfileDetails);
        }
      }}
      className="update-button"
    >
      Submit
    </button>
  </Box>
);

const PackagesEdit = ({
  index,
  editablePackageDetails,
  setEditablePackageDetails,
  numbersDropdown,
  updateEngineerServices,
  handleInputChangeServices,
  setModalOpen,
  featuresList,
  selectedfeaturesList,
  setSelectedFeaturesList,
  updateFeatures,
  deleteFeatures,
  engineerStems,
}) => (
  <Box
    component="form"
    sx={{
      "& > :not(style)": { marginY: 1, width: "100%" },
    }}
    noValidate
    autoComplete="off"
  >
    <button
      className="book_now_btn delete-service-btn"
      onClick={() => setModalOpen("Delete")}
    >
      Delete Service
    </button>
    <TextField
      className="outlined-basic"
      id="engineer-name"
      label="Service Name"
      variant="outlined"
      name="name"
      value={editablePackageDetails.name}
      InputLabelProps={{ shrink: true }}
      onChange={handleInputChangeServices}
      fullWidth
    />
    {editablePackageDetails.name !== "Multitrack Mixing" ? (
      <TextField
        className="outlined-basic"
        id="engineer-name"
        label="Cost"
        variant="outlined"
        name="cost"
        value={editablePackageDetails.cost}
        InputLabelProps={{ shrink: true }}
        onChange={handleInputChangeServices}
        fullWidth
      />
    ) : (
      <p>Cost: Update stems cost to update the service cost</p>
    )}
    <p>Delivery Days</p>

    <Select
      defaultValue={editablePackageDetails.delivery_days}
      onChange={(e) => {
        setEditablePackageDetails({
          ...editablePackageDetails,
          delivery_days: e.target.value,
        });
      }}
      MenuProps={{ disableScrollLock: true }}
    >
      {numbersDropdown.map((number) => (
        <MenuItem value={number.value}>{number.label}</MenuItem>
      ))}
    </Select>
    <p>Revisions</p>
    <Select
      defaultValue={editablePackageDetails.revisions}
      onChange={(e) => {
        setEditablePackageDetails({
          ...editablePackageDetails,
          revisions: e.target.value,
        });
      }}
      MenuProps={{ disableScrollLock: true }}
    >
      {numbersDropdown.map((number) => (
        <MenuItem value={number.value}>{number.label}</MenuItem>
      ))}
    </Select>
    <FormControl>
      <InputLabel id="demo-multiple-checkbox-label">Features</InputLabel>
      <SelectMUI
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedfeaturesList}
        label="Features"
        onChange={(e) => {
          e.preventDefault();
          console.log("Selected features:", e.target.value);
          setSelectedFeaturesList(e.target.value);
        }}
        input={<OutlinedInput label="Features" />}
        multiple
      >
        {featuresList.length > 0 ? (
          featuresList.map((feat) => (
            <MenuItem key={feat.id} value={feat.id}>
              {feat.name}
            </MenuItem>
          ))
        ) : (
          <p>No Features at the moment</p>
        )}
        {/* {featuresList.length > 0 ? (
          featuresList.map((feat) => (
            <MenuItem key={feat.id} value={feat.id}>
              <button
                id="add-btn"
                onClick={() =>
                  updateFeatures(editablePackageDetails.id, feat.id)
                }
              >
                Add
              </button>
              {feat.name}
            </MenuItem>
          ))
        ) : (
          <p>No Features at the moment</p>
        )} */}
      </SelectMUI>
    </FormControl>
    <div className="engineer-selected-features-container">
      {editablePackageDetails?.features?.length > 0 ? (
        editablePackageDetails?.features?.map((feat) => (
          <div id="feature-name-container" key={feat?.feature?.id}>
            <p id="selected-feature-name">
              {feat.feature?.name}{" "}
              <DeleteOutline
                id="feature-delete-icon"
                onClick={() => {
                  // remove feature from selected features list
                  setSelectedFeaturesList(
                    selectedfeaturesList.filter(
                      (feature) => feature !== feat.feature.id
                    )
                  );
                  // remove feature from editable package details
                  setEditablePackageDetails({
                    ...editablePackageDetails,
                    features: editablePackageDetails.features.filter(
                      (feature) => feature.feature.id !== feat.feature.id
                    ),
                  });

                  // delete feature from database
                  deleteFeatures(editablePackageDetails.id, feat.feature.id);
                }}
              />
            </p>
          </div>
        ))
      ) : (
        <p>No features added</p>
      )}
    </div>
    {/* <button
      onClick={(e) => {
        e.preventDefault();
        if (selectedfeaturesList.length === 0) {
          setSelectedFeaturesList(
            editablePackageDetails.features.map((feat) => {
              return feat.feature.id;
            })
          );
        }
      }}
      className="book_now_btn"
    >
      Confirm features
    </button> */}
    <button
      onClick={(e) => {
        e.preventDefault();
        if (process.env.NODE_ENV === "development") {
          console.log(
            "Services updates Data: ",
            editablePackageDetails,
            selectedfeaturesList
          );
        }

        // if selectedfeaturesList is not empty, map through it and update the features
        if (selectedfeaturesList.length > 0) {
          selectedfeaturesList.map((feat) => {
            return updateFeatures(editablePackageDetails.id, feat);
          });
        }
        updateEngineerServices(editablePackageDetails.id);
        // const userAT = authentication().currentUser.getIdToken();
        if (process.env.NODE_ENV === "development") {
          console.log("Auth Token: ", window.localStorage.getItem("AuthToken"));
        }
      }}
      className="book_now_btn"
    >
      Save Details
    </button>
  </Box>
);

const PackagesAdd = ({
  serviceNames,
  index,
  editablePackageDetails,
  setEditablePackageDetails,
  numbersDropdown,
  AddEngineerServices,
  handleInputChangeServices,
  featuresList,
  selectedfeaturesList,
  setSelectedFeaturesList,
  servicesToBeAdded,
  setServicesToBeAdded,
  setSelectedService,
  selectedService,
  engineerStems,
}) => (
  <div>
    {servicesToBeAdded.map((service, index) => {
      // match service name
      const match = serviceNames.find((name) => name === service.name);
      if (!match) {
        return (
          <Box
            component="form"
            sx={{
              "& > :not(style)": { marginY: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <h1>{service.name}</h1>
            <TextField
              className="outlined-basic"
              id="engineer-name"
              label="Cost"
              variant="outlined"
              name="cost"
              value={
                service.name === "Multitrack Mixing"
                  ? engineerStems
                  : service.cost
              }
              disabled={service.name === "Multitrack Mixing"}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                setServicesToBeAdded(
                  servicesToBeAdded.map((service, i) => {
                    if (i === index) {
                      return {
                        ...service,
                        cost: e.target.value,
                      };
                    } else {
                      return service;
                    }
                  })
                );
              }}
              fullWidth
            />
            <p>Delivery Days</p>
            <Select
              onChange={(e) => {
                setServicesToBeAdded(
                  servicesToBeAdded.map((service, i) => {
                    if (i === index) {
                      return { ...service, delivery_days: e.target.value };
                    } else {
                      return service;
                    }
                  })
                );
              }}
              MenuProps={{ disableScrollLock: true }}
            >
              {numbersDropdown.map((number) => (
                <MenuItem value={number.value}>{number.label}</MenuItem>
              ))}
            </Select>
            <p>Revisions</p>
            <Select
              defaultValue={service.revisions}
              onChange={(e) => {
                setServicesToBeAdded(
                  servicesToBeAdded.map((service, i) => {
                    if (i === index) {
                      return { ...service, revisions: e.target.value };
                    } else {
                      return service;
                    }
                  })
                );
              }}
              MenuProps={{ disableScrollLock: true }}
            >
              {numbersDropdown.map((number) => (
                <MenuItem value={number.value}>{number.label}</MenuItem>
              ))}
            </Select>

            <div id="service-default-features-container">
              <h4 id="service-modal-subtitle">Default Features</h4>
              {service.desc.map((feature, index) => (
                <p id="service-default-features">•{feature}</p>
              ))}
            </div>

            <h4 id="service-modal-subtitle">Select the Features you want</h4>
            {/* <Select
                defaultValue={editablePackageDetails.revisions}
                options={featuresList}
                isMulti
                onChange={(e) => console.log("Selected features:", e)}
              /> */}
            <FormControl>
              <InputLabel id="demo-multiple-checkbox-label">
                Features
              </InputLabel>
              <SelectMUI
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedfeaturesList}
                label="Features"
                onChange={(e) => {
                  e.preventDefault();
                  console.log("Selected features:", e.target.value);
                  setSelectedFeaturesList(e.target.value);
                }}
                input={<OutlinedInput label="Features" />}
                multiple
              >
                {featuresList.length > 0 ? (
                  featuresList.map((feat) => (
                    <MenuItem key={feat.id} value={feat.id}>
                      {feat.name}
                    </MenuItem>
                  ))
                ) : (
                  <p>No Features at the moment</p>
                )}
              </SelectMUI>
            </FormControl>
            {/* <button
                onClick={(e) => {
                  e.preventDefault();
                  console.log("selected features:", selectedfeaturesList);
                }}
              >
                show
              </button> */}
            {/* <div className="selected-features-container">
                {selectedfeaturesList.length > 0 ? (
                  selectedfeaturesList.map((feat) => (
                    <div className="selected-feature">
                      <p>{feat}</p>
                    </div>
                  ))
                ) : (
                  <p>No features selected</p>
                )}
              </div> */}
            {/* FOR UPDATING STEMS */}
            <button
              onClick={(e) => {
                e.preventDefault();
                if (process.env.NODE_ENV === "development") {
                  console.log(
                    "Service update Data: ",
                    service,
                    selectedfeaturesList
                  );
                }
                AddEngineerServices(service);
                // const userAT = authentication().currentUser.getIdToken();
              }}
              className="update-button"
            >
              Add Service
            </button>
            <hr />
          </Box>
        );
      }
    })}
  </div>
);

const StemsAdd = ({
  setStemRanges,
  stemRanges,
  setAddServiceStems,
  addServiceStems,
  UpdateServiceStems,
  stems,
  setStems,
  engineerStems,
}) => (
  <Box
    component="form"
    sx={{
      "& > :not(style)": { marginY: 1, width: "100%" },
    }}
    noValidate
    autoComplete="off"
  >
    {/* FOR UPDATING STEMS */}
    <div className="stems-update-container">
      <p>Stem Ranges</p>
      <FormGroup aria-label="position" column>
        <FormControlLabel
          value="1-5"
          control={
            <div>
              {stems.map((stem, i) => (
                <div className="engineer-package-stem-range-container">
                  <p id="stem-range">
                    {stem.start_range}-{stem.end_range}
                  </p>
                  <TextField
                    className="outlined-basic"
                    id="engineer-stem-edit-input"
                    label="Cost"
                    variant="outlined"
                    placeholder={parseInt(engineerStems[i]?.cost) || 0}
                    value={stem.cost}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      // Add cost to particular stem range
                      let updateStemCost = stems.map((stem, index) =>
                        index === i ? { ...stem, cost: e.target.value } : stem
                      );
                      setStems(updateStemCost);
                    }}
                  />
                  <Checkbox
                    color="primary"
                    disabled={!stem.cost ? true : false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setAddServiceStems([
                          ...addServiceStems,
                          {
                            start_range: stem.start_range,
                            end_range: stem.end_range,
                            cost: stem.cost,
                          },
                        ]);
                      } else {
                        setAddServiceStems(
                          addServiceStems.filter(
                            (item) =>
                              item.start_range !== stem.start_range &&
                              item.end_range !== stem.end_range
                          )
                        );
                      }
                    }}
                  />
                </div>
              ))}
              {/* <div className="engineer-package-stem-range-container">
                <p className="engineer-package-stem-range">01-05</p>
                <TextField
                  className="outlined-basic"
                  id="engineer-name-edit-input"
                  label="Cost"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) =>
                    setStemRanges({
                      start_range: 1,
                      end_range: 5,
                      cost: e.target.value,
                    })
                  }
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setAddServiceStems([...addServiceStems, stemRanges]);
                    toast.success("Stems selected");
                  }}
                  className="add-stem-btn"
                >
                  Add stem
                </button>
              </div> */}
            </div>
          }
          labelPlacement="end"
        />

        {/* <FormControlLabel
          value="1-5"
          control={
            <div className="engineer-package-stem-range-container">
              <p className="engineer-package-stem-range">10-15</p>
              <TextField
                className="outlined-basic"
                id="engineer-name-edit-input"
                label="Cost"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  setStemRanges({
                    start_range: 10,
                    end_range: 15,
                    cost: e.target.value,
                  })
                }
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setAddServiceStems([...addServiceStems, stemRanges]);
                  toast.success("Stems selected");
                }}
                className="add-stem-btn"
              >
                Add stem
              </button>
            </div>
          }
          labelPlacement="end"
        />
        <FormControlLabel
          value="1-5"
          control={
            <div className="engineer-package-stem-range-container">
              <p className="engineer-package-stem-range">15-25</p>
              <TextField
                className="outlined-basic"
                id="engineer-name-edit-input"
                label="Cost"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  setStemRanges({
                    start_range: 15,
                    end_range: 25,
                    cost: e.target.value,
                  })
                }
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setAddServiceStems([...addServiceStems, stemRanges]);
                  toast.success("Stems selected");
                }}
                className="add-stem-btn"
              >
                Add stem
              </button>
            </div>
          }
          labelPlacement="end"
        />
        <FormControlLabel
          value="1-5"
          control={
            <div className="engineer-package-stem-range-container">
              <p className="engineer-package-stem-range">25-40</p>
              <TextField
                className="outlined-basic"
                id="engineer-name-edit-input"
                label="Cost"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  setStemRanges({
                    start_range: 25,
                    end_range: 40,
                    cost: e.target.value,
                  })
                }
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setAddServiceStems([...addServiceStems, stemRanges]);
                  toast.success("Stems selected");
                }}
                className="add-stem-btn"
              >
                Add stem
              </button>
            </div>
          }
          labelPlacement="end"
        />
        <FormControlLabel
          value="1-5"
          control={
            <div className="engineer-package-stem-range-container">
              <p className="engineer-package-stem-range">40-60</p>
              <TextField
                className="outlined-basic"
                id="engineer-name-edit-input"
                label="Cost"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  setStemRanges({
                    start_range: 40,
                    end_range: 60,
                    cost: e.target.value,
                  })
                }
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setAddServiceStems([...addServiceStems, stemRanges]);
                  toast.success("Stems selected");
                }}
                className="add-stem-btn"
              >
                Add stem
              </button>
            </div>
          }
          labelPlacement="end"
        />*/}
      </FormGroup>
    </div>
    <button
      onClick={(e) => {
        e.preventDefault();
        if (process.env.NODE_ENV === "development") {
          console.log("Services Stems Data: ", stemRanges, addServiceStems);
        }
        UpdateServiceStems();
      }}
      className="update-button"
    >
      Save Stem Details
    </button>
  </Box>
);

const DeletePackage = ({ serviceDetails, deleteServices, handleClose }) => (
  <Box
    component="form"
    sx={{
      "& > :not(style)": { marginY: 1, width: "100%" },
    }}
    noValidate
    autoComplete="off"
  >
    <div className="confirm-modal-container">
      <p id="delete-service-name">{serviceDetails.name}</p>
      <div className="confirm-btns-container">
        <button
          id="yes-btn"
          onClick={(e) => {
            e.preventDefault();
            deleteServices(serviceDetails.id);
          }}
        >
          Yes
        </button>
        <button id="no-btn" onClick={handleClose}>
          No
        </button>
      </div>
    </div>
  </Box>
);

const UpdateGenre = ({
  genreList,
  selectedGenreList,
  setSelectedGenreList,
  UpdateEngineerGenre,
}) => (
  <Box
    component="form"
    sx={{
      "& > :not(style)": { marginY: 1, width: "100%" },
    }}
    noValidate
    autoComplete="off"
  >
    <p>Select upto 4 Genres</p>

    <FormControl>
      <InputLabel id="demo-multiple-checkbox-label">Genres</InputLabel>
      <SelectMUI
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedGenreList}
        label="Genres"
        onChange={(e) => {
          console.log("Selected Genre:", e.target.value);
          setSelectedGenreList(e.target.value);
          // allow only 4 genres to be selected
          if (e.target.value.length > 4) {
            toast.error("You can only select upto 4 genres");
            setSelectedGenreList(e.target.value.slice(0, 4));
          }
        }}
        input={<OutlinedInput label="Genres" />}
        multiple
      >
        {genreList.length > 0 ? (
          genreList.map((genre) => (
            <MenuItem key={genre.id} value={genre.id}>
              {genre?.genre_name}
            </MenuItem>
          ))
        ) : (
          <p>No Genres at the moment</p>
        )}
      </SelectMUI>
    </FormControl>
    <button
      onClick={(e) => {
        e.preventDefault();
        if (process.env.NODE_ENV === "development") {
          console.log("Genres updates Data: ", selectedGenreList);
        }
        selectedGenreList.length > 0
          ? UpdateEngineerGenre()
          : toast.error("Genre list cannot be empty");
        // if (process.env.NODE_ENV === "development") {
        //   console.log("Auth Token: ", window.localStorage.getItem("AuthToken"));
        // }
      }}
      className="update-button"
    >
      Save Details
    </button>
  </Box>
);

function EngineerProfile() {
  const [navItemName, setNavItemName] = useState("Services");
  const [engineers, setEngineers] = useState();
  const [engineerDetails, setEngineerDetails] = useState();
  const [engineerReviews, setengineerReviews] = useState([]);
  const [engineerStems, setEngineerStems] = useState([]);
  const [engineerGenres, setEngineerGenres] = useState([]);
  const [selectedStemOption, setSelectedStemOption] = useState();
  const [selectedStem, setSelectedStem] = useState({
    stemId: "",
    stemStart: 1,
    stemEnd: 5,
    stemCost: engineerStems[0]?.cost || 0,
  });
  const [engineerServicesData, setEngineerServicesData] = useState([]);
  const [serviceNames, setServiceNames] = useState([]);
  const [playBtnClicked, setPlayBtnClicked] = useState({
    before: false,
    after: false,
  });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const waveformBefore = useRef(null);
  const waveformAfter = useRef(null);
  const { id } = useParams();
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [uploading, setUploading] = useState(false);
  const [editableProfileDetails, setEditableProfileDetails] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    address: "",
    city: "",
    pincode: 0,
    country: "",
    bio: "",
    spotify: "",
    instagram: "",
    // youtube: "",
  });
  const [serviceModalDetails, setServiceModalDetails] = useState({
    name: "",
    cost: 0,
    revisions: 0,
    delivery_days: 0,
    desc: "",
    id: "",
    features: [],
    index: 0,
  });
  const [stemRanges, setStemRanges] = useState({
    start_range: 0,
    end_range: 0,
    cost: 0,
  });
  const [stems, setStems] = useState([
    {
      start_range: 1,
      end_range: 5,
      cost: null,
    },
    {
      start_range: 5,
      end_range: 10,
      cost: null,
    },
    {
      start_range: 10,
      end_range: 15,
      cost: null,
    },
    {
      start_range: 15,
      end_range: 25,
      cost: null,
    },
    {
      start_range: 25,
      end_range: 40,
      cost: null,
    },
    {
      start_range: 40,
      end_range: 60,
      cost: null,
    },
  ]);
  const [addServiceStems, setAddServiceStems] = useState([]);
  const { setActiveNavItem } = useContext(NavContext);
  const profileImg = useRef(null);
  const [profileImgFile, setProfileImgFile] = useState({
    name: "",
    file: null,
  });
  const [featuresList, setFeaturesList] = useState([]);
  const [selectedfeaturesList, setSelectedFeaturesList] = useState([]);
  const [allGenres, setAllGenres] = useState([]);
  const [selectedGenreList, setSelectedGenreList] = useState([]);
  const [servicesToBeAdded, setServicesToBeAdded] = useState([
    {
      name: "Multitrack Mixing",
      cost: null,
      revisions: 0,
      delivery_days: 0,
      desc: [
        "Main mix and clean alt included by default",
        "Upload up to 256 mono or stereo tracks/stems per song",
      ],
      id: "",
      index: 0,
      stems: true,
    },
    {
      name: "Vocals Mix",
      cost: null,
      revisions: 0,
      delivery_days: 0,
      desc: [
        "Main mix and clean alt included by default",
        "Upload vocal tracks and upto 2 instrumental track for mixing",
      ],
      id: "",
      index: 1,
      stems: false,
    },
    {
      name: "Podcast Mix & Master",
      cost: null,
      revisions: 0,
      delivery_days: 0,
      desc: [
        "Main mix and clean alt included by default",
        "Upload vocal tracks and upto 2 instrumental track for mixing",
      ],
      id: "",
      index: 2,
      stems: false,
    },
    {
      name: "Podcast Edit, Mix & Master",
      cost: null,
      revisions: 0,
      delivery_days: 0,
      desc: [
        "Main mix and clean alt included by default",
        "Upload vocal tracks and upto 2 instrumental track for mixing",
      ],
      id: "",
      index: 2,
      stems: false,
    },
    {
      name: "Mastering",
      cost: null,
      revisions: 0,
      delivery_days: 0,
      desc: [
        "Upload 24-bit / 32-bit stereo wav",
        "Mastered record returned in wav format",
      ],
      id: "",
      index: 4,
      stems: false,
    },
  ]);
  const [multitrackMixingServiceId, setMultitrackMixingServiceId] =
    useState("");
  const [selectedService, setSelectedService] = useState();

  const url = require("../../Sounds/ksi-holiday-official-music-video.mp3");

  const fileTypes = ["MP3"];
  const [BeforeFile, setBeforeFile] = useState(null);
  const [AfterFile, setAfterFile] = useState(null);
  const handleBeforeFileChange = (file) => {
    setBeforeFile(file);
    if (process.env.NODE_ENV === "development") {
      console.log("Selected file", file);
      console.log(file.current.duration);
    }
  };
  const handleAfterFileChange = (file) => {
    setAfterFile(file);
    if (process.env.NODE_ENV === "development") {
      console.log("Selected file", file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableProfileDetails({
      ...editableProfileDetails,
      [name]: value,
    });
  };

  const handleInputChangeServices = (e) => {
    const { name, value } = e.target;
    setServiceModalDetails({
      ...serviceModalDetails,
      [name]: value,
    });
  };

  const numbersDropdown = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
  ];

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
    justifyContent: "center",
  };

  useEffect(() => {
    console.log("Profile Image: ", profileImgFile.file);
  }, [profileImgFile]);

  useEffect(() => {});

  useEffect(() => {
    document.title = "Jamrio | Engineers";
    window.scrollTo(0, 0);
    setActiveNavItem("P");
    fetchEngineerDetails();
    fetchEngineerReviews();
    fetchEngineerStems();
    fetchEngineerServices();
    fetchEngineerGenres();
    fetchFeatures();
    fetchAllGenres();
  }, []);

  useEffect(() => {
    setEditableProfileDetails({
      firstname: engineerDetails?.firstname,
      lastname: engineerDetails?.lastname,
      username: engineerDetails?.username,
      email: engineerDetails?.email,
      password: engineerDetails?.password,
      address: engineerDetails?.address,
      city: engineerDetails?.city,
      pincode: engineerDetails?.pincode,
      country: engineerDetails?.country,
      bio: engineerDetails?.bio,
    });
  }, [engineerDetails]);

  useEffect(() => {
    fetchEngineerDetails();
  }, [id]);

  useEffect(() => {
    console.log("stems: ", selectedStem);
  }, [selectedStem]);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);
  }, []);

  // make an array of services names
  useEffect(() => {
    let services = [];
    engineerServicesData?.map((service) => {
      return services.push(service.service_name);
    });
    setServiceNames(services);
  }, [engineerServicesData]);

  //--------------Create music stem waveforms----------
  useEffect(() => {
    if (engineerDetails) {
      if (engineerDetails?.before_edit) {
        if (!waveformBefore.current) {
          waveformBefore.current = WaveSurfer.create({
            container: "#waveformBefore",
            waveColor: "#999",
            progressColor: "#FF782C",
            cursorColor: "#000",
            barWidth: 2,
            barRadius: 2,
            cursorWidth: 1,
            height: 50,
            barGap: 3,
            plugins: [WaveSurferRegionsPlugin.create({ maxLength: 40 })],
          });
          waveformBefore?.current?.load(engineerDetails?.before_edit);
        }
      } else {
        if (!waveformBefore.current) {
          waveformBefore.current = WaveSurfer.create({
            container: "#waveformBefore",
            waveColor: "#999",
            progressColor: "#FF782C",
            cursorColor: "#000",
            barWidth: 2,
            barRadius: 2,
            cursorWidth: 1,
            height: 50,
            barGap: 3,
            plugins: [WaveSurferRegionsPlugin.create({ maxLength: 40 })],
          });
          waveformBefore?.current?.load("/");
        }
      }
      waveformBefore.current.on("ready", () => {});
    }
    // waveform.current.on("region-created", (e) => {});
  }, [engineerDetails]);

  useEffect(() => {
    if (engineerDetails) {
      if (engineerDetails?.after_edit) {
        if (!waveformAfter.current) {
          waveformAfter.current = WaveSurfer.create({
            container: "#waveformAfter",
            waveColor: "#999",
            progressColor: "#FF782C",
            cursorColor: "#000",
            barWidth: 2,
            barRadius: 2,
            cursorWidth: 1,
            height: 50,
            barGap: 3,
            plugins: [WaveSurferRegionsPlugin.create({ maxLength: 40 })],
          });
          waveformAfter?.current?.load(engineerDetails?.after_edit);
        }
      } else {
        if (!waveformAfter.current) {
          waveformAfter.current = WaveSurfer.create({
            container: "#waveformAfter",
            waveColor: "#999",
            progressColor: "#FF782C",
            cursorColor: "#000",
            barWidth: 2,
            barRadius: 2,
            cursorWidth: 1,
            height: 50,
            barGap: 3,
            plugins: [WaveSurferRegionsPlugin.create({ maxLength: 40 })],
          });
          waveformAfter?.current?.load("/");
        }
      }
    }
    // waveform.current.on("ready", () => {});
    // waveform.current.on("region-created", (e) => {});
  }, [engineerDetails]);

  useEffect(() => {
    console.log("stems 1 item", parseInt(engineerStems[0]?.cost));
  }, [engineerStems]);

  useEffect(() => {
    // if Multitrack Mixing service exists then get its id
    if (engineerServicesData) {
      engineerServicesData.map((service) => {
        if (service.service_name === "Multitrack Mixing") {
          return setMultitrackMixingServiceId(service.id);
        }
      });
    }
  }, [engineerServicesData]);

  useEffect(() => {
    console.log("multi track mixing service id: ", multitrackMixingServiceId);
  }, [multitrackMixingServiceId]);

  const fetchEngineerDetails = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/mixing-mastering/${window.localStorage.getItem(
        "engineerId"
      )}/studioengineer`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/engineer/details/?type=L`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("engineerData ----->", data);
          }
          setEngineerDetails(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateEngineerDetails = async () => {
    if (process.env.NODE_ENV === "development") {
      console.log("update profile: ", editableProfileDetails);
    }
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/update`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/engineer/details/?type=L`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
        body: JSON.stringify({
          engineer_id: window.localStorage.getItem("engineerId"),
          firstname: editableProfileDetails.firstname
            ? editableProfileDetails.firstname
            : engineerDetails.firstname,
          lastname: editableProfileDetails.lastname
            ? editableProfileDetails.lastname
            : engineerDetails.lastname,
          username: editableProfileDetails.username
            ? editableProfileDetails.username
            : engineerDetails.username,
          password: editableProfileDetails.password
            ? editableProfileDetails.password
            : engineerDetails.password,
          email: editableProfileDetails.email
            ? editableProfileDetails.email
            : engineerDetails.email,
          city: editableProfileDetails.city
            ? editableProfileDetails.city
            : engineerDetails.city,
          address: editableProfileDetails.address
            ? editableProfileDetails.address
            : engineerDetails.address,
          country: editableProfileDetails.country
            ? editableProfileDetails.country
            : engineerDetails.country,
          pincode: editableProfileDetails.pincode
            ? editableProfileDetails.pincode
            : engineerDetails.pincode,
          phone: editableProfileDetails.phone
            ? editableProfileDetails.phone
            : engineerDetails.phone,
          bio: editableProfileDetails.bio
            ? editableProfileDetails.bio
            : engineerDetails.bio,
          genres: [1, 2, 33],
          spotify: editableProfileDetails.spotify,
          instagram: editableProfileDetails.instagram,
          youtube: editableProfileDetails.youtube,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          handleClose();
          if (process.env.NODE_ENV === "development") {
            console.log("engineerData ----->", data);
          }
          setEngineerDetails(data);
          window.location.reload();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
          notifyErr(data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchEngineerServices = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/mixing-mastering/${window.localStorage.getItem("engineerId")}/services`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/engineer/details/?type=L`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Engineer services Data ----->", data);
          }
          setEngineerServicesData(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchFeatures = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/service/features`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/engineer/details/?type=L`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Service features ----->", data);
          }
          setFeaturesList(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateEngineerServices = async (serviceId, stemcost) => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/service/${serviceId}`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/engineer/details/?type=L`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
        body: JSON.stringify({
          engineer_id: window.localStorage.getItem("engineerId"),
          service_name: serviceModalDetails.name,
          description: serviceModalDetails.desc,
          cost:
            serviceModalDetails.name === "Multitrack Mixing"
              ? engineerStems[0]?.cost || stemcost || 0
              : serviceModalDetails.cost,
          delivery_days: serviceModalDetails.delivery_days,
          revisions: serviceModalDetails.revisions,
          features:
            selectedfeaturesList?.length > 0 ? selectedfeaturesList : {},
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Updated Engineer services Data ----->", data);
          }
          window.location.reload();
          handleClose();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log(data);
          }
          if (data.code === "field/invalid-features") {
            toast.error("Please confirm features first");
          } else {
            toast.error(data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateEngineerFeatures = async (serviceId, featureId) => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/service/${serviceId}/features/${featureId}`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/engineer/details/?type=L`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        if (response.status === 400) {
          // toast.info("Feature already added");
          handleClose();
        }
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Updated Engineer Features Data ----->", data);
          }
          fetchEngineerServices();
          toast.success("Feature Updated Successfully");
          handleClose();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log(data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteEngineerFeatures = async (serviceId, featureId) => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/service/${serviceId}/features/${featureId}`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/engineer/details/?type=L`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        if (response.status === 400) {
          // toast.info("Feature already added");
          handleClose();
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Deleted Engineer Features Data ----->", data);
          }
          fetchEngineerServices();
          toast.success("Feature Deleted Successfully");
          // handleClose();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log(data.message);
          }
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const AddEngineerServices = async (service) => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/service`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/engineer/details/?type=L`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
        body: JSON.stringify({
          engineer_id: window.localStorage.getItem("engineerId"),
          service_name: service.name,
          description: service.desc,
          cost:
            service.name === "Multitrack Mixing"
              ? engineerStems[0]?.cost || 0
              : service.cost,
          delivery_days: service.delivery_days,
          revisions: service.revisions,
          features: selectedfeaturesList,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Updated Engineer services Data ----->", data);
          }
          // window.location.reload();
          // handleClose();
          toast.success("Service Added Successfully");
          fetchEngineerServices();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log(data.message);
          }
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const DeleteEngineerServices = async (serviceId) => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/service/${serviceId}`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/engineer/details/?type=L`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          if (process.env.NODE_ENV === "development") {
            console.log("Delete Engineer services ----->", response);
          }
          handleClose();
          window.location.reload();
        }
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Deleted Engineer services ----->", data);
          }
          handleClose();
          fetchEngineerServices();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log(data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchEngineerReviews = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/mixing-mastering/${window.localStorage.getItem("engineerId")}/reviews`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/engineer/details/?type=L`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Engineer reviews Data ----->", data);
          }
          setengineerReviews(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchEngineerStems = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/mixing-mastering/${window.localStorage.getItem("engineerId")}/stems`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/engineer/details/?type=L`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Engineer stems Data ----->", data);
          }
          setEngineerStems(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchAllGenres = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/genres`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/engineer/details/?type=L`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("All Genres ----->", data);
          }
          setAllGenres(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchEngineerGenres = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/mixing-mastering/${window.localStorage.getItem("engineerId")}/genres`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/engineer/details/?type=L`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("engineer Genres ----->", data);
          }
          setEngineerGenres(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdateEngineerGenre = async (serviceId) => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/updateGenres`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/engineer/details/?type=L`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
        body: JSON.stringify({
          sid: window.localStorage.getItem("engineerId"),
          genres: selectedGenreList,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Updated Engineer services Data ----->", data);
          }
          fetchEngineerGenres();
          handleClose();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log(data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdateServiceStems = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/stems`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/engineer/details/?type=L`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
        body: JSON.stringify({
          sid: window.localStorage.getItem("engineerId"),
          stems: addServiceStems,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Updated Engineer stems----->", data);
          }
          fetchEngineerStems();
          toast.success("Stems Updated");
          handleClose();
          setAddServiceStems([]);
          fetchEngineerServices();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log(data.message);
          }
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const uploadProfileImg = async () => {
    const formData = new FormData();
    formData.append("photo", profileImgFile.file);

    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/upload/${window.localStorage.getItem(
        "engineerId"
      )}/studioengineer/image`,
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
        body: formData,
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.isError) {
          if (process.env.NODE_ENV === "development") {
            console.log("File uploading ----->", data);
            console.log(data.data);
            console.log(data.message);
          }
          fetchEngineerDetails();
          setProfileImgFile({
            name: null,
            file: null,
          });
          notifySuccess(data.message);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.isError);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        notifyErr("Please upload a png image");
      });
  };

  const uploadBeforeTrack = async () => {
    const formData = new FormData();
    formData.append("file", BeforeFile);

    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/mixing-mastering/${window.localStorage.getItem(
        "engineerId"
      )}/tracks/before`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
        body: formData,
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.isError) {
          if (process.env.NODE_ENV === "development") {
            console.log("File uploading Before----->", data);
            console.log(data.message);
          }
          fetchEngineerDetails();
          notifySuccess(data.message);
          setUploading(false);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.isError);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        notifyErr("Please upload file in MP3 format");
      });
  };

  const uploadAfterTrack = async () => {
    const formData = new FormData();
    formData.append("file", AfterFile);

    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/mixing-mastering/${window.localStorage.getItem(
        "engineerId"
      )}/tracks/after`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
        body: formData,
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.isError) {
          if (process.env.NODE_ENV === "development") {
            console.log("File uploading After----->", data);
            console.log(data.message);
          }
          fetchEngineerDetails();
          notifySuccess(data.message);
          setUploading(false);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.isError);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        notifyErr("Please upload file in MP3 format");
      });
  };

  const playAudioBefore = () => {
    // Check if the audio is already playing
    if (waveformBefore.current.isPlaying()) {
      waveformBefore.current.pause();
    } else {
      waveformBefore.current.play();
    }
  };

  const playAudioAfter = () => {
    // Check if the audio is already playing
    if (waveformAfter.current.isPlaying()) {
      waveformAfter.current.pause();
    } else {
      waveformAfter.current.play();
    }
  };

  const EngineerServiceMobile = () => (
    <Tabs>
      <TabList>
        <Tab>Mastering</Tab>
        <Tab>2 Track Mixing</Tab>
        <Tab>Stem Mixing</Tab>
      </TabList>

      <TabPanel>
        <EngineerServices dropdown={false} cost={2200} packgname="Mastering" />
      </TabPanel>
      <TabPanel>
        <EngineerServices
          dropdown={false}
          cost={4200}
          packgname="2 Track Mixing"
        />
      </TabPanel>
      <TabPanel>
        <EngineerServices dropdown={true} cost={2500} packgname="STEM Mixing" />
      </TabPanel>
    </Tabs>
  );

  useEffect(() => {
    console.log("engineer service modal details", serviceModalDetails);
  }, [serviceModalDetails]);

  // NEW SERVICES CONTAINER -------- Currently using
  const EngineerServices = ({
    name,
    cost,
    features,
    description,
    delivery_days,
    revisions,
    serviceId,
    index,
  }) => {
    return (
      <div className="services_card">
        <div className="services_card_body">
          <div className="service-edit-icon-container">
            <img
              src="https://img.icons8.com/ios-glyphs/100/000000/pencil--v1.png"
              alt="edit"
              className="service-edit-icon"
              onClick={() => {
                setServiceModalDetails({
                  id: serviceId,
                  name: name,
                  desc: description,
                  cost: cost,
                  delivery_days: delivery_days,
                  revisions: revisions,
                  index: index,
                  features: features,
                });
                handleOpen();
                setModalOpen("Service");
              }}
            />
          </div>
          <h3 className="services_card_title">{name}</h3>
          <p className="services_cost">
            ₹{" "}
            {name === "Multitrack Mixing"
              ? selectedStem.stemStart === 1
                ? cost
                : cost
              : name === "Multitrack Mixing"
              ? parseInt(selectedStem.stemCost) > 0
                ? cost + parseInt(selectedStem.stemCost)
                : cost
              : cost}
          </p>
          <div className="services_features_container">
            <p className="engineer-service-body-text">
              {" "}
              ✓ <span id="delivery-number">{delivery_days}</span> Delivery Days
            </p>
            <p className="engineer-service-body-text">
              {" "}
              ✓ <span id="delivery-number">{revisions}</span> Revisions
            </p>
            {servicesToBeAdded.map((service) => {
              if (service.name === name) {
                return (
                  <div>
                    {service.desc.map((feature, index) => (
                      <p class="engineer-service-body-text"> ✓ {feature}</p>
                    ))}
                  </div>
                );
              }
            })}
            {features.length > 0 &&
              features.map((feature, index) => (
                <p key={index} className="engineer-service-body-text">
                  {" "}
                  ✓ {feature?.feature?.name}
                </p>
              ))}
            {name === "5.1 Mixing" || name === "Multitrack Mixing" ? (
              <>
                <p className="engineer-service-body-text">
                  {" "}
                  ✓ Stem Ranges for Stem Mixing - Select the number of stems
                </p>
                <FormControl
                  sx={{ m: 1, minWidth: 80 }}
                  className="select-stem-container"
                >
                  {/* <InputLabel id="demo-simple-select-label">Stems</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="stem-select"
                    label="Stems"
                    MenuProps={{ disableScrollLock: true }}
                    value={selectedStem.stemStart}
                    fullWidth
                  >
                    <MenuItem className="stem-container" value={0}>
                      <div
                        id="stem"
                        onClick={() =>
                          setSelectedStem({
                            stemId: 0,
                            stemCost: 0,
                            stemStart: 0,
                            stemEnd: 0,
                          })
                        }
                      >
                        <p id="stem-range">None</p>
                      </div>
                    </MenuItem>
                    {engineerStems?.length > 0 &&
                      engineerStems.map((stem) => (
                        <MenuItem
                          className="stem-container"
                          value={stem.start_range}
                          onClick={() =>
                            setSelectedStem({
                              stemId: stem.id,
                              stemCost: stem.cost,
                              stemStart: stem.start_range,
                              stemEnd: stem.end_range,
                            })
                          }
                        >
                          <div id="stem">
                            <p id="stem-range">
                              {stem.start_range + " - " + stem.end_range}
                            </p>
                            <p id="stem-cost">
                              +₹
                              {stem.cost}
                            </p>
                          </div>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </>
            ) : null}
          </div>
          <button className="book_now_btn">Book Now</button>
        </div>
      </div>
    );
  };

  const EngineerReviews = (props) => (
    <div className="engineer-review">
      <div className="engineer-review-head">
        <img
          src="https://img.icons8.com/material-sharp/50/000000/user-male-circle.png"
          alt="user profile"
          id="engineer-review-img"
        />
        <div className="engineer-review-details">
          <h1 id="engineer-review-username">{props.name}</h1>
          <p id="engineer-review-ratings">
            {"⭐".repeat(parseInt(props.score))}
          </p>
        </div>
      </div>

      <div className="engineer-review-body">
        <p>{props.text}</p>
      </div>
    </div>
  );

  const EngineerGenre = (genre) => (
    <div className="engineer-genre">
      <div className="engineer-genre-img-container">
        <img
          className="engineer-genre-img"
          src="https://img.icons8.com/external-microdots-premium-microdot-graphic/500/000000/external-dj-lifestyle-entertainment-vol3-microdots-premium-microdot-graphic.png"
          alt="EDM"
        />
      </div>
      <p>{genre?.genre ? genre?.genre?.genre_name : null}</p>
    </div>
  );

  const FileUpload = ({ name }) => (
    <div className="engineer-file-upload-container">
      <div className="engineer-file-upload-inner-container">
        <p className="engineer-file-upload-box-top-text">
          Upload {name} Track - MP3 Track only
        </p>
        <div>
          <FileUploader
            handleChange={
              name === "Before" ? handleBeforeFileChange : handleAfterFileChange
            }
            name="file"
            types={fileTypes}
            classes="engineer-file-upload-box"
            maxSize={1024}
            children={
              <>
                <p className="engineer-file-upload-box-text">
                  Drag and Drop a File here
                </p>
                <p className="engineer-file-upload-box-text">OR</p>
                <h3 id="modalBtn-file-select">Click here to select a file</h3>
                <p className="engineer-file-upload-box-bottom-text">
                  Max file size 20MB
                </p>
              </>
            }
          />
        </div>
      </div>
      {name === "Before" && BeforeFile ? (
        <>
          <div className="engineer-selected-file-container">
            <div className="engineer-selected-file">
              <h1 id="engineer-file-name">
                {BeforeFile.name.substring(0, 20) + "..."}
              </h1>
            </div>
          </div>
        </>
      ) : null}
      {name === "After" && AfterFile ? (
        <>
          <div className="engineer-selected-file-container">
            <div className="engineer-selected-file">
              <h1 id="engineer-file-name">
                {AfterFile.name.substring(0, 20) + "..."}
              </h1>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );

  return (
    <div className="EngineerProfile">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle} id="engineer-modal">
          <Typography id="engineer-modal-title" variant="h6" component="h2">
            {modalOpen === "Tracks"
              ? "Add Tracks"
              : modalOpen === "Profile"
              ? "Edit Profile"
              : modalOpen === "Service"
              ? "Edit Service"
              : modalOpen === "ServiceAdd"
              ? "Add Service"
              : modalOpen === "Delete"
              ? "Are you sure you want to delete"
              : modalOpen === "Genre"
              ? "Update Genre"
              : null}
            <Close onClick={handleClose} id="close-btn" />
          </Typography>
          {modalOpen === "Tracks" ? (
            <div className="engineer-modal-content">
              <FileUpload name="Before" />
              <button
                onClick={() => {
                  uploadBeforeTrack();
                  setUploading(true);
                }}
                className="update-button"
              >
                {uploading ? (
                  <div>
                    <ScaleLoader
                      color={"#fff"}
                      loading={uploading}
                      height={20}
                      width={5}
                      radius={50}
                    />
                  </div>
                ) : (
                  <p>Upload Before Track</p>
                )}
              </button>
              <FileUpload name="After" />
              <button
                onClick={() => {
                  uploadAfterTrack();
                  setUploading(true);
                }}
                className="update-button"
              >
                {uploading ? (
                  <div>
                    <ScaleLoader
                      color={"#fff"}
                      loading={uploading}
                      height={20}
                      width={5}
                      radius={50}
                    />
                  </div>
                ) : (
                  <p>Upload After Track</p>
                )}
              </button>
            </div>
          ) : modalOpen === "Profile" ? (
            <div className="engineer-modal-content">
              <ProfileEdit
                updateEngineerDetails={updateEngineerDetails}
                handleInputChange={handleInputChange}
                editableProfileDetails={editableProfileDetails}
              />
            </div>
          ) : modalOpen === "Service" ? (
            <div className="engineer-modal-content">
              <PackagesEdit
                handleInputChangeServices={handleInputChangeServices}
                numbersDropdown={numbersDropdown}
                editablePackageDetails={serviceModalDetails}
                setEditablePackageDetails={setServiceModalDetails}
                updateEngineerServices={updateEngineerServices}
                updateFeatures={updateEngineerFeatures}
                deleteFeatures={deleteEngineerFeatures}
                setModalOpen={setModalOpen}
                featuresList={featuresList}
                setSelectedFeaturesList={setSelectedFeaturesList}
                selectedfeaturesList={selectedfeaturesList}
              />
            </div>
          ) : modalOpen === "ServiceAdd" ? (
            <div className="engineer-modal-content">
              <PackagesAdd
                serviceNames={serviceNames}
                handleInputChangeServices={handleInputChangeServices}
                numbersDropdown={numbersDropdown}
                editablePackageDetails={serviceModalDetails}
                setEditablePackageDetails={setServiceModalDetails}
                AddEngineerServices={AddEngineerServices}
                deleteServices={DeleteEngineerServices}
                featuresList={featuresList}
                setSelectedFeaturesList={setSelectedFeaturesList}
                selectedfeaturesList={selectedfeaturesList}
                setStemRanges={setStemRanges}
                stemRanges={stemRanges}
                stems={stems}
                setStems={setStems}
                engineerStems={
                  engineerStems?.length > 0
                    ? parseInt(engineerStems[0]?.cost)
                    : 0
                }
                setAddServiceStems={setAddServiceStems}
                addServiceStems={addServiceStems}
                UpdateServiceStems={UpdateServiceStems}
                setServicesToBeAdded={setServicesToBeAdded}
                servicesToBeAdded={servicesToBeAdded}
                setSelectedService={setSelectedService}
                selectedService={selectedService}
              />
            </div>
          ) : modalOpen === "ServiceStems" ? (
            <div className="engineer-modal-content">
              <StemsAdd
                handleInputChangeServices={handleInputChangeServices}
                numbersDropdown={numbersDropdown}
                editablePackageDetails={serviceModalDetails}
                setEditablePackageDetails={setServiceModalDetails}
                AddEngineerServices={AddEngineerServices}
                deleteServices={DeleteEngineerServices}
                featuresList={featuresList}
                setSelectedFeaturesList={setSelectedFeaturesList}
                selectedfeaturesList={selectedfeaturesList}
                setStemRanges={setStemRanges}
                stemRanges={stemRanges}
                stems={stems}
                setStems={setStems}
                setAddServiceStems={setAddServiceStems}
                addServiceStems={addServiceStems}
                UpdateServiceStems={UpdateServiceStems}
                engineerStems={engineerStems}
              />
            </div>
          ) : modalOpen === "Delete" ? (
            <div className="engineer-modal-content">
              <DeletePackage
                serviceDetails={serviceModalDetails}
                deleteServices={DeleteEngineerServices}
                handleClose={handleClose}
              />
            </div>
          ) : modalOpen === "Genre" ? (
            <div className="engineer-modal-content">
              <UpdateGenre
                genreList={allGenres}
                selectedGenreList={selectedGenreList}
                setSelectedGenreList={setSelectedGenreList}
                UpdateEngineerGenre={UpdateEngineerGenre}
              />
            </div>
          ) : null}
        </Box>
      </Modal>
      <div className="header-container">
        <div className="engineer-profile-img-container">
          <input
            type="file"
            id="profile-img-upload"
            ref={profileImg}
            onChange={(e) => {
              console.log("FILE-------->", e);
              setProfileImgFile({
                name: e?.target?.files[0]?.name,
                file: e?.target?.files[0],
              });
            }}
          />
          <img
            src={
              engineerDetails?.image ? engineerDetails?.image : placeholderImg
            }
            alt="engineer profile"
            id="engineer-profile-img"
          />
          <button
            id="profile-img-btn"
            onClick={() => {
              profileImg.current.click();
            }}
          >
            <img
              src="https://img.icons8.com/fluency-systems-filled/48/FFFFFF/edit.png"
              alt="edit"
              id="profile-edit-icon"
            />
          </button>
        </div>
        {profileImgFile.name && (
          <div className="profile-img-upload-container">
            <p>{profileImgFile.name}</p>
            <button onClick={uploadProfileImg} className="update-button">
              Upload Profile Image
            </button>
          </div>
        )}
        <div className="left-container">
          <div className="engineer-profile-name-details">
            <h1 id="engineer-profile-name">
              {engineerDetails?.firstname + " " + engineerDetails?.lastname}
            </h1>
            {engineerDetails?.isVerified === false && (
              <div>
                <img
                  id="engineer-profile-bluetick"
                  src="https://img.icons8.com/fluency/50/000000/instagram-check-mark.png"
                  alt="blueTick"
                />
              </div>
            )}
          </div>
          <p id="engineer-profile-username">@{engineerDetails?.username}</p>
          <p id="engineer-location">
            {engineerDetails?.address + ", " + engineerDetails?.city}
          </p>
          <div className="engineer-profile-details-social-container">
            {engineerDetails?.instagram && (
              <a
                href={engineerDetails?.instagram}
                target="_blank"
                rel="noopener noreferrer"
                id="engineer-socials-link"
              >
                <img
                  src="https://img.icons8.com/fluency/100/000000/instagram-new.png"
                  alt="instagram"
                  className="engineer-socials"
                />
              </a>
            )}
            {engineerDetails?.spotify && (
              <a
                href={engineerDetails?.spotify}
                target="_blank"
                rel="noopener noreferrer"
                id="engineer-socials-link"
              >
                <img
                  src="https://img.icons8.com/fluency/100/000000/spotify.png"
                  alt="spotify"
                  className="engineer-socials"
                />
              </a>
            )}
            {engineerDetails?.youtube && (
              <a
                href={engineerDetails?.youtube}
                target="_blank"
                rel="noopener noreferrer"
                id="engineer-socials-link"
              >
                <img
                  src="https://img.icons8.com/color/48/null/youtube-play.png"
                  alt="spotify"
                  className="engineer-socials"
                />
              </a>
            )}
          </div>
          <button
            onClick={() => {
              handleOpen();
              setModalOpen("Profile");
            }}
            id="engineer-profile-edit-btn"
          >
            <Edit />
            Edit profile details
          </button>
        </div>
        <div className="right-container">
          <div className="engineer-profile-ratings-container">
            <p id="engineer-avg-ratings">
              <span id="engineer-profile-ratings-star">⭐</span>
              {engineerDetails?.rating}
            </p>
            <p id="engineer-avg-ratings-text">Ratings</p>
          </div>
          <div className="engineer-profile-ratings-container">
            <p id="engineer-avg-ratings">{engineerDetails?.ordersCount}</p>
            <p id="engineer-avg-ratings-text">Orders</p>
          </div>
        </div>
      </div>

      <div className="about-container">
        <h2 className="about-heading">About</h2>
        {/* <p className="about-text">
          Mixing | Mastering | Producer | Recording engineer
        </p> */}
        <p className="engineer-bio">
          {engineerDetails?.bio !== "" ? engineerDetails?.bio : "No Bio"}
        </p>
        <div className="services-title-btn-container">
          <h2 className="about-heading">Genre</h2>
          <button
            onClick={() => {
              handleOpen();
              setModalOpen("Genre");
            }}
            id="engineer-genre-edit-btn"
          >
            <Edit />
            Edit Genre
          </button>
        </div>
        <div className="engineer-genre-container">
          {engineerGenres.length > 0 ? (
            engineerGenres.map((genre) => (
              <div className="engineer-genre">
                <p id="engineer-genre-text">{genre?.genre?.genre_name}</p>
              </div>
            ))
          ) : (
            <p>No genre</p>
          )}
        </div>
      </div>
      <div className="engineer-portfolio-container">
        <div className="engineer-portfolio-header">
          <h2 className="about-heading">Portfolio</h2>
          <button
            onClick={() => {
              handleOpen();
              setModalOpen("Tracks");
            }}
            id="engineer-profile-edit-btn"
          >
            Update Tracks
          </button>
        </div>
        {engineerDetails?.before_edit === "" && (
          <p id="demo-tracks-text">
            These are demo tracks, not uploaded by the engineer!
          </p>
        )}
        <div className="engineer-inner-music-before-after-container">
          <div className="engineer-music-btn-musictrack-container">
            <div className="engineer-music-play-btn-container">
              <h1 id="before-after-title">Before</h1>
              <button
                onClick={() => {
                  setPlayBtnClicked({ before: !playBtnClicked.before });
                  playAudioBefore();
                }}
                id="engineer-music-play-btn"
              >
                <img
                  id="play-btn-img"
                  src={
                    playBtnClicked.before
                      ? "https://img.icons8.com/ios-filled/100/FFFFFF/pause--v1.png"
                      : "https://img.icons8.com/material-rounded/100/FFFFFF/play--v1.png"
                  }
                  alt="play"
                />
              </button>
            </div>
            <div id="waveformBefore" />
          </div>
        </div>
        <div className="engineer-inner-music-before-after-container">
          <div className="engineer-music-btn-musictrack-container">
            <div className="engineer-music-play-btn-container">
              <h1 id="before-after-title">After</h1>
              <button
                onClick={() => {
                  setPlayBtnClicked({ after: !playBtnClicked.after });
                  playAudioAfter();
                }}
                id="engineer-music-play-btn"
              >
                <img
                  id="play-btn-img"
                  src={
                    playBtnClicked.after
                      ? "https://img.icons8.com/ios-filled/100/FFFFFF/pause--v1.png"
                      : "https://img.icons8.com/material-rounded/100/FFFFFF/play--v1.png"
                  }
                  alt="play"
                />
              </button>
            </div>
            <div id="waveformAfter" />
          </div>
        </div>
      </div>
      <div className="services-container">
        <div className="services-title-btn-container">
          <h2 className="services-heading">Services</h2>
          {engineerServicesData.length < 4 && (
            <button
              id="add-service-btn"
              onClick={() => {
                handleOpen();
                setModalOpen("ServiceAdd");
              }}
            >
              Add a service
            </button>
          )}
          <button
            id="add-service-btn"
            onClick={() => {
              handleOpen();
              setModalOpen("ServiceStems");
            }}
          >
            Add Stems
          </button>
        </div>
        <div className="services-wrapper">
          {/* <EngineerServices
            title="Mastering"
            cost="2,405"
            feature1="Mastering"
            feature2="Days Delivery"
            feature3="Revisions"
            feature4="Dolby Atmos ADM Master"
            feature5="Analog Gear Master"
          /> */}
          {engineerServicesData ? (
            engineerServicesData?.map((service, index) => (
              <EngineerServices
                name={service.service_name}
                cost={service.cost}
                dropdown={service.false}
                features={service.selectedfeatures}
                description={service.description}
                delivery_days={service.delivery_days}
                revisions={service.revisions}
                serviceId={service.id}
                index={index}
              />
            ))
          ) : (
            <p>No services at the moment</p>
          )}
        </div>
      </div>

      <div className="reviews-section">
        <h2 className="reviews-heading">Reviews</h2>
        <div className="engineer-reviews-container">
          {engineerReviews?.length > 0 ? (
            engineerReviews.map((review) => (
              <EngineerReviews
                name={review.name}
                text={review.text}
                score={review.score}
              />
            ))
          ) : (
            <p>No reviews yet!</p>
          )}
        </div>
      </div>

      {/* <div className="engineer-profile-container">
        
        <div className="engineer-profile-bottom-container">
          <div className="engineer-dashboard-navbar">
            <div
              onClick={() => {
                onClickNavItem("Services");
              }}
              className="engineer-navbar-item-container"
            >
              <div
                className={
                  navItemName === "Services"
                    ? "engineer-navbar-item-selected"
                    : "engineer-navbar-item"
                }
              >
                <p className="engineer-navbar-text">Services</p>
              </div>
            </div>
            <div
              onClick={() => {
                onClickNavItem("Reviews");
              }}
              className="engineer-navbar-item-container"
            >
              <div
                className={
                  navItemName === "Reviews"
                    ? "engineer-navbar-item-selected"
                    : "engineer-navbar-item"
                }
              >
                <p className="engineer-navbar-text">Reviews</p>
              </div>
            </div>
          </div>
          {navItemName === "Services" ? (
            <>
              {screenWidth > 800 ? (
                <div className="engineer-service-content-container">
                  {engineerServicesData?.map((item, index) => (
                    <EngineerService
                      id={item.id}
                      dropdown={true}
                      cost={item.cost}
                      name={item.service_name}
                      description={item.description}
                      delivery_days={item.delivery_days}
                      revisions={item.revisions}
                      features={item.features}
                      index={index}
                    />
                  ))}
                </div>
              ) : (
                <EngineerServiceMobile />
              )}
            </>
          ) : (
            <div className="engineer-reviews-content-container">
              <EngineerReviews />
              <EngineerReviews />
              <EngineerReviews />
              <EngineerReviews />
              <EngineerReviews />
              <EngineerReviews />
            </div>
          )}
        </div>
      </div> */}
    </div>
  );
}

export default EngineerProfile;
