import { createContext, useState } from "react";

const NavContext = createContext();

export function NavContextProvider({ children }) {
  const [activeNavItem, setActiveNavItem] = useState("D");
  return (
    <NavContext.Provider
      value={{
        activeNavItem,
        setActiveNavItem,
      }}
    >
      {children}
    </NavContext.Provider>
  );
}

export default NavContext;
