import "./styles.css";
import {
  EventNote,
  Today,
  AccessTime,
  AccountBalanceWalletOutlined,
  PaidOutlined,
} from "@mui/icons-material";
import { useEffect, useContext, useState } from "react";
import NavContext from "../../Context/NavContext";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

const StudioBookings = () => {
  //Navigation and URL Params
  const navigate = useNavigate();
  const { component } = useParams();
  const { setActiveNavItem } = useContext(NavContext);
  const [activeFilter, setActiveFilter] = useState(component);
  const [allOrders, setAllOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [acceptedOrders, setAcceptedOrders] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [itemClicked, setItemClicked] = useState({
    orderId: "",
    order: false,
    accept: false,
    reject: false,
  });

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);
  }, []);

  useEffect(() => {
    document.title = "Jamrio | Bookings";
    setActiveNavItem("B");
    GetAllOrders();
  }, []);

  const GetAllOrders = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/dashboard/studio/${window.localStorage.getItem("studioId")}/orders`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Studio orders ----->", data);
          }
          setAllOrders(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    setPendingOrders([]);
    if (allOrders) {
      Object.entries(allOrders).forEach(([key, value]) => {
        return value.state === "approval_pending"
          ? setPendingOrders((pendingOrders) => [...pendingOrders, value])
          : null;
      });
    }
  }, [allOrders]);

  useEffect(() => {
    setAcceptedOrders([]);
    if (allOrders) {
      Object.entries(allOrders).forEach(([key, value]) => {
        return value.state === "otp_pending" &&
          new Date(value.bookingdate) >= new Date()
          ? setAcceptedOrders((acceptedOrders) => [...acceptedOrders, value])
          : null;
      });
    }
  }, [allOrders]);

  //Order accepting and rejecting api

  const AcceptRejectOrder = async (orderId, status, message) => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/dashboard/studio/${window.localStorage.getItem(
        "studioId"
      )}/orders/${orderId}/status`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
        body: JSON.stringify({
          is_approved: status,
        }),
      }
    )
      .then((response) => {
        return response.status;
      })
      .then((data) => {
        if (data === 204) {
          alert(message);
          GetAllOrders();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed");
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const handleAccept = (orderId) => {
  //   if (process.env.NODE_ENV === "development") {
  //     console.log("Accepting order", orderId);
  //   }
  //   AcceptRejectOrder(orderId, 1, "Order Accepted");
  // };

  // const handleReject = (orderId) => {
  //   if (process.env.NODE_ENV === "development") {
  //     console.log("Rejecting order", orderId);
  //   }
  //   navigate(`/studio-home/order/${orderId}/reject`);
  // };

  useEffect(() => {
    onClickOrder();
  }, [itemClicked]);

  const onClickOrder = () => {
    if (itemClicked.accept) {
      navigate(`/studio-home/order/${itemClicked.orderId}/null`);
    }
    if (itemClicked.reject) {
      navigate(`/studio-home/order/${itemClicked.orderId}/reject`);
    }
    if (itemClicked.order) {
      navigate(`/studio-home/order/${itemClicked.orderId}/null`);
    }
  };

  const PendingOrders = ({ order }) => {
    return (
      <div
        onClick={() => {
          setItemClicked({
            orderId: order?.id,
            order: true,
            accept: false,
            reject: false,
          });
        }}
        className="booking-container-phone"
      >
        <h2 style={{margin:"0", marginTop:"20px"}}>{order?.studioname}</h2>
        <p id="bookings-order-cost-phone">₹{order?.netamount}</p>
        <p id="bookings-order-text-id2">Order Id: {order?.id}</p>
        <p id="bookings-order-text-phone">
          {moment.utc(order.bookingdate).format("MMM Do YYYY")}
        </p>
        {/* <p id="bookings-order-text-phone">
          {parseInt(order?.starttime.substring(0, 2)) > 12
            ? parseInt(order?.starttime.substring(0, 2)) - 12 + "pm"
            : parseInt(order?.starttime.substring(0, 2)) === 12
            ? parseInt(order?.starttime.substring(0, 2)) + "pm"
            : parseInt(order?.starttime.substring(0, 2)) + "am"}{" "}
          -{" "}
          {parseInt(order?.endtime.substring(0, 2)) > 12
            ? order?.endtime.substring(0, 2)?.substring(0, 2) - 12 + "pm"
            : parseInt(order?.endtime.substring(0, 2)) === 12
            ? parseInt(order?.endtime.substring(0, 2)) + "pm"
            : parseInt(order?.endtime.substring(0, 2)) + "am"}
        </p> */}
        {order?.state === "approval_pending" ? (
          <div className="bookings-items-btn-container">
            <button
              onClick={() => {
                navigate(`/studio-home/order/${order.id}/accept`);
              }}
              id="bookings-accept-btn"
            >
              Accept
            </button>
            <button
              onClick={() => {
                console.log("clicked reject");
                navigate(`/studio-home/order/${order.id}/reject`);
              }}
              id="bookings-reject-btn"
            >
              Reject
            </button>
          </div>
        ) : (
          <p id="bookings-order-text-phone">Order Status: {order?.state}</p>
        )}
      </div>
    );
  };

  return (
    <div className="studioBookings">
      <h1 id="bookings-title">Studio Bookings</h1>
      <div className="bookings-items-container">
        <div className="bookings-items-top">
          <button
            onClick={() => {
              setActiveFilter("pending");
              navigate("/studio-home/bookings-pending");
            }}
            className={`bookings-filter-container${
              component === "bookings-pending" || activeFilter === "pending"
                ? "-selected"
                : ""
            }`}
          >
            <p id="bookings-filter-title">Pending <br /> Requests</p>
            <p
              id={`bookings-filter-number${
                component === "bookings-pending" || activeFilter === "pending"
                  ? "-selected"
                  : ""
              }`}
            >
              {pendingOrders?.length}
            </p>
          </button>
          <button
            onClick={() => {
              setActiveFilter("upcoming");
              navigate("/studio-home/bookings-upcoming");
            }}
            className={`bookings-filter-container${
              component === "bookings-upcoming" || activeFilter === "upcoming"
                ? "-selected"
                : ""
            }`}
          >
            <p id="bookings-filter-title">Upcoming <br /> Requests</p>
            <p
              id={`bookings-filter-number${
                component === "bookings-upcoming" || activeFilter === "upcoming"
                  ? "-selected"
                  : ""
              }`}
            >
              {acceptedOrders?.length}
            </p>
          </button>
          <button
            onClick={() => {
              setActiveFilter("total");
              navigate("/studio-home/bookings-total");
            }}
            className={`bookings-filter-container${
              component === "bookings-total" || activeFilter === "total"
                ? "-selected"
                : ""
            }`}
          >
            <p id="bookings-filter-title">Total <br /> Requests</p>
            <p
              id={`bookings-filter-number${
                component === "bookings-total" || activeFilter === "total"
                  ? "-selected"
                  : ""
              }`}
            >
              {allOrders?.length}
            </p>
          </button>
        </div>
        <div className="bookings-items-bottom">
          {component === "bookings-pending" || activeFilter === "pending" ? (
            pendingOrders &&
            pendingOrders?.map((order) => <PendingOrders order={order} />)
          ) : component === "bookings-upcoming" ||
            activeFilter === "upcoming" ? (
            acceptedOrders &&
            acceptedOrders?.map((order) => <PendingOrders order={order} />)
          ) : component === "bookings-total" || activeFilter === "total" ? (
            allOrders &&
            allOrders?.map((order) => <PendingOrders order={order} />)
          ) : (
            <p>No Orders Yet</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudioBookings;
