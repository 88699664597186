import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import OrderHistory from "./Pages/OrderHistory/OrderHistory";
import { SelectedPackagesProvider } from "./Context/PackageContext";
import Login from "./Pages/Login/Login";
import StudioHome from "./Pages/StudioHome/StudioHome";
import { NavContextProvider } from "./Context/NavContext";
import EngineerHome from "./Pages/EngineerHome/EngineerHome";
import EngineerOrderHistory from "./Pages/EngineerOrderHistory/EngineerOrderHistory";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";

function App() {
  return (
    <div className="App">
        <SelectedPackagesProvider>
        <NavContextProvider>
        <BrowserRouter>
          <Routes>
              <Route exact path="/" element={<Login />} />
              <Route path="/studio-home/:component" element={<StudioHome />} />
              <Route path="/engineer-home/:component" element={<EngineerHome />} />
              <Route path="/studio-home/order/:id/:action" element={<OrderHistory />} />
              <Route path="/engineer-home/order/:id" element={<EngineerOrderHistory />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
          </Routes>
        </BrowserRouter>
        </NavContextProvider>
        </SelectedPackagesProvider>
    </div>
  );
}

export default App;
