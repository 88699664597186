import "./styles.css";
import { Box, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  notifyErr,
  notifyRegistrationErr,
  notifyRegistrationSuccess,
} from "../../Components/ToastFunctions";
import Logo from "../../Images/jamrioLogo.webp";

function Login() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Jamrio | Login";
  }, []);

  useEffect(() => {
    if (userId !== null || userId !== "" || userId !== undefined) {
      CheckOwnerDetails();
    }
  }, [userId]);

  const OwnerLogin = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/auth/login`,
      // "http://localhost:3000/user/auth",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          email: email.toString().trim(),
          password: password.toString(),
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.data) {
          if (process.env.NODE_ENV === "development") {
            console.log("Logged in ----->", data);
          }
          notifyRegistrationSuccess(data.message);
          setUserId(data.data.userId);
          window.localStorage.setItem("AuthToken", data.data.token);
          window.localStorage.setItem("userId", data.data.userId);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
          notifyErr(data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const CheckOwnerDetails = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/user/${userId}`,
      // "http://localhost:3000/user/auth",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("User Details ----->", data);
          }
          if (data.studioengineerId !== null) {
            window.localStorage.setItem("usertype", data.usertype);
            window.localStorage.setItem("engineerId", data.studioengineerId);
            navigate("/engineer-home/dashboard");
            notifyRegistrationSuccess(data.message);
          }
          if (data.studioid !== null) {
            window.localStorage.setItem("usertype", data.usertype);
            window.localStorage.setItem("studioId", data.studioid);
            navigate("/studio-home/dashboard");
            notifyRegistrationSuccess(data.message);
          }
          if (data.studioid === null && data.studioengineerId === null) {
            alert("Not a Studio Owner or Engineer");
          }
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="login-container">
      <img src={Logo} alt="jamr-logo" id="jamr-logo" />
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
        id="input-box"
      >
        <TextField
          required
          id="filled-required"
          label="Email"
          variant="outlined"
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          required
          id="outlined-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          id="login-btn"
          variant="contained"
          onClick={() => {
            if (process.env.NODE_ENV === "development") {
              console.log("Email", email);
            }
            if (process.env.NODE_ENV === "development") {
              console.log("Pass", password);
            }
            OwnerLogin();
          }}
        >
          Login
        </Button>
      </Box>

      <p id="fgt-pass">
        <Link
          to="forgot-password"
          target="_blank"
          className="link_to-login"
          rel="noreferrer"
        >
          Forgot Password?
        </Link>
      </p>
      <p id="sign-up">
        Dont have an account?{" "}
        <a
          href="https://jamrio.com/Login/register"
          target="_blank"
          className="link_to-login"
          rel="noreferrer"
        >
          Sign Up
        </a>
      </p>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default Login;
