import React, { useEffect, useState } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { styled } from "@material-ui/core/styles";
import {
  notifyErr,
  notifyRegistrationSuccess,
} from "../../Components/ToastFunctions";
import { ToastContainer } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import { Box, TextField } from "@mui/material";
import { HStack, PinInput, PinInputField } from "@chakra-ui/react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// import loginImg from "../../Images/Login-BGHigher-Res.webp";
// import bgImg from "../../Images/bg-pattern.webp";
import loginImg from "../../Images/login-mockup-img.webp";
import logo from "../../Images/jamrioLogo.webp";
// import { Helmet } from "react-helmet";

const TextBox = styled(TextField)(() => ({
  "& fieldset": {
    boxShadow: "0 0 10px rgba(0,0,0,0.2)",
    borderRadius: "10px",
    border: "none",
  },
}));

function ForgotPassword() {
  let navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [token, setToken] = useState(0);
  const [disableBtn, setDisableBtn] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [userDetails, setUserDetails] = useState({
    password: "",
    confirmPassword: "",
    phone: "",
  });

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Send a request to reset password and get OTP and Token
  const requestPassReset = (e) => {
    e.preventDefault();
    fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/reset-password/mobile/${userDetails.phone}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (process.env.NODE_ENV === "development") {
          console.log(data);
        }
        if (data.token) {
          if (process.env.NODE_ENV === "development") {
            console.log("Success");
            setLoading(false);
          }
          setToken(data.token);
          notifyRegistrationSuccess(data.message);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failes", data);
            notifyErr(data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //  Send the OTP, Token and new password
  const passReset = (e) => {
    e.preventDefault();
    if (process.env.NODE_ENV === "development") {
      console.log("TOKEN", token);
    }
    fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/reset-password/mobile/${userDetails.phone}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
          otp: otp,
          newPassword: userDetails.password,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (process.env.NODE_ENV === "development") {
          console.log(data);
        }
        if (!data.errors) {
          if (process.env.NODE_ENV === "development") {
            console.log("Suuccess");
          }
          notifyRegistrationSuccess(data.message);
          navigate("/");
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data);
          }
          notifyErr(data.errors[0].msg);
          notifyErr(data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const PassResetForm = () => (
    <div className="login-container2">
      {/* <Helmet>
        <title>Jamrio | Reset Password</title>
        <meta
          name="description"
          content="Best place for booking and finding music studios, jampads,audio engineers, shoot locations and much more, Read blogs, Read Artist Testimonies and much more!"
        />
        <meta
          name="keywords"
          content="Music, Music Studios, Jampads, Audio Engineers, Book Studio, Shoot Locations"
        />
      </Helmet> */}
      <div className="login-left">
        <img
          src={loginImg}
          alt="Login-BGHigher-Res"
          className="login-left-img"
        />
      </div>
      <div className="login-right">
        {screenWidth > 800 ? (
          <form className="login-form">
            <div className="login-title-container">
              <p className="login-title">Reset Password</p>
              <p className="login-description">
                Enter the phone number you used when you joined
              </p>
            </div>
            <div className="inputs">
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": {
                    marginBottom: "30px",
                    width: "100%",
                  },
                }}
                noValidate
                autoComplete="off"
              >
                <div style={{ marginTop: "10px" }} className="mobile-number">
                  <div className="register-mobile-number">
                    <div className="country-code">
                      {/* <img
                        src="https://img.icons8.com/color/96/000000/india.png"
                        alt="flag"
                        className="flag"
                      /> */}
                      <h1>+91</h1>
                    </div>
                    <TextBox
                      required
                      className="outlined-error-helper-text"
                      variant="outlined"
                      // className="mobile-number-input"
                      type="number"
                      label="Enter Mobile Number"
                      value={userDetails.phone}
                      onChange={(e) =>
                        setUserDetails({
                          ...userDetails,
                          phone: e.target.value,
                        })
                      }
                    />
                  </div>
                  <button
                    style={{ marginTop: "20px" }}
                    onClick={(e) => {
                      requestPassReset(e);
                      setDisableBtn(false);
                      setLoading(true);
                    }}
                    className="send-otp-btn"
                    id="timer"
                  >
                    {loading ? (
                      <FaSpinner icon="spinner" className="spinner" />
                    ) : (
                      "Request OTP"
                    )}
                  </button>
                </div>
                <div>
                  <HStack
                    style={
                      disableBtn ? { display: "none" } : { display: "flex" }
                    }
                    className={disableBtn ? "hidden-otp" : "pin-container"}
                  >
                    <PinInput
                      placeholder="•"
                      size="sm"
                      otp
                      onChange={(e) => setOtp(e)}
                    >
                      <PinInputField className="pin-input" />
                      <PinInputField className="pin-input" />
                      <PinInputField className="pin-input" />
                      <PinInputField className="pin-input" />
                      <PinInputField className="pin-input" />
                      <PinInputField className="pin-input" />
                    </PinInput>
                  </HStack>
                </div>
              </Box>
            </div>
            <div
              style={{ marginTop: "15px" }}
              className={disableBtn ? "hidden-otp" : "forgot-pass-actions"}
            >
              <div className="new-password-section">
                <TextBox
                  required
                  disabled={disableBtn}
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  value={userDetails.password}
                  className="password"
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, password: e.target.value })
                  }
                  fullWidth
                />
                {showPassword ? (
                  <VisibilityOff
                    id="password-icon-show"
                    onClick={handleClickShowPassword}
                  />
                ) : (
                  <Visibility
                    id="password-icon-show"
                    onClick={handleClickShowPassword}
                  />
                )}

                <TextBox
                  required
                  variant="outlined"
                  type="password"
                  disabled={disableBtn}
                  label="Confirm Password"
                  className="confirm-password"
                  value={userDetails.confirmPassword}
                  onChange={(e) =>
                    setUserDetails({
                      ...userDetails,
                      confirmPassword: e.target.value,
                    })
                  }
                  fullWidth
                />
                <button
                  style={{ marginTop: "-5px" }}
                  disabled={disableBtn}
                  className="login-page-btn"
                  onClick={passReset}
                >
                  Reset Password
                </button>
              </div>
            </div>
          </form>
        ) : (
          // <form className="login-form">
          //   <div className="login-title-container">
          //     <p className="login-title">Login</p>
          //     <img src={logo} alt="jamrio logo" id="logo-on-login" />
          //   </div>

          //   <div className="inputs">
          //     <Box
          //       component="form"
          //       sx={{
          //         "& .MuiTextField-root": {
          //           marginBottom: "30px",
          //           width: "100%",
          //         },
          //       }}
          //       noValidate
          //       autoComplete="off"
          //     >
          //       <div className="mobile-number">
          //         <div className="register-mobile-number">
          //           <div className="country-code">
          //             <img
          //               src="https://img.icons8.com/color/96/000000/india.png"
          //               alt="flag"
          //               className="flag"
          //             />
          //             <h1>+91</h1>
          //           </div>
          //           <TextBox
          //             required
          //             className="outlined-error-helper-text"
          //             variant="outlined"
          //             // className="mobile-number-input"
          //             type="number"
          //             label="Mobile Number"
          //             value={userDetails.phone}
          //             onChange={(e) =>
          //               setUserDetails({
          //                 ...userDetails,
          //                 phone: e.target.value,
          //               })
          //             }
          //           />
          //         </div>
          //         <button
          //           onClick={(e) => {
          //             requestPassReset(e);
          //             setDisableBtn(false);
          //             setLoading(true);
          //           }}
          //           className="send-otp-btn"
          //           id="timer"
          //         >
          //           {loading ? <FaSpinner icon="spinner" className="spinner" /> : "Send OTP"}
          //         </button>
          //       </div>
          //       <div>
          //         <HStack className={disableBtn ? "hidden-otp":"pin-container"}>
          //           <PinInput
          //             placeholder="•"
          //             size="sm"
          //             otp
          //             onChange={(e) => setOtp(e)}
          //           >
          //             <PinInputField className="pin-input" />
          //             <PinInputField className="pin-input" />
          //             <PinInputField className="pin-input" />
          //             <PinInputField className="pin-input" />
          //             <PinInputField className="pin-input" />
          //             <PinInputField className="pin-input" />
          //           </PinInput>
          //         </HStack>
          //       </div>
          //     </Box>
          //   </div>
          //   <div className="forgot-pass-actions">
          //     <div className="new-password-section">
          //       <TextBox
          //         required
          //         variant="outlined"
          //         type={showPassword ? "text" : "password"}
          //         label="Password"
          //         value={userDetails.password}
          //         className="password"
          //         onChange={(e) =>
          //           setUserDetails({ ...userDetails, password: e.target.value })
          //         }
          //         fullWidth
          //       />
          //       {showPassword ? (
          //         <VisibilityOff
          //           id="password-icon-show"
          //           onClick={handleClickShowPassword}
          //         />
          //       ) : (
          //         <Visibility
          //           id="password-icon-show"
          //           onClick={handleClickShowPassword}
          //         />
          //       )}

          //       <TextBox
          //         required
          //         variant="outlined"
          //         type="password"
          //         label="Confirm Password"
          //         className="confirm-password"
          //         value={userDetails.confirmPassword}
          //         onChange={(e) =>
          //           setUserDetails({
          //             ...userDetails,
          //             confirmPassword: e.target.value,
          //           })
          //         }
          //         fullWidth
          //       />
          //       <button
          //         disabled={disableBtn}
          //         className="login-page-btn"
          //         onClick={passReset}
          //       >
          //         Reset Password
          //       </button>
          //     </div>
          //   </div>
          // </form>
          <form className="login-form">
            <div className="login-title-container">
              <p className="login-title">Reset Password</p>
              <p className="login-description">
                Enter the phone number you used when you joined
              </p>
            </div>
            <div className="inputs">
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": {
                    marginBottom: "30px",
                    width: "100%",
                  },
                }}
                noValidate
                autoComplete="off"
              >
                <div style={{ marginTop: "20px" }} className="mobile-number">
                  <div className="register-mobile-number">
                    <div className="country-code">
                      {/* <img
                        src="https://img.icons8.com/color/96/000000/india.png"
                        alt="flag"
                        className="flag"
                      /> */}
                      <h1>+91</h1>
                    </div>
                    <TextBox
                      required
                      className="outlined-error-helper-text"
                      variant="outlined"
                      // className="mobile-number-input"
                      type="number"
                      label="Enter Mobile Number"
                      value={userDetails.phone}
                      onChange={(e) =>
                        setUserDetails({
                          ...userDetails,
                          phone: e.target.value,
                        })
                      }
                    />
                  </div>
                  <button
                    style={{ marginTop: "10px" }}
                    onClick={(e) => {
                      requestPassReset(e);
                      setDisableBtn(false);
                      setLoading(true);
                    }}
                    className="send-otp-btn"
                    id="timer"
                  >
                    {loading ? (
                      <FaSpinner icon="spinner" className="spinner" />
                    ) : (
                      "Request OTP"
                    )}
                  </button>
                </div>
                <div>
                  <HStack
                    style={
                      disableBtn ? { display: "none" } : { display: "flex" }
                    }
                    className={disableBtn ? "hidden-otp" : "pin-container"}
                  >
                    <PinInput
                      placeholder="•"
                      size="sm"
                      otp
                      onChange={(e) => setOtp(e)}
                    >
                      <PinInputField className="pin-input" />
                      <PinInputField className="pin-input" />
                      <PinInputField className="pin-input" />
                      <PinInputField className="pin-input" />
                      <PinInputField className="pin-input" />
                      <PinInputField className="pin-input" />
                    </PinInput>
                  </HStack>
                </div>
              </Box>
            </div>
            <div
              style={{ marginTop: "15px" }}
              className={disableBtn ? "hidden-otp" : "forgot-pass-actions"}
            >
              <div className="new-password-section">
                <TextBox
                  required
                  disabled={disableBtn}
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  value={userDetails.password}
                  className="password"
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, password: e.target.value })
                  }
                  fullWidth
                />
                {showPassword ? (
                  <VisibilityOff
                    id="password-icon-show"
                    onClick={handleClickShowPassword}
                  />
                ) : (
                  <Visibility
                    id="password-icon-show"
                    onClick={handleClickShowPassword}
                  />
                )}

                <TextBox
                  required
                  variant="outlined"
                  type="password"
                  disabled={disableBtn}
                  label="Confirm Password"
                  className="confirm-password"
                  value={userDetails.confirmPassword}
                  onChange={(e) =>
                    setUserDetails({
                      ...userDetails,
                      confirmPassword: e.target.value,
                    })
                  }
                  fullWidth
                />
                <button
                  style={{ marginTop: "-5px" }}
                  disabled={disableBtn}
                  className="login-page-btn"
                  onClick={passReset}
                >
                  Reset Password
                </button>
              </div>
            </div>
          </form>
        )}
        <div id="recaptcha-container"></div>
      </div>
    </div>
  );

  // const emailpassLogin = () => (
  //   <div className="login-container">
  //     <Helmet>
  //       <title>Jamrio | Login</title>
  //       <meta
  //         name="description"
  //         content="Best place for booking and finding music studios, jampads,audio engineers, shoot locations and much more, Read blogs, Read Artist Testimonies and much more!"
  //       />
  //       <meta
  //         name="keywords"
  //         content="Music, Music Studios, Jampads, Audio Engineers, Book Studio, Shoot Locations"
  //       />
  //     </Helmet>
  //     <div className="login-left">
  //       <img
  //         src={loginImg}
  //         alt="registration-illustration"
  //         className="login-left-img"
  //       />
  //     </div>
  //     <div className="login-right">
  //       <form className="login-form">
  //         <div className="login-title-container">
  //           <p className="login-title">Login</p>
  //           <img src={logo} alt="jamrio logo" id="logo-on-login" />
  //         </div>
  //         <div className="inputs">
  //           <Box
  //             component="form"
  //             sx={{
  //               "& .MuiTextField-root": {
  //                 marginBottom: "30px",
  //                 width: "100%",
  //               },
  //             }}
  //             // noValidate
  //             id="login-items-container"
  //           >
  //             <TextBox
  //               required
  //               variant="outlined"
  //               label="Email Id"
  //               value={userDetails.email}
  //               onChange={(e) =>
  //                 setUserDetails({ ...userDetails, email: e.target.value })
  //               }
  //             />
  //             <TextBox
  //               required
  //               variant="outlined"
  //               type={showPassword ? "text" : "password"}
  //               label="Password"
  //               value={userDetails.password}
  //               onChange={(e) =>
  //                 setUserDetails({
  //                   ...userDetails,
  //                   password: e.target.value,
  //                 })
  //               }
  //             />
  //             {showPassword ? (
  //               <VisibilityOff
  //                 id="password-icon"
  //                 onClick={handleClickShowPassword}
  //               />
  //             ) : (
  //               <Visibility
  //                 id="password-icon"
  //                 onClick={handleClickShowPassword}
  //               />
  //             )}
  //             <div
  //               className="text-container-fp"
  //               onClick={() => setNavItem("forgotPass")}
  //             >
  //               <Link to="/forgot-password">
  //                 <p className="forgot-pass">Forgot Password?</p>
  //               </Link>
  //             </div>
  //           </Box>
  //         </div>
  //         <div className="login-actions-uname">
  //           <button
  //             disabled={signInSent}
  //             className="login-page-btn"
  //             onClick={(e) => {
  //               loginWithEmailPass();
  //               setSignInSent(true);
  //             }}
  //           >
  //             Login
  //           </button>
  //         </div>
  //       </form>
  //       <div id="recaptcha-container"></div>
  //     </div>
  //   </div>
  // );

  // const forgotPass = () => (
  //   <div className="login-container">
  //     <div className="login-left">
  //       <img
  //         src={registrationImg}
  //         alt="registration-illustration"
  //         className="login-left-img"
  //       />
  //     </div>
  //     <div className="login-right">
  //       <form className="login-form">
  //         <p className="login-title">Forgot Password</p>
  //         <p className="login-text">
  //           Please enter your email id to get a an email for resetting your
  //           password
  //         </p>
  //         <div className="inputs">
  //           <Box
  //             component="form"
  //             sx={{
  //               "& .MuiTextField-root": {
  //                 marginBottom: "30px",
  //                 width: "100%",
  //               },
  //             }}
  //             noValidate
  //             autoComplete="off"
  //             id="login-items-container"
  //           >
  //             <TextBox
  //               required
  //               variant="outlined"
  //               label="Email"
  //               value={userDetails.email}
  //               onChange={(e) =>
  //                 setUserDetails({ ...userDetails, email: e.target.value })
  //               }
  //             />
  //           </Box>
  //         </div>
  //         <div className="login-actions-uname">
  //           <button
  //             disabled={emailSent}
  //             className="login-page-btn"
  //             onClick={forgotPassword}
  //           >
  //             Send Email
  //           </button>
  //         </div>
  //       </form>
  //       <div id="recaptcha-container"></div>
  //     </div>
  //   </div>
  // );

  return (
    <>
      {/* <img src={bgImg} alt="background pattern" id="login-background-pattern" /> */}
      <div className="login">{PassResetForm()}</div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default ForgotPassword;
