import React from "react";
import "./styles.css";
import { useEffect, useContext, useState } from "react";
import NavContext from "../../Context/NavContext";
import {
  AccountCircleRounded,
  AddCircleOutline,
  ArrowForwardIosRounded,
  Call,
  Close,
  ContactEmergency,
  Edit,
  Help,
  Logout,
  ModeEdit,
  Visibility,
} from "@mui/icons-material";
import { Modal, Typography, Box, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const EditEquiments = ({ equipments, setEquipments, UpdateEquipments }) => {
  // List of equipments and input boxes
  return (
    <div className="equipments">
      <div className="equipments-list">
        {/* <div className="equipments-list-item">
          <div className="equipments-list-item-label">Microphone</div>
          <div className="equipments-list-item-input">
            <TextField
              id="outlined-basic"
              label="Microphone"
              variant="outlined"
              value={equipments.microphone}
              onChange={(e) =>
                setEquipments({ ...equipments, microphone: e.target.value })
              }
            />
          </div>
        </div> */}
        {Object.entries(equipments).map(([key, value]) => {
          if (key !== "sid" && key !== "accousticallyTreated") {
            return (
              <div className="equipments-list-item">
                <div className="equipments-list-item-label">
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </div>
                <div className="equipments-list-item-input">
                  <TextField
                    id="outlined-basic"
                    label={key}
                    variant="outlined"
                    value={value}
                    onChange={(e) =>
                      setEquipments({ ...equipments, [key]: e.target.value })
                    }
                  />
                </div>
              </div>
            );
          }
        })}
      </div>
      <button
        id="equipments-save-btn"
        onClick={() => {
          console.log("Equipments", equipments);
          UpdateEquipments();
        }}
      >
        Save Details
      </button>
    </div>
  );
};

const EditHostRules = ({
  hostRules,
  setHostRules,
  UpdateHostRules,
  hostRulesCounter,
  setHostRulesCounter,
}) => {
  // List of Rules and input boxes
  return (
    <div className="host-rules">
      <div className="host-rules-list">
        {hostRules.map((rule, index) => {
          return (
            <div className="host-rules-list-item">
              <div className="host-rules-list-item-label">Rule {index + 1}</div>
              <div className="host-rules-list-item-input">
                <TextField
                  id="host-rules-list-item-input-outlined-basic"
                  label="Rule"
                  variant="outlined"
                  value={rule.title}
                  fullWidth
                  onChange={(e) => {
                    // host rules title and description
                    let temp = [...hostRules];
                    temp[index] = { title: e.target.value, description: "abc" };
                    setHostRules(temp);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="host-rules-add">
        <button
          id="host-rules-add-btn"
          onClick={() => {
            setHostRules([...hostRules, ""]);
            setHostRulesCounter(hostRulesCounter + 1);
          }}
        >
          Add Rule <AddCircleOutline />
        </button>
      </div>
      <button
        id="host-rules-save-btn"
        onClick={() => {
          console.log("Host Rules", hostRules);
          hostRules?.map((rule, index) => {
            if (rule.title !== "") {
              console.log("Rule", rule);
              UpdateHostRules(rule);
            }
          });
        }}
      >
        Save Details
      </button>
    </div>
  );
};

const StudioAccount = () => {
  const { setActiveNavItem } = useContext(NavContext);
  const [studioData, setStudioData] = useState();
  const [userData, setUserData] = useState();
  const [avgRatings, setAvgRatings] = useState();
  const navigate = useNavigate();
  const [open, setOpen] = useState({
    options: false,
    equipments: false,
    host: false,
  });
  const handleOpen = (name) => {
    setOpen({ ...open, [name]: true });
  };
  const handleClose = () => setOpen(false);
  const [equipments, setEquipments] = useState({
    microphone: "",
    headphones: "",
    audioInterface: "",
    MIDI: "",
    monitor: "",
    drums: "",
    racks: "",
    mixer: "",
    guitar: "",
    cymbals: "",
    speaker: "",
    keyboard: "",
    accousticallyTreated: false,
    sid: window.localStorage.getItem("studioId"),
  });
  const [hostRules, setHostRules] = useState([]);
  const [hostRulesCounter, setHostRulesCounter] = useState(0);

  useEffect(() => {
    document.title = "Jamrio | Account";
    setActiveNavItem("A");
    GetStudioData();
    GetOwnerDetails();
  }, []);

  const GetStudioData = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/studio/${window.localStorage.getItem("studioId")}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Studio Data ------------------- ----->", data);
          }
          setStudioData(data.studio);
          setAvgRatings(data.rating);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetOwnerDetails = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/user/${window.localStorage.getItem("userId")}`,
      // "http://localhost:3000/user/auth",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("User Details ----->", data);
          }
          setUserData(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdateEquipments = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/studio/equipment`,
      // "http://localhost:3000/user/auth",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
        body: JSON.stringify(equipments),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Equipments list updated ----->", data);
          }
          toast.success("Updated Equipment List Successfully");
          handleClose();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
          toast.error("Failed to Update Equipment List");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdateHostRules = async (rule) => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/studio/hostrules`,
      // "http://localhost:3000/user/auth",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
        body: JSON.stringify(rule),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Host rules API ----->", data);
          }
          toast.success("Updated Host Rules Successfully");
          // handleClose();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
          toast.error("Failed to Update Equipment List");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onClickLogout = () => {
    window.localStorage.removeItem("userId");
    window.localStorage.removeItem("studioId");
    window.localStorage.removeItem("engineerId");
    OnLogout();
  };

  const OnLogout = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/auth/logout`,
      // "http://localhost:3000/user/auth",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Logged Out ----->", data);
          }
          navigate("/");
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="account-page">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Modal
        open={open.options || open.equipments || open.host}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // hideBackdrop
      >
        <Box id="operational-hrs-modal">
          <Typography
            id="operational-hrs-modal-title"
            variant="h6"
            component="h2"
          >
            {open.options
              ? "Edit Details"
              : open.equipments
              ? "Add equipment list"
              : open.host
              ? "Add host rules"
              : null}
            <Close onClick={handleClose} id="operational-hrs-close-btn" />
          </Typography>
          <div className="operational-hrs-modal-content">
            {open.options ? (
              <div className="options-modal">
                <button onClick={() => handleOpen("equipments")}>
                  Edit equipment List
                </button>
                <button onClick={() => handleOpen("host")}>
                  Edit Host Rules
                </button>
              </div>
            ) : open.equipments ? (
              <p>Add your equipments list</p>
            ) : open.host ? (
              <p>Add your host rules</p>
            ) : null}
          </div>
        </Box>
      </Modal>
      <Modal
        open={open.equipments || open.host}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // hideBackdrop
      >
        <Box id="operational-hrs-modal">
          <Typography
            id="operational-hrs-modal-title"
            variant="h6"
            component="h2"
          >
            {open.equipments
              ? "Add equipment list"
              : open.host
              ? "Add host rules"
              : null}
            <Close onClick={handleClose} id="operational-hrs-close-btn" />
          </Typography>
          <div className="operational-hrs-modal-content">
            {open.equipments ? (
              <EditEquiments
                equipments={equipments}
                setEquipments={setEquipments}
                UpdateEquipments={UpdateEquipments}
              />
            ) : open.host ? (
              <EditHostRules
                hostRules={hostRules}
                setHostRules={setHostRules}
                UpdateHostRules={UpdateHostRules}
                hostRulesCounter={hostRulesCounter}
                setHostRulesCounter={setHostRulesCounter}
              />
            ) : null}
          </div>
        </Box>
      </Modal>
      <div className="account-items-container">
        <div className="account-item account-details">
          <div id="account-img-container">
            {userData?.image ? (
              <img src={userData?.image} alt="profile" id="account-img" />
            ) : (
              <AccountCircleRounded />
            )}
          </div>
          <div className="account-details-container">
            <div className="acount-user-name-container">
              <p>{userData?.name}</p>
              <div className="account-ratings-container">
                ⭐<p>{avgRatings?.toFixed(1)}</p>
              </div>
            </div>
            <h1>{studioData?.name}</h1>
          </div>
        </div>
        <div className="account-item" onClick={() => handleOpen("options")}>
          <div id="account-icon-container">
            <ModeEdit id="account-icon" />
          </div>
          <p id="account-item-text">Edit Details</p>
          <ArrowForwardIosRounded id="account-icon" />
        </div>
        <div
          className="account-item"
          onClick={() =>
            window.open(`https://jamrio.com/coming-soon`, "_blank")
          }
        >
          <div id="account-icon-container">
            <Help id="account-icon" />
          </div>
          <p id="account-item-text">Help</p>
          <ArrowForwardIosRounded id="account-icon" />
        </div>
        <div
          className="account-item"
          onClick={() => window.open(`https://jamrio.com/contact-us`, "_blank")}
        >
          <div id="account-icon-container">
            <Call id="account-icon" />
          </div>
          <p id="account-item-text">Contact Us</p>
          <ArrowForwardIosRounded id="account-icon" />
        </div>
        <div className="account-item" onClick={onClickLogout}>
          <div id="account-icon-container">
            <Logout id="account-icon" />
          </div>
          <p id="account-item-text">Logout</p>
          <ArrowForwardIosRounded id="account-icon" />
        </div>
      </div>
    </div>
  );
};

export default StudioAccount;
