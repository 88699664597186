import React, { useContext, useEffect, useState } from "react";
import "./styles.css";
// import { ReBookingModal } from "../../components/ReBookingModal/ReBookingModal.jsx";
import OtpInput from "react-otp-input";
import { FileUploader } from "react-drag-drop-files";
import BookingDetailsContext from "../../Context/BookingDetailsContext.js";
import ScaleLoader from "react-spinners/ScaleLoader";
import Alert from "@mui/material/Alert";
import { FaFileAudio } from "react-icons/fa";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import Logo from "../../Images/jamrioLogo.webp";
import { ToastContainer, toast } from "react-toastify";
import io from "socket.io-client";

const socket = io(`https://${process.env.REACT_APP_DOMAIN}/inbox`, {
  extraHeaders: {
    Authorization: window.localStorage.getItem("AuthToken"),
  },
});

function OrderHistory({ token }) {
  const [LSOrderId, setLSOrderId] = useState(null);
  const [otp, setOtp] = useState();
  const [orderStarted, setOrderStarted] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState();
  const [uploading, setUploading] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [messageText, setMessageText] = useState();
  const [chatMessages, setChatMessages] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [orderMessages, setOrderMessages] = useState([]);
  const [userData, setUserData] = useState();
  const [showOtherComponents, setShowOtherComponents] = useState(false);
  const [inboxId, setInboxId] = useState([]);
  const [inboxes, setInboxes] = useState([]);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState("none");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { id, action } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Jamrio | Order History";
    if (action === "reject") {
      setOpen(true);
      setOpenModal("reject");
      handleReject(id);
    }
  }, []);

  useEffect(() => {
    FetchOrderDetails();
  }, []);

  //File Upload
  const fileTypes = ["JPG", "PNG", "GIF", "MP3"];
  const [file, setFile] = useState(null);
  const handleFileChange = (file) => {
    setFile(file);
    if (process.env.NODE_ENV === "development") {
      console.log("Selected file", file);
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    justifyContent: "center",
    minWidth: "250px",
  };

  const getLSOrderID = window.localStorage.getItem("orderId");

  useEffect(() => {
    if (
      window.localStorage.getItem("orderId") !== null ||
      window.localStorage.getItem("orderId") !== undefined ||
      window.localStorage.getItem("orderId") !== ""
    ) {
      setLSOrderId(window.localStorage.getItem("orderId"));
    }
  }, [getLSOrderID]);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.log("order Data", orderDetails);
    }
    fetchUserData();
  }, [orderDetails]);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.log("Firebase Data order Messages", orderMessages);
    }
  }, [orderMessages]);

  useEffect(() => {
    setChatMessages([]);

    Object.values(orderMessages).forEach((value) => {
      if (process.env.NODE_ENV === "development") {
        //
        console.log("Message Value", value);
      }
      return setChatMessages((msg) => [...msg, value]);
    });
  }, [LSOrderId, orderMessages]);

  useEffect(() => {
    chatMessages.map((msg) => {
      if (process.env.NODE_ENV === "development") {
        console.log("Message ----->", msg);
      }
      if (process.env.NODE_ENV === "development") {
        console.log("Message From", msg.origin);
      }
      if (process.env.NODE_ENV === "development") {
        console.log("Chat Message", msg.message);
      }
      // return order.orderId === LSOrderId;
    });
  }, [chatMessages]);

  useEffect(() => {
    GetUploadedFiles();
    GetInboxes();
  }, []);

  useEffect(() => {
    setTimeout(() => setShowAlert(false), 4000);
  }, [showAlert]);

  useEffect(() => {
    // filter inbox id with studio id matcching the studio id of the order
    setInboxId([]);
    setInboxId(inboxes.filter((inbox) => inbox.userId === orderDetails.userid));
  }, [inboxes, orderDetails]);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.log("Inbox id", inboxId);
    }
  }, [inboxId]);

  //Fetch Order Details
  const FetchOrderDetails = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/studio/${window.localStorage.getItem("trnStudioId")}/orders/${id}/get`,
      {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("GET order details ----->", data);
          }
          setOrderDetails(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //Fetch user details
  const fetchUserData = async () => {
    if (process.env.NODE_ENV === "development") {
      console.log("USER ID--------->", orderDetails?.userid);
    }
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/user/${orderDetails?.userid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (process.env.NODE_ENV === "development") {
          console.log("Response Body -> ", JSON.parse(JSON.stringify(data)));
        }

        if (!data.message) {
          setUserData(data);
          if (process.env.NODE_ENV === "development") {
            console.log("USER DETAILS ----->", data);
          }
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // OTP API

  const OTPApprove = async (inputOtp) => {
    //PRODUCTION
    if (process.env.NODE_ENV === "development") {
      console.log("OTP Entered: ", inputOtp);
    }
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/dashboard/studio/${id}/orders/${id}/otp`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
        body: JSON.stringify({
          otp: inputOtp,
        }),
      }
    )
      .then((response) => {
        if (process.env.NODE_ENV === "development") {
          console.log("Status", response.status);
        }
        if (response.status === 204) {
          setOrderStarted(true);
        }
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Studio Data ----->", data);
          }
          alert("Order Started");
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
          alert(data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //Order accepting and rejecting api

  const AcceptRejectOrder = async (orderId, status, message) => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/dashboard/studio/${id}/orders/${orderId}/status`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
        body: JSON.stringify({
          is_approved: status,
        }),
      }
    )
      .then((response) => {
        return response.status;
      })
      .then((data) => {
        if (data === 204) {
          alert(message);
          FetchOrderDetails();
          if (status === 1) {
            setShowOtherComponents(true);
          }
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed");
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAccept = (orderId) => {
    if (process.env.NODE_ENV === "development") {
      console.log("Accepting order", orderId);
    }
    AcceptRejectOrder(orderId, 1, "Order Accepted");
  };

  const handleReject = (orderId) => {
    if (process.env.NODE_ENV === "development") {
      console.log("Rejecting order", orderId);
    }
    setOpenModal("reject");
    setOpen(true);
    AcceptRejectOrder(orderId, 0, "Order Rejected");
  };

  // File upload api

  const UploadFile = async (data) => {
    const formData = new FormData();

    formData.append("file", data);

    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/studio/${orderDetails?.studioid}/orders/${id}/files/studio`,
      {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
        body: formData,
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.isError) {
          if (process.env.NODE_ENV === "development") {
            console.log("File uploading ----->", data);
          }
          setUploading(false);
          setDownloadUrl(data.downloadurl);
          setShowAlert(true);
          setFile(null);
          toast.success("File uploaded successfully!");
          GetUploadedFiles();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.isError);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Get uploaded files
  const GetUploadedFiles = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/studio/${orderDetails?.studioid}/orders/${id}/files`,
      {
        method: "get",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("GET uploaded files ----->", data);
          }
          setAllFiles(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetInboxes = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/inbox/studio/${window.localStorage.getItem("studioId")}`,
      // "http://localhost:3000/user/auth",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.inbox) {
          if (process.env.NODE_ENV === "development") {
            console.log("Inboxes ----->", data);
          }
          setInboxes(data.inbox);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Messaging
  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected");
    });

    socket.on("error", (err) => {
      // setIsConnected(true);
      console.log("error", err);
    });

    socket.on("send-message", (data) => {
      // setIsConnected(true);
      console.log("send message", data);
      setMessageText("");
      handleClose();
      toast.success("Message sent successfully!");
    });

    socket.on("deliver-message", () => {
      console.log("deliver");
    });

    socket.on("read-message", () => {
      console.log("read");
    });

    socket.on("receive", (data) => {
      console.log("receive", data);
    });

    socket.on("disconnect", () => {});

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);

  const sendMessage = () => {
    console.log("Sending message", inboxId[0]?.id);
    if (messageText !== "") {
      socket.emit("send-message", {
        inbox: {
          inboxType: "studioOrder",
          orderId: orderDetails?.id,
          studioId: orderDetails?.studioid,
          userId: orderDetails?.userid,
          inboxId: inboxId[0]?.id || null,
        },
        message: {
          messageType: "text",
          text: messageText,
          sender: "studio",
          receiver: "user",
        },
      });
    } else {
      toast.error("Message cannot be empty");
    }
  };

  return (
    <div className="orderHistory">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {openModal === "message"
              ? "Message Studio"
              : openModal === "reject"
              ? "Please write a reason for rejection or ask the customer to rebook at a different time"
              : null}
          </Typography>
          <input
            id="order-history-chat-input"
            type="text"
            placeholder="Type your message ..."
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
          />
          <button className="send-msg-btn" onClick={sendMessage}>
            Send Message
          </button>
        </Box>
      </Modal>
      <img
        onClick={() => navigate("/studio-home/dashboard")}
        src={Logo}
        alt="Jamrio logo"
        id="jamrio-logo"
      />
      <div className="order-history-main-container">
        <div className="left-main-container">
          <div className="order-profile-container">
            <div className="profile-image">
              <img
                src="https://images.unsplash.com/photo-1501196354995-cbb51c65aaea?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80"
                alt="profile"
                className="profile-pic"
              />
            </div>
            <div className="profile-details">
              <h1 className="name">{userData?.name}</h1>
              <div className="contact-container">
                <img
                  src="https://img.icons8.com/ios-filled/50/ffffff/new-post.png"
                  alt="email"
                  className="contact-icons"
                />
                <h2 className="email">{userData?.email}</h2>
              </div>
              <div className="contact-container">
                <img
                  src="https://img.icons8.com/material-outlined/50/ffffff/iphone--v1.png"
                  alt="phone"
                  className="contact-icons"
                />
                <h2 className="phone">{userData?.mobile}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="right-main-container">
          <div className="right-top-container">
            <div className="request-status-container">
              <h2>Order Status</h2>
              {orderDetails && orderDetails.state === "approval_pending" ? (
                <div className="actionBtn-container">
                  <button id="acceptBtn" onClick={() => handleAccept(id)}>
                    Accept
                  </button>
                  <button id="rejectBtn" onClick={() => handleReject(id)}>
                    Reject
                  </button>
                </div>
              ) : (
                <h2 id="request-status">
                  {orderDetails?.state
                    ? orderDetails?.state?.split("_").join(" ")
                    : "Loading..."}
                </h2>
              )}
              <h3 id="studio-order-id">{id}</h3>
            </div>
            <div className="request-status-container">
              <h2>OTP</h2>
              <OtpInput
                isDisabled={orderStarted}
                value={otp}
                onChange={setOtp}
                numInputs={6}
                // separator={<span> - </span>}
                containerStyle={"otp-container"}
                inputStyle={"otp-input"}
              />
              <div className="buttons-container">
                <button
                  disabled={orderStarted}
                  onClick={() => {
                    if (process.env.NODE_ENV === "development") {
                      console.log("OTP", otp);
                    }
                    OTPApprove(otp);
                  }}
                  className="start-btn"
                >
                  {orderStarted ? "Order Started" : "Start Order"}
                </button>
              </div>
              {orderStarted && <h3 id="started-msg">Order Started</h3>}
            </div>
          </div>
          <div className="studio-order-details-container">
            <div className="order-details-text-content">
              <div id="text-container">
                <div className="order-details-content">
                  <p className="detail-title">Recording Date: </p>
                  <h1 className="detail">{orderDetails?.bookingdate}</h1>
                </div>
                <div className="order-details-content">
                  <p className="detail-title">Time: </p>
                  <h1 className="detail">
                    {parseInt(orderDetails?.starttime) > 12
                      ? parseInt(orderDetails?.starttime) - 12 + "pm"
                      : parseInt(orderDetails?.starttime) === 12
                      ? parseInt(orderDetails?.starttime) + "pm"
                      : parseInt(orderDetails?.starttime) + "am"}{" "}
                    -{" "}
                    {parseInt(orderDetails?.endtime) > 12
                      ? orderDetails?.endtime?.substring(0, 2) - 12 + "pm"
                      : parseInt(orderDetails?.endtime) === 12
                      ? parseInt(orderDetails?.endtime) + "pm"
                      : parseInt(orderDetails?.endtime) + "am"}
                  </h1>
                </div>
                <div className="order-details-content">
                  <p className="detail-title">Payment Status: </p>
                  <h1 className="detail">Paid</h1>
                </div>
                <div className="order-details-content">
                  <p className="detail-title">Amount Paid: </p>
                  <h1 className="detail">₹{orderDetails?.netamount}</h1>
                </div>
              </div>
            </div>
          </div>
          {/* TODO: SOON TO BE CHANGED */}
          {orderDetails?.state === "created" ? null : orderDetails?.state ===
              "otp_pending" || orderDetails?.state === "success" ? (
            <div className="studio-messaging-file-container">
              <div className="message-container">
                <h2 className="order-status-title">
                  Message the User for any queries
                </h2>
                <div className="message-btn-container">
                  <button
                    className="send-msg-btn"
                    onClick={() => {
                      setOpenModal("message");
                      handleOpen();
                    }}
                  >
                    Send Message
                  </button>
                </div>
              </div>
              <div className="file-upload-container">
                <div className="file-upload-inner-container">
                  <div>
                    <FileUploader
                      handleChange={handleFileChange}
                      name="file"
                      types={fileTypes}
                      classes="file-upload-box"
                      maxSize={1024}
                      children={
                        <>
                          <h2>Drag and Drop a File here</h2>
                          <h2>OR</h2>
                          <h3 className="modalBtn modalBtn2">
                            Click here to select a file
                          </h3>
                          <p>Max file size 1GB</p>
                        </>
                      }
                    />
                  </div>
                </div>
                {file ? (
                  <>
                    <div className="selected-file-container">
                      <div className="selected-file">
                        <h1 className="detail">{file.name}</h1>
                      </div>
                    </div>
                    <button
                      className="modalBtn modalBtn3"
                      onClick={() => {
                        setUploading(true);
                        UploadFile(file);
                      }}
                    >
                      {uploading ? (
                        <div>
                          <ScaleLoader
                            color={"#fff"}
                            loading={uploading}
                            height={20}
                            width={5}
                            radius={50}
                          />
                        </div>
                      ) : (
                        <p>Upload File</p>
                      )}
                    </button>
                  </>
                ) : null}
                {showAlert ? (
                  <Alert severity="success">File Uploaded Successfully!</Alert>
                ) : null}
                {allFiles.length > 0 ? (
                  <div className="uploaded-file-container">
                    <h3>Uploaded Files</h3>
                    <div className="file-container">
                      {allFiles.map((file) => {
                        let fileName = file?.filename;
                        return (
                          <div className="uploaded-file" key={file.url}>
                            <a
                              className="file-link"
                              href={file?.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaFileAudio size={26} color={"#888"} />
                              <p className="file-name">
                                {fileName?.slice(0, -4).substring(0, 20) +
                                  "..."}
                              </p>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
          {orderDetails?.state === "failed" ? (
            <div className="message-container">
              <h2 className="order-status-title">
                Message the User if you want them to reschedule
              </h2>
              <div className="message-btn-container">
                <button
                  className="send-msg-btn"
                  onClick={() => {
                    setOpenModal("message");
                    handleOpen();
                  }}
                >
                  Send Message
                </button>
              </div>
            </div>
          ) : null}
          {/* <div className="review-container">
            <p className="detail-title">Payment Breakdown:</p>
            <div className="review-btn-container"></div>
            <div className="review-text-content"></div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default OrderHistory;
