import "./styles.css";
import {
  Dashboard,
  EventNote,
  Inbox,
  BarChart,
  Link,
  Person,
  Logout,
  LocalOffer,
  SwapHoriz,
  AccountCircleOutlined,
  Widgets,
  AccountCircle,
} from "@mui/icons-material";
import Logo from "../../Images/jamrioLogo.webp";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useContext } from "react";
import NavContext from "../../Context/NavContext";
import EngineerDashboard from "../../Components/EngineerDashboard/EngineerDashboard";
import EngineerBookings from "../../Components/EngineerBookings/EngineerBookings";
import EngineerCoupons from "../../Components/EngineerCoupons/EngineerCoupons";
import EngineerProfile from "../EngineerProfile copy/EngineerProfile";
import EngineerInbox from "../../Components/EngineerInbox/EngineerInbox";
import { Switch } from "@mui/material";
import { ToastContainer } from "react-toastify";
import EngineerSettings from "../../Components/EngineerSettings/EngineerSettings";
import EngineerEarnings from "../../Components/EngineerEarnings/EngineerEarnings";

const EngineerHome = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { activeNavItem } = useContext(NavContext);
  const [engineerDetails, setEngineerDetails] = useState();
  const [pathName, setPathName] = useState();
  const { component } = useParams();
  const [clicked, setClicked] = useState(false);
  const [ownerDetails, setOwnerDetails] = useState();
  const [ownerIds, setOwnerIds] = useState({
    studio: "",
    engineer: "",
  });
  const [engStatus, setEngStatus] = useState(false);
  const navigate = useNavigate();
  //-------------NAV ITEMS--------------------//
  // Dashboard ------- D
  // Bookings ------- B
  // Inbox ------- I
  // Statistics ------- S
  // Packages & Coupons ------- PC
  // Link ------- L
  // Profile ------- P

  useEffect(() => {
    setEngStatus(engineerDetails?.isEnabled);
  }, [engineerDetails]);

  const handleStatusChange = (event) => {
    console.log("Switch", event.target.checked);
    setEngStatus(event.target.checked);
    ChangeEngineerStatus(event.target.checked ? 1 : 0);
  };

  useEffect(() => {
    GetEngineerData();
    GetOwnerDetails();
    RefreshToken();
  }, []);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);
  }, []);

  const onClickLogout = () => {
    window.localStorage.removeItem("userId");
    window.localStorage.removeItem("studioId");
    window.localStorage.removeItem("engineerId");
    OnLogout();
  };

  const GetOwnerDetails = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/user/${window.localStorage.getItem("userId")}`,
      // "http://localhost:3000/user/auth",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("User Details ----->", data);
          }
          setOwnerIds({
            studio: data.studioid,
            engineer: data.studioengineerId,
          });
          setOwnerDetails(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const ChangeEngineerStatus = async (status) => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/dashboard/engineer/${engineerDetails.id}/enabled/${status}`,
      // "http://localhost:3000/user/auth",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          GetEngineerData();
        }
        if (process.env.NODE_ENV === "development") {
          console.log("Status changed----->", response.status);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          GetEngineerData();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const RefreshToken = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/auth/refresh`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        if (response.status === 401) {
          OnLogout();
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data.token) {
          if (process.env.NODE_ENV === "development") {
            console.log("Refresh token ------------------- ----->", data);
          }
          window.localStorage.setItem("AuthToken", data.token.token);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const OnLogout = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/auth/logout`,
      // "http://localhost:3000/user/auth",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Logged Out ----->", data);
          }
          navigate("/");
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const setActiveComponent = () => {
    switch (component) {
      case "dashboard":
        return <EngineerDashboard />;
      // break;
      case "bookings-pending":
        return <EngineerBookings />;
      case "bookings-upcoming":
        return <EngineerBookings />;
      case "bookings-total":
        return <EngineerBookings />;
      case "inbox":
        return <EngineerInbox />;
      case "packages-coupons":
        return <EngineerCoupons />;
      case "profile":
        return <EngineerProfile />;
      case "account":
        return <EngineerSettings />;
      case "earnings":
        return <EngineerEarnings />;
      default:
        return <EngineerDashboard />;
      // break;
    }
  };

  const GetEngineerData = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/mixing-mastering/${window.localStorage.getItem(
        "engineerId"
      )}/studioengineer`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("engineer Data ------------------- ----->", data);
          }
          setEngineerDetails(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="engineerHome">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {screenWidth > 800 ? (
        <div className={"engineer-home-navbar"}>
          {engineerDetails?.image ? (
            <img
              src={engineerDetails?.image}
              alt="Profile"
              id="navbar-profile-img"
              onClick={() => {
                navigate("/engineer-home/profile");
              }}
            />
          ) : (
            <p id="studio-name">
              {engineerDetails?.firstname + " " + engineerDetails?.lastname}
            </p>
          )}
          {/* <div className="enable-engineer-status-container">
            <p
              id={`engineer-profile-visibility-${
                engineerDetails?.isEnabled ? "true" : "false"
              }`}
            >
              {engineerDetails?.isEnabled
                ? "Profile is Visible"
                : "Profile is not Visible"}
            </p>
            <Switch
              checked={engStatus}
              onChange={handleStatusChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div> */}
          <button
            onClick={() => {
              navigate("/engineer-home/dashboard");
            }}
            className={
              activeNavItem === "D"
                ? "engineerHome-navbar-items-selected"
                : "engineerHome-navbar-items"
            }
          >
            <div className="engineerHome-navbar-items-icons-container">
              <Dashboard className="engineerHome-navbar-items-icon" />
            </div>
            <p className="engineerHome-navbar-items-text">Dashboard</p>
          </button>
          <button
            onClick={() => {
              navigate("/engineer-home/bookings-pending");
            }}
            className={
              activeNavItem === "B"
                ? "engineerHome-navbar-items-selected"
                : "engineerHome-navbar-items"
            }
          >
            <div className="engineerHome-navbar-items-icons-container">
              <EventNote className="engineerHome-navbar-items-icon" />
            </div>
            <p className="engineerHome-navbar-items-text">Bookings</p>
          </button>
          <button
            onClick={() => {
              navigate("/engineer-home/inbox");
            }}
            className={
              activeNavItem === "I"
                ? "engineerHome-navbar-items-selected"
                : "engineerHome-navbar-items"
            }
          >
            <div className="engineerHome-navbar-items-icons-container">
              <Inbox className="engineerHome-navbar-items-icon" />
            </div>
            <p className="engineerHome-navbar-items-text">Inbox</p>
          </button>
          <button
            onClick={() => {
              navigate("/engineer-home/packages-coupons");
            }}
            className={
              activeNavItem === "PC"
                ? "engineerHome-navbar-items-selected"
                : "engineerHome-navbar-items"
            }
          >
            <div className="engineerHome-navbar-items-icons-container">
              <LocalOffer className="engineerHome-navbar-items-icon" />
            </div>
            <p className="engineerHome-navbar-items-text">Offers</p>
          </button>
          <button
            onClick={() => {
              navigate("/engineer-home/profile");
            }}
            className={
              activeNavItem === "P"
                ? "engineerHome-navbar-items-selected"
                : "engineerHome-navbar-items"
            }
          >
            <div className="engineerHome-navbar-items-icons-container">
              <AccountCircle className="engineerHome-navbar-items-icon" />
            </div>
            <p className="engineerHome-navbar-items-text">Profile</p>
          </button>
          <button
            onClick={() => {
              navigate("/engineer-home/account");
            }}
            className={
              activeNavItem === "A"
                ? "engineerHome-navbar-items-selected"
                : "engineerHome-navbar-items"
            }
          >
            <div className="engineerHome-navbar-items-icons-container">
              <Widgets className="engineerHome-navbar-items-icon" />
            </div>
            <p className="engineerHome-navbar-items-text">Account</p>
          </button>
          <button
            className="engineerHome-navbar-items studio-logout-btn"
            onClick={onClickLogout}
          >
            <div className="engineerHome-navbar-items-icons-container">
              <Logout className="engineerHome-navbar-items-icon" />
            </div>
            <p className="engineerHome-navbar-items-text">Logout</p>
          </button>
          {ownerIds.studio !== null && (
            <button
              className="engineerHome-navbar-items"
              id="studio-switch-btn"
              onClick={() => navigate("/studio-home/dashboard")}
            >
              <div className="engineerHome-navbar-items-icons-container">
                <SwapHoriz className="engineerHome-navbar-items-icon" />
              </div>
              <p className="engineerHome-navbar-items-text">
                Switch To Studio Dashboard
              </p>
            </button>
          )}
        </div>
      ) : (
        <div className="navbar-mobile">
          <button
            onClick={() => {
              navigate("/engineer-home/bookings-pending");
            }}
            className={
              activeNavItem === "B"
                ? "engineerHome-navbar-items-phone-selected"
                : "engineerHome-navbar-items-phone"
            }
          >
            <div className="engineerHome-navbar-items-phone-icons-container">
              <EventNote className="engineerHome-navbar-items-phone-icon" />
            </div>
            <p className="engineerHome-navbar-items-phone-text">Bookings</p>
          </button>
          <button
            onClick={() => {
              navigate("/engineer-home/inbox");
            }}
            className={
              activeNavItem === "I"
                ? "engineerHome-navbar-items-phone-selected"
                : "engineerHome-navbar-items-phone"
            }
          >
            <div className="engineerHome-navbar-items-phone-icons-container">
              <Inbox className="engineerHome-navbar-items-phone-icon" />
            </div>
            <p className="engineerHome-navbar-items-phone-text">Inbox</p>
          </button>
          <button
            onClick={() => {
              navigate("/engineer-home/dashboard");
            }}
            className={
              activeNavItem === "D"
                ? "engineerHome-navbar-items-phone-selected"
                : "engineerHome-navbar-items-phone"
            }
          >
            <div className="engineerHome-navbar-items-phone-icons-container">
              <Dashboard className="engineerHome-navbar-items-phone-icon" />
            </div>
            <p className="engineerHome-navbar-items-phone-text">Dashboard</p>
          </button>
          <button
            onClick={() => {
              navigate("/engineer-home/packages-coupons");
            }}
            className={
              activeNavItem === "PC"
                ? "engineerHome-navbar-items-phone-selected"
                : "engineerHome-navbar-items-phone"
            }
          >
            <div className="engineerHome-navbar-items-phone-icons-container">
              <LocalOffer className="engineerHome-navbar-items-phone-icon" />
            </div>
            <p className="engineerHome-navbar-items-phone-text">Offers</p>
          </button>
          <button
            onClick={() => {
              navigate("/engineer-home/account");
            }}
            className={
              activeNavItem === "A"
                ? "engineerHome-navbar-items-phone-selected"
                : "engineerHome-navbar-items-phone"
            }
          >
            <div className="engineerHome-navbar-items-phone-icons-container">
              <AccountCircle className="engineerHome-navbar-items-phone-icon" />
            </div>
            <p className="engineerHome-navbar-items-phone-text">Account</p>
          </button>
          {/* <button
            className="engineerHome-navbar-items-phone studio-logout-btn"
            onClick={onClickLogout}
          >
            <div className="engineerHome-navbar-items-phone-icons-container">
              <Logout className="engineerHome-navbar-items-phone-icon" />
            </div>
            <p className="engineerHome-navbar-items-phone-text">Logout</p>
          </button>
          {ownerIds.engineer !== null && (
            <button
              className="engineerHome-navbar-items-phone"
              id="studio-switch-btn"
              onClick={() => navigate("/engineer-home/dashboard")}
            >
              <div className="engineerHome-navbar-items-phone-icons-container">
                <SwapHoriz className="engineerHome-navbar-items-phone-icon" />
              </div>
              <p className="engineerHome-navbar-items-phone-text">
                Switch To Engineer Dashboard
              </p>
            </button>
          )} */}
        </div>
      )}
      <div
        className={
          clicked ? "engineer-home-content-fullwidth" : "engineer-home-content"
        }
      >
        <img
          onClick={() => navigate("/engineer-home/dashboard")}
          src={Logo}
          alt="Jamrio logo"
          id="studio-jamrio-logo"
        />
        {/* <div id="mobile" onClick={clickHandler}>
          <i id="bar" className={clicked ? "fas fa-bars" : "fas fa-times"}></i>
        </div> */}
        <div id="engineer-home-content-left"></div>
        {setActiveComponent()}
      </div>
    </div>
  );
};

export default EngineerHome;
